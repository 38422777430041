import React, { useEffect, useState } from "react";
import { CardesTemplate } from "../composPageAccueil/CardesTemplate";
import { useAppContext } from "../../context/AppContext";
import { HubForgeHeader } from "../composPageAccueil/HubForgeHeader";
import Logo from "../../assets/LogoHubForge.png";
import { useGetTemplatesQuery } from "../../services/templates_services/TemplateService";

export const TotalTemplates = () => {
  const { apiUrlImg } = useAppContext();
  const { data: templates } = useGetTemplatesQuery("dashboard");
  
  const getCreator = (template) => {
    if (template.user_role === "Worker") {
      return { creator: template.created_by, profile: template.user_profile };
    } else {
      return { creator: "Bakeli Work", profile: Logo };
    }
  };


  return (
    <div
      className={`border-gray-100`}
    >
      <div>
        <HubForgeHeader
          TitrePage={
            <h1
              className={`text-center text-6xl max-sm:text-4xl font-bold leading-[66px] text-bl`}
            >
              Dashboards
            </h1>
          }
        />
      </div>

      <div className={`mt-8 flex justify-center w-full flex-wrap`}>
       {templates?.map((temp) => {
          const { creator, profile } = getCreator(temp);
          return (
            <CardesTemplate
              key={temp.id}
              HandlePreview={temp.aperçu}
              imageTemplate={`${apiUrlImg}/${temp.image}`}
              fonCardTemplate={temp.couleur_fond}
              handlePriceTo={temp.aperçu}
              titreCrdTemplate={temp.titre}
              DesctiptionTemplate={temp.description}
              prixTemplate={temp.prix === null ? "Free" : temp.prix}
              telechargeLink={`/telecharge/${temp.id}`}
              userName={creator}
              userProfile={profile}
            />
          );
        })}
      </div>
    </div>
  );
};
