import React from 'react'
import { Skeleton } from 'primereact/skeleton';

const SkeletonLoader = ({id, cardProject, cardTask, image, text, widthtext, heightText, cardTemplates, livraison, notif}) => {
  return (
    <>
      {cardProject ? (
        <div
          className="animate-pulse w-[23%] max-lg:w-[46%] max-lg:mb-2 mb-1 bg-white shadow py-5 rounded-md px-5"
          id={id}
        >
          <Skeleton className="rounded bg-slate-300" height="180px"></Skeleton>
          <div className="pt-4 grid gap-1">
            <Skeleton
              className=" bg-slate-300 rounded mb-2"
              width="50%"
              height="0.6rem"
            ></Skeleton>
            <Skeleton
              className=" bg-slate-300 mb-2 rounded"
              height="1rem"
            ></Skeleton>
            <div className="">
              <Skeleton
                className="h-2 bg-slate-300 rounded"
                width="40%"
              ></Skeleton>
            </div>
          </div>
        </div>
      ) : image ? (
        <Skeleton
          className="rounded bg-slate-300"
          height="320px"
          width="100%"
        ></Skeleton>
      ) : cardTask ? (
        <div className="flex flex-col justify-center animate-pulse bg-white rounded-lg px-7 py-3 border border-gray-300 mb-4">
          <div className="card-body leading-[10px] h-[80%] gap-5">
            <Skeleton className="bg-slate-300 mb-5" height="0.6rem"></Skeleton>
            <Skeleton
              className="bg-slate-300 rounded mb-5"
              height="0.6rem"
            ></Skeleton>
            <Skeleton
              className="bg-slate-300 rounded mb-5"
              width="100%"
              height="0.6rem"
            ></Skeleton>
            <div className="w-full flex justify-end">
              <Skeleton
                className="bg-slate-300 rounded"
                width="40%"
                height="0.6rem"
              ></Skeleton>
            </div>
          </div>
        </div>
      ) : text ? (
        <Skeleton
          className="bg-slate-300 mb-5"
          height={heightText}
          width={widthtext}
        ></Skeleton>
      ) : cardTemplates ? (
        <div
          className="card animate-pulse w-[35%] max-lg:w-[46%] max-lg:mb-2 mb-1 bg-white shadow py-5 rounded-md px-5"
          id={id}
        >
          <Skeleton className="rounded bg-slate-300" height="200px"></Skeleton>
          <div className="pt-4 grid gap-1">
            <Skeleton
              className=" bg-slate-300 rounded mb-2"
              width="50%"
              height="0.6rem"
            ></Skeleton>
            <Skeleton
              className=" bg-slate-300 mb-2 rounded"
              height="1rem"
            ></Skeleton>
            <div className="">
              <Skeleton
                className="h-2 bg-slate-300 rounded"
                width="40%"
              ></Skeleton>
            </div>
          </div>
        </div>
      ) : livraison ? (
        <div className="flex gap-2 items-start w-3/5 bg-white p-5 rounded-lg">
          <div className="w-[50px] h-[50px]">
            <Skeleton
              className="h-full w-full profile cursor-pointer text-center items-center text-sm flex justify-center rounded-full border bg-slate-300"
              height="100%"
              width="100%"
            ></Skeleton>
          </div>
          <div className="text-xs leading-8 flex-grow w-[85%]">
            <div className="flex items-center gap-2 mt-1">
              <Skeleton
                className="bg-slate-300 rounded mb-2"
                width="30%"
                height="0.6rem"
              ></Skeleton>
            </div>
            <div className="leading-6 mt-3">
              <Skeleton
                className="bg-slate-300 mb-1"
                width="40%"
                height="0.6rem"
              ></Skeleton>
              <Skeleton
                className="bg-slate-300 mb-1"
                width="60%"
                height="0.6rem"
              ></Skeleton>
              <Skeleton
                className="bg-slate-300 mb-1"
                width="80%"
                height="0.6rem"
              ></Skeleton>
              <Skeleton
                className="bg-slate-300 mb-1"
                width="100%"
                height="0.6rem"
              ></Skeleton>
              <Skeleton
                className="bg-slate-300 mb-1"
                width="100%"
                height="0.6rem"
              ></Skeleton>
              <Skeleton
                className="bg-slate-300 mb-1"
                width="100%"
                height="0.6rem"
              ></Skeleton>
              <Skeleton
                className="card rounded-xl bg-slate-300 mt-6"
                height="10rem"
                width="100%"
              ></Skeleton>
            </div>
          </div>
        </div>
      ) : notif ? (
        <div
          className={`absolute inset-0 flex items-center justify-center rounded-box w-full h-full z-[1000]`}
        >
          <Skeleton className="h-full w-full bg-red opacity-20 rounded-box" width='100%' height='100%'/>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default SkeletonLoader
