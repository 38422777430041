import { IoMdCube } from "react-icons/io";
import { HiUsers } from "react-icons/hi2";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { RiTaskFill } from "react-icons/ri";

export const dataCards = [
  {
    statsData: [
      {
        label: "Total",
        value: 44,
        colorClass: "text-bl",
      },
      {
        label: "En cours",
        value: 16,
        bordure: "border-l-2  border-gray",
        colorClass: "text-orange",
      },
      {
        label: "En pause",
        value: 18,
        bordure: "border-l-2  border-gray",
        colorClass: "text-rg",
      },
      {
        label: "Terminés",
        value: 14,
        bordure: "border-l-2  border-gray",
        colorClass: "text-vr",
      },
    ],
    bgIcon: "bg-[#1abca4]/15",
    typeCard: "longue",
    cardData: {
      title: "Projets",
      icone: <IoMdCube className="text-3xl text-[#1abca4]" />,
    },
  },
  {
    statsData: [
      {
        label: "Total",
        value: 53,
        colorClass: "text-bl",
      },
      {
        label: "En cours",
        bordure: "border-l-2  border-gray",
        value: 22,
        colorClass: "text-orange",
      },
      {
        label: "En pause",
        value: 13,
        bordure: "border-l-2  border-gray",
        colorClass: "text-rg",
      },
      {
        bordure: "border-l-2  border-gray",
        label: "Terminées",
        value: 18,
        colorClass: "text-vr",
      },
    ],
    bgIcon: "bg-bl/15",
    typeCard: "longue",
    cardData: {
      title: "Tâches",
      icone: <RiTaskFill className="text-3xl text-bl" />,
    },
  },
  // Ajoutez d'autres cartes si nécessaire
];

export const cardsData = [
  {
    id: 4,
    typeCard: "courte",
    bgIcon: "bg-[#6a120466]/15",
    sousTitre: "Total Bakelistes ",
    icone: <HiUsers className="text-3xl text-[#6a120466]" />,
    total: "42",
  },
  {
    id: 2,
    typeCard: "courte",
    bgIcon: "bg-bl/15",
    sousTitre: "Bakelistes actifs",
    icone: <HiUsers className="text-3xl text-bl" />,
    total: "12",
  },
  {
    id: 3,
    typeCard: "courte",
    bgIcon: "bg-vr/15",
    sousTitre: "Backelistes Payés",
    icone: <HiUsers className="text-3xl text-vr" />,
    total: "25",
  },

  {
    id: 5,
    typeCard: "courte",
    bgIcon: "bg-[#d0280eef]/15",
    sousTitre: "Bakelistes Rétirés",
    icone: <HiUsers className="text-3xl text-[#d0280eef]" />,
    total: "13",
  },

  // livraisons
  {
    id: 6,
    typeCard: "courte",
    bgIcon: "bg-[#7d18caef]/15",
    sousTitre: "Total Livraisons",
    icone: <IoCheckmarkDoneCircle className="text-3xl text-[#7d18caef]" />,
    total: "11",
  },
  {
    id: 7,
    typeCard: "courte",
    bgIcon: "bg-orange/15",
    sousTitre: "Livraisons Validées",
    icone: <IoCheckmarkDoneCircle className="text-3xl text-orange" />,
    total: "14",
  },
  {
    id: 8,
    typeCard: "courte",
    bgIcon: "bg-[#243fdb]/15",
    sousTitre: "Livraisons Réjétées",
    icone: <IoCheckmarkDoneCircle className="text-3xl text-[#243fdb]" />,
    total: "14",
  },
  {
    id: 8,
    typeCard: "courte",
    bgIcon: "bg-[#243fdb]/15",
    sousTitre: "Livraisons Réjétées",
    icone: <IoCheckmarkDoneCircle className="text-3xl text-[#243fdb]" />,
    total: "14",
  },
 
];
