import React, { useState } from "react";
import { FaAngleDown, FaImage } from "react-icons/fa6";
import { FiUpload } from "react-icons/fi";
import { IoCheckmark, IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import { MoonLoader, PulseLoader } from "react-spinners";
import { useAppContext } from "../../context/AppContext";
import { useModal } from "../../context/ModalContext";
import Tabs from "../client/projectsComponents/Tabs";
import MonBouton from "./design-system/buttons/MonBouton";
import MonInput from "./design-system/inputs/MonInput";
import ModalElement from "./ModalElement";
import { contentBoutonList } from "./UtilsTask";
import RenderWysiwyg from "render-wysiwyg";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import DateModal from "../client/projectsComponents/DateModal";
import { BsFillClipboard2CheckFill, BsFillClipboard2XFill, BsThreeDotsVertical } from "react-icons/bs";

const ModalContent = ({
  tasks,
  task,
  isLoadingTask,
  action,
  isLoadingPostuleTask,
  user,
  assigned,
  setValidated,
  profileLetters,
  comments,
  fetchData,
  microTaches,
  refetchMicroTaches,
  isLoadingValidationTask,
  handleSubmitImage,
  openModalPostule,
  setPostuled,
  notifs,
  statut,
  image,
  handleCancel,
  handleEditClick,
  handleImageChange,
  isLoadingUpdateImg,
  isLoadingUpdateDates,
  setDate,
  date,
  dateFinished,
  setDateFinished,
  handleUpdateDate,
  status,
  loadingChangeStatus,
  handleChangeStatus,
}) => {
  const {
    isModalElementOpen,
    closeModalElement,
    modalTask,
    openModalElement,
    openModalValidation,
  } = useModal();
  const firstLetters = profileLetters(task?.assigned_to_name);
  const { apiUrlImg } = useAppContext();
  const { openModalLivraison } = useModal();
  const [modalDate, setModalDate] = useState(false);
  const openModalDate = () => {
    setModalDate(true);
  };
  const closeModalDate = () => {
    setModalDate(false);
  };
  const userId = user?.id;
  const taskList = microTaches?.microTaches;
  const taskFinished = taskList?.filter(
    (microtask) => microtask?.terminer === 1
  );
  const pourcentage = Math.min(
    Math.floor((taskFinished?.length * 100) / taskList?.length),
    100
  );
  const handleValidated = () => {
    setValidated(true);
    openModalValidation();
  };

  const handlePostuled = () => {
    setPostuled(true);
    openModalPostule();
  };
  const handleInValidated = () => {
    setValidated(false);
    openModalValidation();
  };
  const handleOpenModal = (images) => {
    openModalLivraison(images);
  };
  

  return (
    <div className="flex flex-wrap">
      <div className="w-[45%] pt-8 flex flex-col justify-center max-lg:w-full">
        <div className="flex flex-col items-end">
          <div
            className={`flex flex-wrap pb-5 w-[92%] border-b ${
              user?.statut === "coach" ? "gap-3" : "gap-4"
            }`}
          >
            {user?.statut === "coach"
              ? contentBoutonList
                  .filter((content) => !content.postule)
                  .map((content, i) => (
                    <div
                      className={`relative ${
                        user?.statut === "coach" ? "w-[28%]" : "w-[30%]"
                      }`}
                      key={i}
                    >
                      <MonBouton
                        children={
                          content?.validated && isLoadingValidationTask
                            ? "En cours..."
                            : content?.members && assigned
                            ? "Réassigner"
                            : content.text
                        }
                        couleurFond={
                          content.validated ? "bg-vr text-white" : "bg-white"
                        }
                        bordure={`w-full border border-gryborder ${content.padding} px-3 flex justify-start items-center outline-none text-noir_200 font-inter font-semibold text-xs rounded h-7`}
                        action={() =>
                          content.postule
                            ? action
                            : content.members
                            ? openModalElement()
                            : content.dates && openModalDate()
                        }
                        type={"button"}
                        icone={content.icon}
                      />
                      {modalDate && (
                        <DateModal
                          date={date}
                          setDate={setDate}
                          handleUpdateDate={handleUpdateDate}
                          isLoadingUpdateDates={isLoadingUpdateDates}
                          setDateFinished={setDateFinished}
                          dateFinished={dateFinished}
                          onClose={closeModalDate}
                          handleCancelDate={closeModalDate}
                          finEcheance={task?.finished_at}
                          debutEcheance={task?.started_at}
                        />
                      )}
                    </div>
                  ))
              : contentBoutonList
                  ?.filter(
                    (content) =>
                      !content?.validated &&
                      !content?.rejeter &&
                      !content?.members &&
                      !content?.dates &&
                      (!content?.postule ||
                        !tasks?.postulants.some(
                          (postulant) => postulant.id === userId
                        ))
                  )
                  .map((content, i) => (
                    <div className="w-[30%] relative" key={i}>
                      <MonBouton
                        children={
                          content.postule && isLoadingPostuleTask
                            ? "En cours..."
                            : content.text
                        }
                        couleurFond={"bg-white"}
                        bordure={`${content.width} ${content.padding} ${
                          content?.rejeter && content?.validated && "hidden"
                        } w-full border border-gryborder ${content.padding} px-3 flex justify-start items-center outline-none text-noir_200 font-inter font-semibold text-xs rounded h-7`}
                        action={
                          content.postule
                            ? () => handlePostuled()
                            : () => console.log("clicked")
                        }
                        type={"button"}
                        icone={content.icon}
                        key={i}
                      />
                      {isModalElementOpen && (
                        <ModalElement
                          task={modalTask}
                          onClose={closeModalElement}
                          memberAssigned={task?.assigned_to_id}
                          membres={
                            isLoadingTask
                              ? [{ name: "Chargement..." }]
                              : tasks
                              ? tasks?.postulants
                              : [{ name: "Aucun postulant" }]
                          }
                        />
                      )}
                    </div>
                  ))}
            <div className="flex gap-2 w-[30%]">
              <div className="dropdown dropdown-end w-9/12">
                <span
                  role="button"
                  tabIndex={0}
                  className="w-full bg-gryborder items-center border-grys/50 px-2 inline-flex gap-2 justify-center cursor-pointer border outline-none text-white font-inter font-medium text-xs rounded h-7"
                >
                  {statut}
                  <FaAngleDown
                    className="text-white outline-none"
                    size={12}
                    tabIndex={0}
                    role="button"
                  />
                </span>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu bg-white rounded items-center z-[1] px-0 w-max shadow"
                >
                  {status?.map((stat, i) => (
                    <li className="!px-0 w-full" key={i}>
                      <MonBouton
                        type={"button"}
                        children={stat.text}
                        couleurFond={statut === stat.text && "bg-gray-100"}
                        bordure={`w-full rounded-none items-center cursor-pointer text-xs font-inter font-medium text-center justify-center`}
                        action={() => handleChangeStatus(stat.statut)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <button
                className={`bg-white ${
                  statut === "Terminée" && "!bg-vrt text-white"
                } cursor-pointer inline-flex tooltip justify-center items-center w-8 rounded border border-gryborder`}
                data-tip={"Achevée la tache"}
                onClick={() =>
                  statut !== "Terminée" && handleChangeStatus("TERMINEE")
                }
                disabled={loadingChangeStatus ? true : false}
              >
                {loadingChangeStatus ? (
                  <MoonLoader size={10} />
                ) : (
                  <IoCheckmarkOutline className="" />
                )}
              </button>
            </div>
            {user?.statut === "coach" && (
              <div className="flex justify-center items-center">
                <span className="dropdown dropdown-end">
                  <BsThreeDotsVertical size={20} tabIndex={0} role="button" />
                  <ul
                    tabIndex={0}
                    className="dropdown-content menu bg-white rounded justify-center items-center z-[1] w-40 shadow"
                  >
                    <li className="w-full flex flex-col gap-3">
                      <MonBouton
                        type={"button"}
                        children={
                          task?.validation_tache === "VALIDE"
                            ? "Déjà validée"
                            : "Valider"
                        }
                        couleurFond={
                          task?.validation_tache === "VALIDE"
                            ? "bg-gryborder hover:!bg-gryborder text-noir_200"
                            : "bg-vr hover:!bg-vr"
                        }
                        icone={
                          <BsFillClipboard2CheckFill
                            color="white"
                            size={"14px"}
                          />
                        }
                        bordure={`w-full rounded-md text-white hover:text-white items-center text-xs font-inter font-medium text-start justify-start`}
                        disabled={
                          task?.validation_tache === "VALIDE" ? true : false
                        }
                        action={() => handleValidated()}
                      />
                      <MonBouton
                        type={"button"}
                        children={
                          task?.validation_tache === "REJETE"
                            ? "Déjà rejetée"
                            : "Rejeter"
                        }
                        couleurFond={
                          task?.validation_tache === "REJETE"
                            ? "bg-gryborder hover:!bg-gryborder text-noir_200"
                            : "bg-red hover:!bg-red"
                        }
                        icone={
                          <BsFillClipboard2XFill color="white" size={"14px"} />
                        }
                        bordure={`w-full rounded-md hover:!text-white text-white items-center text-xs font-inter font-medium text-start justify-start`}
                        disabled={
                          task?.validation_tache === "REJETE" ? true : false
                        }
                        action={() => handleInValidated()}
                      />
                    </li>
                  </ul>
                </span>
              </div>
            )}
          </div>
        </div>
        <div
          className={`pt-6 bg-white border-e flex flex-col items-center h-full w-full max-lg:w-full text-noir_300 gap-5`}
        >
          <div className="flex flex-col w-[85%] max-lg:w-full font-inter gap-5">
            <h2 className="text-2xl max-md:text-lg font-extrabold text-noir_300">
              {task?.titre}
            </h2>
            <div className="w-full items-center flex gap-2">
              <span className="text-sm font-normal">
                {pourcentage ? pourcentage : 0}%
              </span>
              <div className="w-11/12 bg-gray-200 rounded-full h-[4px]">
                <div
                  className="bg-green-500 h-full rounded-full"
                  style={{ width: `${pourcentage ? pourcentage : 0}%` }}
                ></div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-sm font-bold">Assigné</span>
              <div className="flex gap-2 text-noir_300 font-medium">
                {firstLetters && (
                  <span
                    className="profile cursor-pointer flex items-center justify-center size-9 text-xs rounded-full border bg-bl text-blanc font-medium"
                    data-tip={task?.assigned_to_name}
                  >
                    {firstLetters}
                  </span>
                )}
                <span>
                  {task?.assigned_to_name}
                  <p className="text-[12px] font-normal">
                    {task?.assigned_to_email}
                  </p>
                </span>
              </div>
            </div>
            {/* <div className="flex flex-col gap-2"> */}
            {/* <span className="text-sm font-bold">Etiquettes</span> */}
            {/* <div className="flex gap-2 text-noir_300 items-center text-[9px] font-medium"> */}
            {/* <span className="bg-sky-200 border uppercase rounded-md inline-flex items-center justify-center text-sky-600 border-sky-400 h-4 w-10">
                  info
                </span>
                <span className="bg-yellow-50 border uppercase rounded-md inline-flex items-center justify-center h-4 border-red w-11 text-red">
                  urgent
                </span>
                <span className="bg-green-50 border border-green-300 uppercase rounded-md inline-flex items-center justify-center h-4 w-10">
                  fait
                </span> */}
            {/* <MonBouton
                  type={"button"}
                  children={"Ajouter un autre"}
                  couleurFond={"text-orange"}
                  bordure={
                    "max-w-max rounded items-center text-xs font-inter font-bold cursor-text h-6 px-2 justify-center focus:border-none"
                  }
                  icone={<FaPlus size={12} className="font-inter font-bold" />}
                  disabled={true}
                /> */}
            {/* </div> */}
            {/* </div> */}
            <div className="flex flex-col">
              <div className="flex items-center gap-3">
                <span className="text-xl font-bold">Description</span>
                {/* <MdEdit className="text-orange" /> */}
              </div>
              <div>
                <p className="text-base font-nunito font-light">
                  <RenderWysiwyg content={task?.description} />
                </p>
              </div>
            </div>
          </div>
          <div className="flex gap-5 pb-6 justify-center w-full h-full bg-[#EFF2F6]">
            <div className="flex flex-col gap-3 w-[85%]">
              <div className="border-b py-4 border-gryborder/80">
                <span className="text-xl font-bold">Fichiers</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="flex items-center gap-3">
                  <FaImage className="size-3 text-noir_200" />
                  <p className="text-sm ">Image descriptive</p>
                </span>
                <span className="dropdown dropdown-end">
                  <BiDotsHorizontalRounded
                    tabIndex={0}
                    role="button"
                    className="text-noir_200"
                  />
                  <ul
                    tabIndex={0}
                    className="dropdown-content menu bg-white rounded justify-center items-center z-[1] w-40 shadow"
                  >
                    <li className="w-full">
                      <MonBouton
                        type={"button"}
                        children={"Modifier"}
                        couleurFond={""}
                        bordure={`max-w-max w-full rounded-none hover:!bg-transparent items-center !cursor-text text-xs font-inter font-medium text-start justify-center`}
                        disabled={true}
                      />
                      {/* <MonBouton
                        type={"button"}
                        children={"Télécharger"}
                        couleurFond={""}
                        bordure={`max-w-max w-full rounded-none hover:!bg-transparent items-center !cursor-text text-xs font-inter font-medium text-start justify-center`}
                        disabled={true}
                      /> */}
                      <MonBouton
                        type={"button"}
                        children={"Supprimer"}
                        couleurFond={"text-red"}
                        bordure={`max-w-max w-full rounded-none hover:!bg-transparent items-center !cursor-text text-xs font-inter font-medium text-start justify-center`}
                        disabled={true}
                      />
                    </li>
                  </ul>
                </span>
              </div>
              <div className="w-full h-40 flex max-md:ps-2 border-b border-gryborder/80 pb-2">
                {task?.image && (
                  <div className="relative w-[65%] h-full rounded-lg cursor-pointer">
                    <img
                      src={
                        image
                          ? URL.createObjectURL(image)
                          : `${apiUrlImg}/${task?.image}`
                      }
                      className="w-full h-full rounded-md object-cover"
                      onClick={() =>
                        handleOpenModal([
                          {
                            itemImageSrc: `${apiUrlImg}/${task?.image}`,
                            alt: "Img",
                          },
                        ])
                      }
                    />
                    {user.statut === "coach" && (
                      <div
                        className="bg-gray-50 bg-opacity-75 rounded-md items-center absolute cursor-pointer top-3 text-xs font-inter font-semibold left-3 inline-flex gap-2 p-2"
                        onClick={() => handleEditClick()}
                      >
                        <FaImage
                          // size={24}
                          className="size-3"
                        />
                        <span>Changer</span>
                      </div>
                    )}
                  </div>
                )}
                {!task?.image && user.statut === "coach" && (
                  <MonInput
                    label={
                      image ? (
                        <img
                          src={URL.createObjectURL(image)}
                          alt="Uploaded"
                          className="w-[65%] h-full rounded-lg object-cover"
                        />
                      ) : (
                        <div className="flex flex-col justify-center items-center h-36 mb-8 w-full border-orange focus:border-vr border-dashed rounded-md border focus:border-1 focus:outline-none bg-grise gap-1">
                          <FiUpload size={16} className="text-orange" />
                          <span className="text-xs">Charger une image</span>
                        </div>
                      )
                    }
                    type={"file"}
                    id={"image"}
                    onChange={handleImageChange}
                    placeholder={""}
                    marginB={"!w-[65%] !h-full"}
                    paddingB={"!h-full flex items-center hidden"}
                    labelStyle={"w-full text-md"}
                  />
                )}
                {image && (
                  <div className="flex flex-col gap-2">
                    <MonBouton
                      children={"Enrégistrer"}
                      couleurFond={"text-sm text-vr"}
                      bordure={"px-2"}
                      icone={<IoCheckmark />}
                      action={handleSubmitImage}
                      type={"button"}
                      alignment={"items-center"}
                      iconRight={
                        isLoadingUpdateImg && (
                          <PulseLoader size={5} color="#00B38A" />
                        )
                      }
                    />
                    <MonBouton
                      children={"Annuler"}
                      couleurFond={"text-sm text-red"}
                      bordure={"px-2"}
                      icone={<IoCloseOutline />}
                      action={handleCancel}
                      type={"button"}
                      alignment={""}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[55%] pb-1 max-lg:w-full flex flex-col gap-3">
        <Tabs
          task={task}
          name={task?.assigned_to_name}
          filterFunc={profileLetters}
          comments={comments}
          fetchData={fetchData}
          microTaches={microTaches}
          refetchMicroTaches={refetchMicroTaches}
          profileLetters={profileLetters}
          tab={notifs?.microtache ? 0 : 1}
          notifMicrotache={notifs?.microtache}
        />
      </div>
    </div>
  );
};

export default ModalContent;