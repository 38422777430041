import {
  useGetCategoriesQuery,
  useGetTypesCodesQuery,
  useGetTypesTemplatesQuery
} from "../services/templates_services/TemplateService";


export const useSelectData = () => {
  const { data: typesTemplates = [] } = useGetTypesTemplatesQuery();
  const { data: categories = [] } = useGetCategoriesQuery();
  const { data: typesCodes = [] } = useGetTypesCodesQuery();

  return {
    typesTemplates,
    categories,
    typesCodes,
  };
};
