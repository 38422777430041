import { useState, useRef } from 'react';
import UploadImage from '../components/Utils/UploadImage';
import { toast } from 'react-hot-toast';
import { ProjectsEndpoints } from '../Routes/api/Endpoints';
import { useNavigate } from 'react-router-dom';
import { useAddProjectMutation } from '../services/auth-services/AuthService';
import { LOGIN_PATH } from '../Routes/navigation/navigationPaths';
import defaultImg from "../assets/implement-2372179_640.jpg"


const useAjoutProjectForm = (initialFormState, formProps, formPropsLinkAndObj) => {
  const [text, setText] = useState("");
  const [addProspect, { isLoading: isLoadingProjectAdded }] = useAddProjectMutation();
  const [errors, setErrors] = useState({});
  const formRef = useRef(null);
  const [formState, setFormState] = useState(initialFormState);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (files) {
      UploadImage(e, setFormState);
      // console.log(files);
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [id]: value
      }));
      if (errors[id]) {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[id];
          return newErrors;
        });
      }
    }
  };

  const handleTextChange = (value) => {
    setText(value);
    if (errors['text']) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors['text'];
        return newErrors;
      });
    }
  };

  const submitForm = (prospectFormData) => {
    addProspect({
      endpoints: ProjectsEndpoints.postPorteurProjet,
      formData: prospectFormData,
    })
      .unwrap()
      .then((prospectData) => {
        formRef.current.reset();
        setText("");
        setFormState(initialFormState);
        toast.success(prospectData.message);
        setErrors({});
        navigate("/");
      })
      .catch((error) => {
        if (error.status === 403) {
          toast.error(error?.data?.message);
          const timer = setTimeout(() => {
            navigate(`${LOGIN_PATH}`);
          }, 2000);
          return () => clearTimeout(timer);
        }
        toast.error(error?.data?.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = [
      "name",
      "email",
      "number",
      "pays",
      "titre",
      "objectif",
      "budget",
    ];
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formState[field]) {
        newErrors[field] = `Le champ ${field} est requis.`;
      }
    });

    if (text.trim() === "") {
      newErrors["text"] = "Le champ description est requis.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const prospectFormData = new FormData();

    // Ajouter les autres champs au formulaire
    Object.entries(formState).forEach(([key, value]) => {
      if (key !== "image") {
        prospectFormData.append(key, value);
      }
    });
    prospectFormData.append("description", text);

    
    if (formState.image) {
      prospectFormData.append("image", formState.image);
      submitForm(prospectFormData);
    } else {
      fetch(defaultImg)
        .then((res) => res.blob())
        .then((blob) => {
          const defaultFile = new File([blob], "defaultImage.jpg", {
            type: "image/jpeg",
          });
          prospectFormData.append("image", defaultFile);
          submitForm(prospectFormData);
      });
    }
  };

  return {
    text,
    handleTextChange,
    errors,
    formRef,
    formState,
    handleChange,
    handleSubmit,
    isLoadingProjectAdded,
  };
};

export default useAjoutProjectForm;
