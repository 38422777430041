import React, { useState } from "react";
import TestimonialItem from "./TestimonialItem";
import { testimonials } from "./TestimonialData";
import Quot from "../../../assets/images/quot.png";


export const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="overflow-y-scroll pb-6 flex items-center h-max scrollbar-none">
      <div className="w-full">
        <h2 className="text-[45px] max-xl:text-[48px] max-sm:text-[30px] mt-10 font-semibold mb-5 max-xl:leading-[48px] leading-[58px] text-dsn">
          Les gens parlent <br /> de Bakeli Work
        </h2>
        <p className="mb-6 font-[500] text-dsn max-xl:text-[16px] leading-7 text-base">
          Tout ce dont vous avez besoin pour soumettre vos projets, permettre
          aux Bakelistes de postuler sur des tâches, les accomplir pour gagner
          de l'argent, et télécharger des templates de Landing Page et
          Dashboard.
        </p>

        <div className="pb-8 max-xl:pb-4">
          <img src={Quot} alt="quot" className="w-10" />
        </div>
        <TestimonialItem {...testimonials[currentIndex]} />

        <div className="flex items-center justify-start mt-6 space-x-4">
          {testimonials.map((item, index) => (
            <img
              key={index}
              src={item.avatar}
              alt={`Avatar ${index + 1}`}
              className={`size-16 object-cover rounded-full cursor-pointer transition-all duration-300
              ${index === currentIndex ? "border-[4px] border-orange" : ""}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
