import React from "react";
import LoginComponent from "../../components/LoginComponent/LoginComponent";
import { Testimonial } from "./Testimonials/Testimonial";

function LoginPage() {
  return (
    <div className="bg-fond bg-cover bg-no-repeat flex justify-center">
      <div
        className=" flex max-lg:flex-col-reverse max-lg:justify-center gap-20 max-lg:gap-0 max-xl:gap-14 justify-around 
      max-lg:w-full w-[82%] max-xl:w-[90%] h-max max-lg:h-auto items-center pt-8"
      >
        <div className="w-[46%] max-lg:w-5/6">
          <Testimonial />
        </div>
        <div className="w-[54%] max-lg:w-5/6 max-lg:mt-12 max-sm:mt-14 max-sm:w-[93%] ">
          <LoginComponent />
        </div>
      </div>
    </div>  
  );
}

export default LoginPage;
