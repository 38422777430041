import { logout } from "../features/userSliceWithTokenValidation";

export const logoutOnCloseMiddleware = (store) => {
  window.addEventListener("beforeunload", (event) => {
    const state = store.getState();
    const isAuthenticated = state.auth?.isAuthenticated;

    // Vérifie si l'événement est déclenché par la fermeture de l'onglet
    if (isAuthenticated && event.target.visibilityState === "hidden") {
      store.dispatch(logout());
      sessionStorage.clear(); // Utilisation de sessionStorage
      window.location.href = "/connexion";
    }
  });

  return (next) => (action) => next(action);
};
