import React from "react";

const CardDataStats = ({
  bgIcon,
  icone,
  sousTitre,
  total,
  typeCard,
  statsData,
  cardData,
}) => {
  return (
    <>
      {typeCard === "longue" ? (
        // Carte longue
        <div className="rounded-lg bg-white py-2 w-1/2 max-sm:w-full px-6 shadow-sm col-span-4">
          <div className="flex gap-3 items-center">
            <div
              className={`size-11 rounded-lg flex ${bgIcon} items-center justify-center`}
            >
              {cardData.icone}
            </div>
            <h4 className="text-2xl text-orange">{cardData.title}</h4>
          </div>
          <div className="grid grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 mt-2 gap-4">
            {statsData.map((item) => (
              <div key={item.label} className={` ${item.bordure} ps-2`}>
                <p className={`text-3xl font-bold ${item.colorClass}`}>
                  {item.value}
                </p>
                <p className="text-sm text-mr/60 font-medium pb-1">
                  {item.label}
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        // Carte courte
        <div className="rounded-lg flex items-center justify-between bg-white py-3 px-6 shadow-sm">
          <div className=" items-center bg- justify-between">
            <h4 className="text-3xl pb-1 font-bold text-mr/60">{total}</h4>
          <p className="text-sm text-mr/60 font-medium">{sousTitre}</p>
          </div>
            <div
              className={`size-12 rounded-lg ${bgIcon} flex items-center justify-center`}
            >
              {icone}
            </div>
        </div>
      )}
    </>
  );
};

export default CardDataStats;
