import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useDeleteDataMutation,
  useFetchNotificationsQuery,
  useGetDataQuery,
  usePatchDataMutation,
} from "../../services/auth-services/AuthService";
import { ProjectsEndpoints } from "../../Routes/api/Endpoints";
import { useModal } from "../../context/ModalContext";
import { useSelector } from "react-redux";
import { selectData } from "../../redux/features/userSliceWithTokenValidation";
import { useAppContext } from "../../context/AppContext";
import { formatDuration } from "../Utils/FormatDate";
import { getFirstLetters } from "../Utils/Variables/constantes";
// import { HiOutlineDotsHorizontal } from "react-icons/hi";
import NotificationOptions from "./projectsComponents/NotificationOptions";
import SkeletonLoader from "../Utils/Skeleton";

const Notification = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [localNotifications, setLocalNotifications] = useState([]);
  const navigate = useNavigate();
  const { apiUrlImg } = useAppContext();
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const { data: notifications, refetch } = useFetchNotificationsQuery(
    ProjectsEndpoints.getNotifications
  );
  const { openModal } = useModal();
  const user = useSelector(selectData);
  const notificationsUser = localNotifications.length
    ? localNotifications
    : notifications?.filter((notif) => notif.destinateur_id === user?.id);
  const [putNotification] = usePatchDataMutation();
  const [deleteNotification, { isLoading }] = useDeleteDataMutation();
  const [deletingNotifId, setDeletingNotifId] = useState(null);

  const unreadNotifications = notificationsUser?.filter((notif) => !notif.read);

  const { data: taskID, refetch: refetchTask } = useGetDataQuery(
    selectedProjectId
      ? {
          endpoint: ProjectsEndpoints.getTaskById,
          projectId: selectedProjectId,
        }
      : null,
    { skip: !selectedProjectId }
  );

  const handleNotificationClick = (
    slug,
    notificationId,
    projectId,
    notification
  ) => {
    setLocalNotifications((prev) =>
      prev.map((notif) =>
        notif.id === notificationId ? { ...notif, read: true } : notif
      )
    );

    putNotification({
      endpoint: ProjectsEndpoints.putNotifications,
      task: { id: notificationId, terminer: true },
    })
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la mise à jour de la notification :",
          error
        );
        setLocalNotifications((prev) =>
          prev.map((notif) =>
            notif.id === notificationId ? { ...notif, read: false } : notif
          )
        );
      });

    setSelectedProjectId(projectId);
    navigate(`/client-dashboard/projects/${slug}`);

    if (projectId !== null) {
      openModal(taskID?.tache, refetchTask, notification);
    }
  };

  const handleDeleteNotification = async (notifId) => {
    await setDeletingNotifId(notifId)
    deleteNotification({
      endpoint: ProjectsEndpoints.deleteNotifications,
      dataId: notifId,
    })
      .unwrap()
      .then(() => {
        refetch();
        setDeletingNotifId(null)
      })
      .catch((error) => {
        console.error("Error deleting comment:", error);
        setDeletingNotifId(null);
      });
  };

  const filteredNotifications =
    activeTab === 0 ? notificationsUser : unreadNotifications;

  return (
    <div className="dropdown dropdown-end">
      <button tabIndex="0" className="btn btn-ghost btn-circle">
        <div className="indicator">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2em"
            height="1.2em"
            className="cursor-pointer"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M18.134 11C18.715 16.375 21 18 21 18H3s3-2.133 3-9.6c0-1.697.632-3.325 1.757-4.525S10.41 2 12 2q.507 0 1 .09M19 8a3 3 0 1 0 0-6a3 3 0 0 0 0 6m-5.27 13a2 2 0 0 1-3.46 0"
            ></path>
          </svg>
          {unreadNotifications?.length > 0 && (
            <span className="badge badge-xs badge-error top-0 text-white text-[8px] w-4 h-4 right-1 indicator-item">
              {unreadNotifications.length > 99
                ? "+99"
                : unreadNotifications.length}
            </span>
          )}
        </div>
      </button>
      <div
        tabIndex="0"
        className="dropdown-content menu rounded-box z-[1] !-right-24 w-96 shadow bg-white"
      >
        <span className="font-semibold text-sm text-center border-b w-full py-2">
          Notifications
        </span>
        <div className="w-full flex flex-col">
          <ul className="list-none flex items-center w-[50%] px-2 py-4">
            <li
              className={`cursor-pointer text-xs text-center w-1/2 ${
                activeTab === 0 && "py-1 text-white bg-blue-500 rounded-badge"
              }`}
              onClick={() => setActiveTab(0)}
            >
              Tous
            </li>
            <li
              className={`cursor-pointer text-xs w-1/2 text-center ${
                activeTab === 1 && "py-1 text-white bg-blue-500 rounded-badge"
              }`}
              onClick={() => setActiveTab(1)}
            >
              Non lus
            </li>
          </ul>
          <div className="">
            <ul className="py-4 flex flex-col gap-3 max-h-[60vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-gr">
              {filteredNotifications?.map((elem, index) => (
                <div
                  key={index}
                  onClick={() =>
                    handleNotificationClick(
                      elem?.projet?.slug,
                      elem?.id,
                      elem?.tache_id,
                      elem
                    )
                  }
                  className="flex gap-2 items-start justify-between bg-gray-200/60 rounded-box px-4 py-2 hover:bg-gray-300/60 relative"
                >
                  {isLoading && elem?.id === deletingNotifId ? (
                      <SkeletonLoader notif={true} />
                  ) : null}
                  {elem?.auteur?.profile ? (
                    <img
                      src={`${apiUrlImg}/${elem?.auteur?.profile}`}
                      alt="userProfile"
                      className="w-12 h-12 rounded-full object-cover"
                    />
                  ) : (
                    <span className="w-14 h-14 rounded-full bg-bl text-white text-base font-semibold text-center flex justify-center items-center">
                      {getFirstLetters(elem?.auteur?.name)}
                    </span>
                  )}
                  <div className="text-xs leading-5 w-3/4">
                    <div className="cursor-pointer">
                      <span className="font-inter text-[12px] font-semibold me-1">
                        {elem?.auteur?.name}
                      </span>
                      {elem?.message}
                    </div>
                    <span className="text-xs font-sans text-orange">
                      Il y'a {formatDuration(elem?.created_at)}
                    </span>
                  </div>
                  <NotificationOptions
                    read={elem?.read}
                    notifId={elem?.id}
                    handleDelete={handleDeleteNotification}
                  />
                </div>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
