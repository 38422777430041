const UploadImage = async (e, setFormState) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFormState((prevState) => ({
          ...prevState,
          image: file
        }));
      };
    }
  };
  
  export default UploadImage;