
export const formProps = [
  {
    label: "Prénom et Nom",
    type: "text",
    id: "name",
    paddingB:
      "!h-8 !w-[60%] !rounded-r-lg !bg-transparent border-grayborder/60 !shadow-none !text-sm ps-4",
    labelStyle:
      "text-xs font-inter px-2 !w-[40%] bg-blc !h-8 flex items-center border !border-grayborder/90 rounded-l-lg",
  },
  {
    label: "Email",
    type: "text",
    id: "email",
    paddingB:
      "!h-8 !rounded-r-lg !w-[60%] !bg-transparent border-grayborder/70 !shadow-none !text-xs ps-4",
    labelStyle:
      "text-xs font-inter px-2 gap-1 !w-[40%] bg-blc !h-8 flex items-center border !border-grayborder rounded-l-lg",
  },
  {
    label: "Téléphone",
    type: "number",
    id: "number",
    paddingB:
      "!h-8 !rounded-r-lg !w-[60%] !bg-transparent border-grayborder/70 !shadow-none !text-xs ps-4",
    labelStyle:
      "text-xs font-inter px-2 gap-1 !w-[40%] bg-blc !h-8 flex items-center border !border-grayborder rounded-l-lg",
  },
  {
    label: "Pays de résidence",
    type: "text",
    id: "pays",
    paddingB:
      "!rounded-r-lg !w-[60%] !bg-transparent border-grayborder/70 !shadow-none !text-xs ps-4",
    labelStyle:
      "text-xs font-inter px-2 gap-1 !w-[40%] bg-!blc flex items-center border !border-grayborder rounded-l-lg",
  },
  {
    label: "Le nom de votre projet",
    type: "text",
    id: "titre",
    paddingB:
      "!h-8 !rounded-r-lg !w-[60%] !bg-transparent border-grayborder/70 !shadow-none !text-xs ps-4",
    labelStyle:
      "text-xs font-inter px-2 gap-1 !w-[40%] bg-blc !h-8 flex items-center border !border-grayborder rounded-l-lg",
  },
  {
    label: "Votre budget en FCFA(XOF)",
    type: "number",
    id: "budget",
    paddingB:
      "!h-8 !rounded-r-lg !w-[60%] !bg-transparent border-grayborder/70 !shadow-none !text-xs ps-4",
    labelStyle:
      "text-xs font-inter px-2 gap-1 !w-[40%] bg-blc !h-8 flex items-center border !border-grayborder rounded-l-lg",
    suffix: (
      <div className="rounded-r-lg text-center items-center flex text-xs px-5 text-blc bg-vr -ms-[70px] ">
        FCFA
      </div>
    ),
  },
];

export const formLabelImage = (preview) => {
  return (<div className="flex flex-col gap-1 w-full">
    <div className="h-72 flex justify-center items-center w-full rounded-md text-xs bg-transparent border border-grayborder">{preview}</div>
  </div>)
}

export const formPropsLinkAndObj = [
  {
    label: "Objectif du projet",
    type: "text",
    id: "objectif",
    paddingB:
      "!h-8 !rounded-r-lg !w-[60%] !bg-transparent border-grayborder/70 !shadow-none !text-xs ps-4",
    labelStyle:
      "text-xs font-inter px-2 gap-1 !w-[40%] bg-blc !h-8 flex items-center border !border-grayborder rounded-l-lg",
  },
  {
    label: "Fichier de moins de 5 Mo (lien)",
    type: "url",
    id: "document",
    paddingB:
      "!h-8 !rounded-r-lg !w-[60%] !bg-transparent border-grayborder/70 !shadow-none !text-xs ps-4",
    labelStyle:
      "text-xs font-inter px-2 gap-1 !w-[40%] bg-blc !h-8 flex items-center border !border-grayborder rounded-l-lg",
  },
];

export const formClientSideProps = [
  {
    label: "Le nom de votre projet",
    type: "text",
    id: "titre",
    placeholder: "Le nom de votre projet",
    paddingB:
      "!h-8 !rounded-r-lg !w-[60%] !bg-transparent border-grayborder/70 !shadow-none !text-xs ps-4",
    labelStyle:
      "text-xs font-inter px-2 gap-1 !w-[40%] bg-blc !h-8 flex items-center border !border-grayborder rounded-l-lg",
  },
  {
    label: "Votre budget en FCFA(XOF)",
    type: "number",
    id: "budget",
    placeholder: "Votre budget",
    paddingB:
      "!h-8 !rounded-r-lg !w-[60%] !bg-transparent border-grayborder/70 !shadow-none !text-xs ps-4",
    labelStyle:
      "text-xs font-inter px-2 gap-1 !w-[40%] bg-blc !h-8 flex items-center border !border-grayborder rounded-l-lg",
    suffix: (
      <div className="rounded-r-lg text-center items-center flex text-xs px-5 text-blc bg-vr -ms-[70px] ">
        FCFA
      </div>
    ),
  },
];