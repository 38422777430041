import { createSlice } from "@reduxjs/toolkit";

const userSliceWithTokenValidation = createSlice({
    name: "auth",
    initialState: {
        accessToken: null,
        refreshToken: null,
        isAuthenticated: false,
        data: null,
    },
    reducers: {
        setAccessToken: (state, action) => {
            return {
                ...state,
                accessToken: action.payload,
                isAuthenticated: true
            }
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload;
        },
        setCredentials: (state, action) => {
            return {
                ...state,
                refreshToken: action.payload.refresh_token,
                accessToken: action.payload.access_token,
                isAuthenticated: true
            }
        },
        setUserData: (state, action) => {
            return {
                ...state,
                data: action.payload,
            };
        },
        setSkeep(state) {
            state.isSkeep = true;
        },
        logout: (state) => {
            state.accessToken = null;
            state.refreshToken = null;
            state.isAuthenticated = false;
            state.data = null;
        }
    },
});

export const getUserStatus = (state) => state?.auth?.isAuthenticated;
export const getSkeep = (state) => state?.dataPersisted?.user?.isSkeep;
// export const getStatus = (state) => state?.dataPersisted?.isAuthenticated
export const selectToken = (state) => state?.auth?.accessToken;
export const selectData = (state) => state?.auth?.data;

export const { setCredentials, setAccessToken, setUserData, setRefreshToken, logout, setSkeep } =
    userSliceWithTokenValidation.actions;   

export default userSliceWithTokenValidation.reducer;