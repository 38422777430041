import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import NavbarClient from '../../components/client/NavbarClient';
import SidebarClient from '../../components/client/SidebarClient';
import { useModal } from '../../context/ModalContext';
import Modal from '../../components/Utils/Modal';
import Breadcrumbs from '../../components/Utils/Breadcrumbs';
import CarouselModal from '../../components/Utils/CarouselModal';
import FooterDashboard from '../../components/client/projectsComponents/FooterDashboard';

function ClientPage() {
    const { isModalOpen, closeModal, modalTask, livraisonModal, closeModalLivraison,modalCarousel, modalNotifications } = useModal();
    const [toggle, setToggle] = useState(false);
    
    return (
      <div className="flex flex-col h-screen bg-fond bg-no-repeat bg-cover">
        <div className="w-full">
          <NavbarClient sidebarOpen={toggle} setSidebarOpen={setToggle} />
        </div>

        <div className="flex overflow-hidden">
          <SidebarClient sidebarOpen={toggle} setSidebarOpen={setToggle} />

          <div className="relative flex-1 flex-col overflow-y-auto">
            {isModalOpen && (
              <Modal
                task={modalTask}
                onClose={closeModal}
                notifications={modalNotifications}
              />
            )}
            {livraisonModal && (
              <CarouselModal
                onClose={closeModalLivraison}
                modalCarousel={true}
                images={modalCarousel}
              />
            )}

            <main className='ps-3'>
              <div className="mx-auto max-w-screen flex flex-col justify-between min-h-screen bg-white_100 border-t border-l rounded-tl-lg border-gryborder">
                {/* <div className="px-7 pb-3">
                  <Breadcrumbs />
                </div> */}
                <Outlet />
                <FooterDashboard />
              </div>
            </main>
          </div>
        </div>
      </div>
    );
}

export default ClientPage;