import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CLIENT_DASHBOARDS, CLIENT_DASHBOARDS_DASHBOARD } from '../../Routes/navigation/navigationPaths';

const cleanProjectName = (name) => {
    const cleanedName = name.replace(/-\d+$/, '').replace(/-/g, ' ');
    return cleanedName.charAt(0).toUpperCase() + cleanedName.slice(1);
};

const formatPath = (path) =>
  path.split('/')
    .filter(part => part.toLowerCase() !== 'client-dashboard')
    .map((part, index, arr) =>
      index === arr.length - 1 ? cleanProjectName(part) :
      part.toLowerCase() === 'dashboard' ? 'Dashboard' : part.toLowerCase() === 'projects' ? 'Projets' :
      part.toLowerCase() === 'livraisons' ? 'Livraisons' :
      part
    )
    .filter(Boolean);

const Breadcrumbs = ({ home, position }) => {
  const location = useLocation();
  const pathnames = formatPath(location.pathname);
  
  return (
    <>
      <nav className={position} aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li className="inline-flex items-center">
            <Link to={home ? "/" : `/${CLIENT_DASHBOARDS}${CLIENT_DASHBOARDS_DASHBOARD}`} className={`inline-flex items-center text-base ${home ? "text-white" : "text-gray-400"}  font-semibold font-inter`}>
              Accueil
            </Link>
          </li>
          {pathnames.map((value, index) => {
            const originalPathnames = location.pathname.split('/').filter(Boolean);
            const filteredPathnames = originalPathnames.filter(part => part.toLowerCase() !== 'client-dashboard');
            const to = `/${originalPathnames.slice(0, originalPathnames.indexOf(filteredPathnames[index]) + 1).join('/')}`;

            return (
              <li key={to}>
                <div className="flex items-center">
                  <svg className={`rtl:rotate-180 w-2 h-2 ${home ? "text-white" : "text-gray-700 hover:text-orange dark:text-gray-400"} !font-bold mx-1`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" d="m1 9 4-4-4-4"/>
                  </svg>
                  {index === pathnames.length - 1 ? (
                    <span className={`ms-1 text-base font-semibold ${home ? "text-white" : "text-orange"} font-inter md:ms-2 dark:text-orange-400`}>
                      {value}
                    </span>
                  ) : (
                    <Link to={to} className={`ms-1 text-base font-semibold ${home ? "text-white" : "text-gray-700 md:ms-2 dark:text-gray-400 dark:hover:text-orange-400"} font-inter md:ms-2`}>
                      {value}
                    </Link>
                  )}
                </div>
              </li>
            );
          })}
        </ol>
      </nav>
    </>
  );
}

export default Breadcrumbs;