import React from 'react';
import { PulseLoader } from 'react-spinners';
import ReplyForm from './ReplyForm';
import MonBouton from '../../../Utils/design-system/buttons/MonBouton';
import { IoCheckmark, IoCloseOutline } from 'react-icons/io5';

const EditableComment = ({ editCommentText, setEditCommentText, handleUpdate, handleCancel, commentId, isLoadingUpdateComment, replyCommentId, setReplyCommentId, replyText, setReplyText, handleReplySubmit, handleCancelReply }) => {
  return (
    <div className='w-auto'>
      {replyCommentId === commentId && (
        <ReplyForm
          replyText={replyText}
          setReplyText={setReplyText}
          handleReplySubmit={() => handleReplySubmit(commentId)}
          handleCancelReply={handleCancelReply}
        />
      )}
      <textarea
        className='w-full p-2 border-none rounded-md outline-none text-xs bg-transparent resize-none'
        value={editCommentText}
        onChange={(e) => setEditCommentText(e.target.value)}
      />
      <div className='flex gap-2 mt-2 w-full'>
      <MonBouton
        children={"Annuler"}
        couleurFond={"text-xs text-grys"}
        bordure={"px-2"}
        // icone={<IoCloseOutline />}
        action={handleCancel}
        type={"button"}
        alignment={""}
      />
      <MonBouton
        children={isLoadingUpdateComment ? <PulseLoader size={5} color='#00B38A' /> : "Enrégistrer"}
        couleurFond={"text-xs text-vr"}
        bordure={"px-2"}
        // icone={isLoadingUpdateComment ? "" : <IoCheckmark />}
        action={() => handleUpdate(commentId)}
        type={"button"}
        alignment={""}
      />
      </div>
    </div>
  );
};

export default EditableComment;