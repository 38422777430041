import React from 'react'
import AjoutProjetClientSide from '../client/dashboardComponents/AjoutProjetClientSide'

function AddProject() {
    return (
        <div className='flex justify-center pb-3'>
            <div className='w-[90%] bg-gry rounded-md p-8'>
                <div className='header py-2'>
                    <h1 className='font-sans font-medium text-lg text-blck'>Vous avez un projet, une idée que vous voulez concretiser ? faites le nous savoir...</h1>
                </div>
                <div className='py-3'>
                    <AjoutProjetClientSide />
                </div>
            </div>
        </div>
    )
}

export default AddProject