import React, { useState } from 'react';
import ProjectTask from './ProjectTask';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useModal } from '../../../context/ModalContext';
import { selectData } from '../../../redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';
import { LuArrowLeftToLine, LuArrowRightFromLine } from 'react-icons/lu';

function ProjectColumn({ title, backGround, id, taches, numberTask, refetch, profileLetters, onClose, setOnClose }) {
  const { openModal } = useModal();
  
  const handleCloseColumn = () => {
    setOnClose(!onClose)
  }
  
  const handleTaskClick = (task) => {
    openModal(task, refetch);
  };
  const user = useSelector(selectData)
  const userTasks = taches && taches.filter((task) => task.assigned_to_id === user?.id || task.assigned_to_id === null);
  
  return (
    <div
      className={`card rounded-lg max-h-full bg-white w-[320px] shadow-sm opacity-90 ${onClose && "h-full !w-full"}`}
    >
      <Droppable droppableId={id} type="task">
        {(provided) => (
          <div
            className={`content gap-1 z-50 ${onClose && "h-full"}`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className="card rounded-xl border-gr flex flex-col items-center">
              <div
                className={`header h-[55px] text-noir_300 border-b-[2.5px] ${backGround} flex ${onClose && "!py-6"} justify-between items-center w-11/12 rounded-t-lg`}
              >
                <div
                  className={`px-1 text-xs inline-flex gap-2 items-center font-inter font-bold ${onClose && "hidden"}`}
                >
                  {title}
                  <span className="text-xs bg-gry_100 w-6 text-center h-6 flex justify-center items-center rounded-full">
                    {numberTask ? numberTask : 0}
                  </span>
                </div>
                <div
                  className={`w-2/4 flex gap-2 px-2 items-center justify-end cursor-pointer ${onClose && "justify-center w-full"}`}
                  onClick={() => handleCloseColumn(!onClose)}
                >
                  {onClose ? (
                    <LuArrowRightFromLine
                      size={14}
                      className="cursor-pointer"
                    />
                  ) : (
                    <LuArrowLeftToLine size={14} />
                  )}
                </div>
              </div>
            </div>
            {onClose && (
              <div className="font-inter font-semibold text-noir_300 !h-[40vh] mt-8 flex flex-col">
                <h3
                  className="-rotate-90 flex items-center gap-3"
                  style={{
                    writingMode: "vertical-rl",
                    transform: "rotate(180deg)",
                  }}
                >
                  {title}
                  <span className="text-xs bg-gry_100 w-6 text-center h-6 flex justify-center items-center rounded-full">
                    {numberTask ? numberTask : 0}
                  </span>
                </h3>
              </div>
            )}
            <div
              className={`overflow-y-auto content ${
                taches?.length === 0 ? "!max-h-max" : "h-[60vh]"
              } overflow-x-hidden scrollbar-thin ${onClose && "hidden"} !scrollbar-thumb-rounded-full scrollbar-thumb-gray-300 scrollbar-track-gray-200 !scrollbar-track-rounded-full py-3`}
            >
              {user.statut === "coach"
                ? taches?.map((item, index) => (
                    <Draggable
                      draggableId={item.id.toString()}
                      key={item.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          onClick={() => handleTaskClick(item)}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`py-2 border-b border-gr/60 px-2`}
                        >
                          <ProjectTask
                            text={item.titre}
                            item={item}
                            members={item.assigned_to_name}
                            profileLetters={profileLetters}
                            user={user}
                            img={item?.image}
                            date={item?.finished_at}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))
                : userTasks?.map((item, index) => (
                    <Draggable
                      draggableId={item.id.toString()}
                      key={item.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          onClick={() => handleTaskClick(item)}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="py-2 border-b px-2"
                        >
                          <ProjectTask
                            text={item.titre}
                            item={item}
                            members={item.assigned_to_name}
                            profileLetters={profileLetters}
                            user={user}
                            img={item?.image}
                            date={item?.finished_at}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

export default ProjectColumn;