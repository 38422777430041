import React, { useState } from "react";
import { RiArrowDropDownFill, RiArrowDropRightFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { CLIENT_DASHBOARDS, PROJECTS } from "../../Routes/navigation/navigationPaths";
import { ProjectsEndpoints } from "../../Routes/api/Endpoints";
import { selectData } from "../../redux/features/userSliceWithTokenValidation";
import { useSelector } from "react-redux";
import { useFetchProjectsQuery } from "../../services/auth-services/AuthService";
import { isNewProject } from "../Utils/FormatDate";
import { useAppContext } from "../../context/AppContext";

function SidebarItem({ icon, text, path, dropdown }) {
  // const [isContentVisible, setIsContentVisible] = useState(false);
  // const [isIconExpanded, setIsIconExpanded] = useState(false);
  const { data: projets, refetch } = useFetchProjectsQuery(ProjectsEndpoints.getProjects, {refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    skip: false
  });
  const { toggleDropdown, isContentVisible, isIconExpanded } = useAppContext();
  const user = useSelector(selectData)
  const filteredProjects = projets?.filter((proj) => proj.assigned_to_id === user?.id);
  const projectsBakeliste = projets?.filter((proj) => proj.participants_ids?.includes(user?.id))
  // const toggleDropdown = () => {
  //   setIsContentVisible(!isContentVisible);
  //   setIsIconExpanded(!isIconExpanded);
  // };

  const truncateTitle = (title) => {
    return title.length > 10 ? `${title.slice(0, 8)}...` : title;
  };

  return (
    <div>
      <NavLink to={path} 
        className={`flex ${dropdown ? "px-0" : "px-3"} items-center max-xl:space-x-0 text-blueclear max-xl:px-0 max-xl:ps-2 py-1 containerItems`}
        onClick={dropdown ? toggleDropdown : undefined}
      >
        
        <span className={`font-bold inline-flex text-lg w-1/4 items-center justify-center`}>
          {dropdown && (
          <span>
            {isIconExpanded ? <RiArrowDropDownFill size={18} /> : <RiArrowDropRightFill size={18} />}
          </span>
        )}
          {icon}
        </span>
        <span className="text-xs py-1 text-nowrap font-semibold text-start w-3/4 font-inter max-xl:text-xs">
            {text}
        </span>
      </NavLink>
      {dropdown && isContentVisible && (
        <div className="text-blueclear pt-2">
          {user?.statut ==="coach"? filteredProjects?.map((proj, i) => (
            <NavLink className="flex items-center justify-end space-x-2 pt-2 cursor-pointer containerItem" key={i} to={`/${CLIENT_DASHBOARDS}${PROJECTS}/${proj.slug}`}>
              <div className="w-[68%] flex items-center gap-4 justify-start">
                <span className="text-xs  font-inter text-center font-semibold">{isNewProject(proj.created_at) ? truncateTitle(proj.titre) : proj.titre}</span>
              {isNewProject(proj.created_at) && (
                <span className="bg-[#FFEFCA] justify-center items-center rounded-md text-[#BC3803] text-[11px] py-1 px-1 font-inter w-max text-xs border border-[#FFCC85]">
                  Nouveau
                </span>
                )}
              </div>
            </NavLink>
          )): projectsBakeliste?.map((proj, i) => (
            <NavLink className="flex items-center justify-end space-x-2 gap-2 pt-2 cursor-pointer containerItem max-xl:px-0 max-xl:gap-1" key={i} to={`/${CLIENT_DASHBOARDS}${PROJECTS}/${proj.slug}`}>
              <div className="w-[68%] flex items-center gap-4 justify-start">
                <span className="text-xs font-inter text-center font-semibold">{isNewProject(proj.created_at) ? truncateTitle(proj.titre) : proj.titre}</span>
                {isNewProject(proj.created_at) && (
                  <span className="bg-[#FFEFCA] justify-center items-center rounded-md text-[#BC3803] text-[11px] py-1 px-1 font-inter w-max text-xs border border-[#FFCC85]">
                    Nouveau
                  </span>
                )}
              </div>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
}

export default SidebarItem;