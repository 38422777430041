import DetailsProject from "../../../components/composPageAccueil/DetailsProject";
import PageAccueil from "../../../components/composPageAccueil/PageAccueil";
import TelechargePage from "../../../components/Templates/TelechargePage";
import { TotalTemplates } from "../../../components/Templates/TotalTemplates";
import Acceuil from "../../../pages/Acceuil/Acceuil";
import AddingProjectPage from "../../../pages/Client/AddingProjectPage";
import ErrorBoundary from "../../../pages/ErrorBoundary";
import { Inscription } from "../../../pages/Inscription";
import LoginPage from "../../../pages/Login/LoginPage";
import ProjectsPage from "../../../pages/projets/ProjectsPage";
import ResetPassword from "../../../pages/ResetPassword";
import TemplatesPage from "../../../pages/templates/TemplatesPage";
import { DASHBOARDS, DETAIL_PROJET_ACCEUIL, LOGIN_PATH, PROJECTSACCUEIL, PUBLIC_PATH, REGISTER_PATH, RESETPASSWORD, SOUMETTRE_PROJET, TELECHARGER_TEMPLATES, TEMPLATESPAGES } from "../navigationPaths";


/*
|---------------------------------------------------------------
| Les routes publics
|---------------------------------------------------------------
| Toute page qui ne necessite pas une authentification doit etre ici
|
*/
export const publicRoutes = [
    {
        path: PUBLIC_PATH,
        errorElement: <ErrorBoundary />,
        element: <Acceuil />,
        children : [
            {
                path: "",
                element: <PageAccueil />
            },
            {
                path: SOUMETTRE_PROJET,
                element: <AddingProjectPage />
            }
        ]
    },
    {
        path: TELECHARGER_TEMPLATES,
        element: <TelechargePage />
    },
    {
        path: DETAIL_PROJET_ACCEUIL,
        element: <DetailsProject />
    },
    {
        path: PROJECTSACCUEIL,
        element: <ProjectsPage />
    },
    {
        path: TEMPLATESPAGES,
        element : <TemplatesPage />
    },
    {
        path: `${PROJECTSACCUEIL}${DETAIL_PROJET_ACCEUIL}`,
        element : <DetailsProject />
    },
    {
        path: DASHBOARDS,
        element: <TotalTemplates />
    },
    {
        path: LOGIN_PATH,
        element: <LoginPage />,
    },
    {
        path: `${LOGIN_PATH}/:slug`,
        element: <LoginPage />,
    },
    {
        path: RESETPASSWORD,
        element: <ResetPassword />
    },
    {
        path: REGISTER_PATH,
        element: <Inscription />
    }
];