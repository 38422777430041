import React from 'react'
import { GoDotFill } from 'react-icons/go';
import { GrAttachment } from 'react-icons/gr';
import { useAppContext } from '../../../context/AppContext';
import { FaCalendarXmark } from "react-icons/fa6";
import { formatDateShortMonth } from '../../Utils/FormatDate';

function ProjectTask({text, members, item, id, profileLetters, img, date}) {
  const firstLetters = profileLetters(members);
  const { apiUrlImg } = useAppContext();
  return (
    <div
      className="card bg-white_100 cursor-pointer min-h-[70px] p-3 rounded-lg text-black content"
      id={id}
    >
      <div className={`flex flex-col justify-between min-h-[70px] gap-2 `}>
        {img && (
          <div className="h-44">
            <img
              src={`${apiUrlImg}/${img}`}
              alt="img"
              className="w-full h-full rounded-md"
            />
          </div>
        )}
        <div className="flex gap-1 items-center">
          <GoDotFill size={24} />
          <span className="text-[9px] font-medium border rounded-sm uppercase py-1 px-3">
            Indéfinie
          </span>
        </div>
        <p className="text-sm font-normal font-inter indigo_300">{text}</p>
        <div className="flex justify-between">
          <div className="flex items-center gap-3 w-2/4">
            {date && (
              <span className="flex gap-1 items-center text-xs font-mandali">
                <FaCalendarXmark />
                <span>{formatDateShortMonth(date)}</span>
              </span>
            )}
            <div className='flex gap-1 items-center'>
              <GrAttachment size={12} />
              <span className="text-xs font-mandali text-blueclear">15</span>
            </div>
          </div>
          <div className="w-1/4">
            {firstLetters && (
              <div
                className="size-6 tooltip cursor-pointer text-center items-center text-[8px] flex justify-center rounded-full border bg-bl text-blanc ms-auto"
                data-tip={members}
              >
                {firstLetters}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectTask