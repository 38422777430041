import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { FiAlertTriangle } from "react-icons/fi";
import { useAppContext } from "../context/AppContext";
import { Redirection } from "../components/PageTemplate";
import { Bouton, Entete } from "../components/Utils/Reutilisable";
import MonInput from "../components/Utils/design-system/inputs/MonInput";

export const Inscription = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [PasswordError, setPasswordError] = useState("");
  const [maskBtn, setMaskBtn] = useState(true);
  const [isLoading, setIsLoading] = useState(false); 
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [formError, setFormError] = useState(""); 
  const { apiUrlCat } = useAppContext();

  const signUp = async () => {
    if (!name || !email || !password) {
      setFormError("Veuillez remplir tous les champs.");
      return;
    }
    setIsLoading(true);
    let item = { name, email, password };
    let result = await fetch(`${apiUrlCat}/register`, {
      method: "POST",
      body: JSON.stringify(item),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    setIsLoading(false);
    if (result.status === 400) {
      let errorMessage = await result.json();
      setFormError(errorMessage.error);
    } else {
      let successMessage = await result.json();
      toast.success(successMessage.message);
      navigate("/connexion");
    }
  };

  const btnStatus = () => {
    setMaskBtn(
      emailError !== "" ||
        isLoading ||
        PasswordError !== "" ||
        !name ||
        !email ||
        !password
    );
  };

  return (
    <div className="w-full min-h-screen flex items-center justify-center backImg px-2">
      <form className="  flex flex-col justify-center w-full ">
        <div className=" ContaGeneral flex  w-full flex-col justify-center items-center">
          <div className="max-sm:w-full w-[500px] rounded shadow bg-grc flex flex-col mb-2 px-8 py-12">
            <Entete formError={formError} ques={"Inscrivez"} />
            <MonInput
              type="text"
              id="nom"
              value={name}
              placeholder={"Nom Complet"}
              onChange={(e) => {
                setName(e.target.value);
                const Err = !e.target.value.trim() ? (
                  <>
                    <FiAlertTriangle className="me-2" /> Ce champ ne peut pas
                    être vide.
                  </>
                ) : (
                  ""
                );
                setFirstNameError(Err);
                setFormError("");
                btnStatus();
              }}
              MessageError={firstNameError}
            />

            <MonInput
              type="email"
              id="email"
              value={email}
              placeholder={"E-mail"}
              onChange={(e) => {
                setEmail(e.target.value);
                const Err = !/^.+@.+\..+$/.test(e.target.value) ? (
                  <>
                    <FiAlertTriangle className="me-2" /> Adresse email invalide.
                  </>
                ) : (
                  ""
                );
                setEmailError(Err);
                setFormError("");
                btnStatus();
              }}
              MessageError={emailError}
            />
            <MonInput
              type="password"
              id="password"
              labelStyle={"text-gray-500"}
              placeholder={"Mot de passe"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                const Err = !e.target.value.trim() ? (
                  <>
                    <FiAlertTriangle className="me-2" /> Ce champ ne peut pas
                    être vide.
                  </>
                ) : (
                  ""
                );
                setPasswordError(Err);
                setFormError("");
                btnStatus();
              }}
              MessageError={PasswordError}
            />
            <Bouton isLoading={isLoading}emailError={emailError}maskBtn={maskBtn}action={signUp}text={"S'inscrire"}/>
          </div>

          <Redirection
            quest={"avez déjà un "}
            text={" Se connecter"}
            direct={"/connexion"}
          />
        </div>
      </form>
    </div>
  );
};
