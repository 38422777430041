import React from 'react'

const FooterDashboard = () => {
  return (
    <div className="bg-transparent font-mandali text-noir_300 py-4 text-sm font-normal border-t border-gryborder flex justify-between items-center w-full px-6">
      <h3 className="flex items-center gap-2">
        <span className="border-e pe-2 border-noir_300">
          Merci d'avoir utiliser Bakeli Work
        </span>
        <span>2024 &copy;</span>
      </h3>
      <h3>v1.0.0</h3>
    </div>
  );
}

export default FooterDashboard
