import { useSelector } from "react-redux";
import { getUserStatus } from "../redux/features/userSliceWithTokenValidation";
/**
 *
 * @return {true|false}
 */
function useIsAuthenticate() {
    const isAuthenticated  = useSelector(getUserStatus);
    return isAuthenticated;
}

export default useIsAuthenticate;