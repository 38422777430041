import React, { useState } from "react";
import { FaTrashCan } from "react-icons/fa6";
import { FiUpload } from "react-icons/fi";
import MonInput from "../../../Utils/design-system/inputs/MonInput";

const ImageContainerDisplay = ({
  images,
  onRemoveImage,
  width,
  height,
  margin,
  apiUrlImg,
  widthFixed,
  style,
  onAddImage,
}) => {
  const [pictures, setPictures] = useState([]);
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const uploadedImages = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    onAddImage(files);
    setPictures((prevImages) => [...prevImages, ...uploadedImages]);
  };

  const handleRemoveImage = (index) => {
    setPictures(pictures.filter((_, i) => i !== index));
  };

  return (
    <>
      {images && images.length > 0 && (
        <div
          className={`!overflow-x-scroll ${style} bg-wt mt-3 flex rounded-lg scrollbar-thumb-slate-700 scrollbar-track-slate-300 scrollbar-thin`}
        >
          {images.map((image, index) => (
            <div key={index} className={`relative`}>
              <img
                src={apiUrlImg ? `${apiUrlImg}/${image.captures}` : image}
                alt={`uploaded-${index}`}
                style={{
                  maxWidth: `${width}`,
                  width: `${widthFixed}`,
                  margin: `${margin}`,
                  height: `${height}`,
                }}
              />
              {!apiUrlImg && (
                <button
                  onClick={() => onRemoveImage(index)}
                  className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-700"
                  aria-label="Remove image"
                >
                  <FaTrashCan size={18} className="text-red hover:scale-110" />
                </button>
              )}
            </div>
          ))}
          {pictures &&
            pictures.length > 0 &&
            pictures.map((image, index) => (
              <div key={index} className={`relative ${widthFixed}`}>
                <img
                  src={image}
                  alt={`uploaded-${index}`}
                  style={{
                    maxWidth: `${width}`,
                    width: `${widthFixed}`,
                    margin: `${margin}`,
                    height: `${height}`,
                  }}
                />
                <button
                  onClick={() => handleRemoveImage(index)}
                  className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-700"
                  aria-label="Remove image"
                >
                  <FaTrashCan size={18} className="text-red hover:scale-110" />
                </button>
              </div>
            ))}
        </div>
      )}
      {apiUrlImg && (
        <MonInput
          label={
            <div className="flex flex-col justify-center items-center w-24 h-20 border-orange focus:border-vr border-dashed rounded-md border focus:border-1 focus:outline-none bg-grise gap-1">
              <FiUpload size={16} className="text-orange" />
              <span className="text-xs text-center">Ajouter une image</span>
            </div>
          }
          type={"file"}
          id={"image"}
          onChange={handleImageChange}
          placeholder={""}
          marginB={"!w-[6rem]"}
          paddingB={"h-20 flex items-center hidden"}
          labelStyle={"text-md ms-1 mb-3"}
          multiple
        />
      )}
    </>
  );
};

export default ImageContainerDisplay;
