import React from 'react'
import { formatDate, formatDuration } from '../Utils/FormatDate';

function TaskCard({titre, id, finished_at, width, budget, started_at}) {
  
  return (
    <div
      id={id}
      className={`flex flex-col justify-center shadow-lg rounded-lg ${width} max-lg:w-[35%] px-5 border py-2`}
    >
      <div className="flex flex-col gap-3 h-[80%] max-md:!leading-[5px]">
        <div className="text-base flex justify-between flex-wrap items-center w-full max-md:text-xs">
          <span className="font-semibold w-3/5 text-noirel">{titre}</span>
          <span className="text-orange text-sm font-semibold max-md:text-xs w-2/5 inline-flex justify-end">
            {budget ? `${budget} FCFA` : "Non défini"}
          </span>
        </div>

        <div className="text-sm w-full text-noirel max-md:text-xs">
          A livrer avant le:{" "}
          <span className="text-graymat font-mandali">
            {finished_at !== null ? formatDate(finished_at) : "Non défini"}
          </span>
        </div>
        <div className="text-end text-xs w-full flex justify-end pb-6">
          <p className="w-[60%] max-md:w-[60%]">
            Il y'a {started_at ? formatDuration(started_at) : "(Non défini)"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TaskCard