import React from "react";
import MonBouton from "../Utils/design-system/buttons/MonBouton";

const Newsletter = () => {
  return (
    <div className="bg-fondnewsletter bg-no-repeat bg-cover text-white w-[70%] absolute mx-auto flex justify-between py-6 px-4 max-lg:p-3 max-lg:rounded-sm -top-12">
      <div className="flex flex-col px-4 w-[45%] gap-3 max-lg:gap-2">
        <h3 className="text-base max-lg:text-[10px]">
          Abonnez-vous à notre newsletter
        </h3>
        <p className="text-xs font-mandali text-blc/60 max-lg:text-[8px]">
          Abonnez-vous à notre newsletter pour recevoir les dernières actualités
          de nos projets, et conseils directement par email.
        </p>
      </div>
      <div className="w-1/2 flex items-center justify-end">
        <div className=" bg-blc/20 rounded-full flex px-1 max-lg:px-1 max-lg:py-0 shadow items-center justify-between w-11/12">
          <input
            type="email"
            className="input border-none outline-none bg-transparent w-2/3 font-mandali text-xs max-lg:px-1 max-lg:text-[10px] max-lg:h-10"
            placeholder="Entrez votre adresse e-mail"
          />
          <div className="w-1/3">
            <MonBouton
              type="button"
              couleurFond="bg-orange !w-[30%] py-2 !w-full max-lg:text-[8px] max-lg:p-1 rounded-full font-inter justify-center text-white"
            >
              S'abonner
            </MonBouton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
