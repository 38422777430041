import React, { useRef } from 'react';
import { Editor } from 'primereact/editor';
import { MdSend } from 'react-icons/md';
import { PulseLoader } from 'react-spinners';
import { IoCloudUploadOutline } from "react-icons/io5";

function InputWysiwyg({ text, setText, onImageUpload, placeholder, label, height, width, minHeight, required, livrable, submit, isLoading, motif }) {
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    onImageUpload(files);
  };

  const customToolbar = (
    <span className="ql-formats flex items-center">
      <select className="ql-font" aria-label="font" size={5}></select>
      <select className="ql-size" aria-label="size" size={5}></select>
      <button className="ql-bold" aria-label="Bold" size={5}></button>
      <button className="ql-italic" aria-label="Italic" size={5}></button>
      <button className="ql-underline" aria-label="Underline" size={5}></button>
      <button className="ql-link" aria-label="Link" size={5}></button>
      <select className="ql-color" aria-label="color" size={5}></select>
      <select
        className="ql-background"
        aria-label="background"
        size={5}
      ></select>
      <button
        className="ql-list"
        value="ordered"
        aria-label="list"
        size={5}
      ></button>
      <button
        className="ql-list"
        value="bullet"
        aria-label="list"
        size={5}
      ></button>
      {livrable && (
        <span
          className="inline-flex items-center gap-2 rounded-full bg-orange text-white py-1 px-2 cursor-pointer ml-2"
          onClick={handleImageClick}
        >
          <IoCloudUploadOutline size={"12px"} className="" />
          <span className="text-[8px]">Ajouter une image</span>
        </span>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/*"
        multiple
      />
    </span>
  );

  return (
    <div className={`card py-4 ${width} relative text-noirel`}>
      {label && (
        <label className="text-xs font-medium px-2 inline-flex gap-1 mb-2 font-inter ">
          <span>{label}</span>
          {required && <span className="text-red">*</span>}
        </label>
      )}
      {livrable ? (
        <div>
          <Editor
            value={text}
            onTextChange={(e) => setText(e.htmlValue)}
            style={{ height: `${height}`, minHeight: `${minHeight}` }}
            className="w-full text-noir bg-vr/5"
            placeholder={placeholder}
            required={required}
            headerTemplate={customToolbar}
          />
          {isLoading ? (
            <PulseLoader
              size={"5px"}
              color="#00B38A"
              className="absolute bottom-7 right-2"
            />
          ) : (
            text?.length > 1 && (
                <button
                  onClick={submit}
                  className="text-white absolute right-2 bottom-6 bg-vr py-1 text-xs px-4 rounded-full hover:scale-[97%] transition-all duration-500"
                >
                  Envoyer
                </button>
            )
          )}
        </div>
      ) : motif ? (
        <div>
          <Editor
            value={text}
            onTextChange={(e) => setText(e.htmlValue)}
            style={{ height: `${height}`, minHeight: `${minHeight}` }}
            className="w-full text-noir"
            placeholder={placeholder}
            required={required}
            headerTemplate={customToolbar}
          />
          {text?.length > 1 && (
            <span
              className="text-xs cursor-pointer inline-flex bg-red py-1 px-2 rounded-full text-white items-center absolute bottom-7 right-2 gap-2"
              onClick={submit}
            >
              Confirmer le rejet
              {isLoading ? (
                <PulseLoader size={"5px"} color="white" className="" />
              ) : (
                <MdSend size={15} className="cursor-pointer text-white" />
              )}
            </span>
          )}
        </div>
      ) : (
        <Editor
          value={text}
          onTextChange={(e) => setText(e.htmlValue)}
          style={{ height: `${height}`, minHeight: `${minHeight}` }}
          className="w-full text-noir"
          placeholder={placeholder}
          required={required}
        />
      )}
    </div>
  );
}

export default InputWysiwyg;