import React from 'react'
import { Outlet } from 'react-router-dom';

function ProjectClientPage() {
  
  return (
    <div className='projects'>
      {/* <div className='px-6 py-2'>
        <p className='text-lg'>Liste de toutes les taches</p>
      </div> */}
      <Outlet />
    </div>
  )
}

export default ProjectClientPage