import React, { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { FiUploadCloud } from "react-icons/fi";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { selectData } from "../../../redux/features/userSliceWithTokenValidation";
import { ProjectsEndpoints } from "../../../Routes/api/Endpoints";
import { useAddProjectMutation } from "../../../services/auth-services/AuthService";
import MonBouton from "../../Utils/design-system/buttons/MonBouton";
import MonInput from "../../Utils/design-system/inputs/MonInput";
import InputWysiwyg from "../../Utils/InputWysiwyg";
import UploadImage from "../../Utils/UploadImage";
import defaultImg from "../../../assets/implement-2372179_640.jpg"
import {
  formClientSideProps,
  formLabelImage,
  formPropsLinkAndObj,
} from "../../Utils/UtilsForm";

function AjoutProjetClientSide() {
  const [text, setText] = useState("");
  const [errors, setErrors] = useState({});
  const [addProject, { isLoading: isLoadingProjectdetail }] =
    useAddProjectMutation();
  const formRef = useRef(null);
  const user = useSelector(selectData);
  console.log(user);

  const initialFormState = {
    titre: "",
    objectif: "",
    budget: "",
    user_id: user?.porteur_projet?.id,
    user_name: user?.name,
    user_email: user?.email,
    user_statut: user?.statut,
    document: null,
    image: null,
  };
  const [formState, setFormState] = useState(initialFormState);
  console.log(formState);


  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (files) {
      UploadImage(e, setFormState);
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [id]: files ? files[0] : value,
      }));
    }
  };

  const submitForm = (prospectFormData) => {
    addProject({
      endpoints: ProjectsEndpoints.postProjet,
      formData: prospectFormData,
    })
      .unwrap()
      .then((prospectData) => {
        formRef.current.reset();
        setText("");
        setFormState(initialFormState);
        toast.success(prospectData.message);
        setErrors({});
      })
      .catch((error) => {
        toast.error(error);
      });
  };

    const handleSubmit = (e) => {
      e.preventDefault();

      const requiredFields = ["titre", "objectif", "budget"];
      const newErrors = {};

      requiredFields.forEach((field) => {
        if (!formState[field]) {
          newErrors[field] = `Le ${field} est requis.`;
        }
      });

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }

      const prospectFormData = new FormData();
      Object.entries(formState).forEach(([key, value]) => {
        prospectFormData.append(key, value);
      });

      prospectFormData.append("description", text);

      if (formState.image) {
        prospectFormData.append("image", formState.image);
        submitForm(prospectFormData);
      } else {
        fetch(defaultImg)
          .then((res) => res.blob())
          .then((blob) => {
            const defaultFile = new File([blob], "defaultImage.jpg", {
              type: "image/jpeg",
            });
            prospectFormData.append("image", defaultFile);
            submitForm(prospectFormData);
          });
      }
    };

  return (
    <form
      ref={formRef}
      className="flex flex-wrap justify-between"
      onSubmit={handleSubmit}
    >
      {formClientSideProps && formClientSideProps.map((elem, i) => (
        <div className="w-full md:w-[48%] mb-3 md:mb-0" key={i}>
          <MonInput
            label={elem.label}
            type={elem.type}
            id={elem.id}
            key={elem.id}
            onChange={handleChange}
            placeholder={elem.placeholder}
            paddingB={elem.paddingB}
            labelStyle={elem.labelStyle}
            required={true}
            suffix={elem.suffix}
            padding={"py-3"}
          />

          {errors[elem.id] && (
            <div className="text-red-500">{errors[elem.id]}</div>
          )}
        </div>
      ))}
      <div className="flex flex-wrap justify-between w-full">
        <div className="md:w-[48%]">
          {formPropsLinkAndObj && formPropsLinkAndObj.map((elem, i) => (
            <div className="w-full mb-3 md:mb-0" key={i}>
              <MonInput
                label={elem.label}
                type={elem.type}
                id={elem.id}
                key={elem.id}
                onChange={handleChange}
                placeholder={elem.placeholder}
                paddingB={elem.paddingB}
                labelStyle={elem.labelStyle}
                required={true}
                padding={"py-3"}
              />
              {errors[elem.id] && (
                <div className="text-red-500">{errors[elem.id]}</div>
              )}
            </div>
          ))}
        </div>
        <div className="md:w-[49%] h-full">
          <div className="w-full h-[90%] mb-3 md:mb-0">
            <MonInput
              label={
                formState?.image !== null
                  ? formLabelImage(
                    <img
                      src={URL.createObjectURL(formState.image)}
                      alt="preview"
                      className="!w-full h-full rounded-md"
                    />
                  )
                  : formLabelImage(
                    <div className="flex flex-col items-center gap-3 w-[95%]">
                      <MonBouton
                        type={"button"}
                        couleurFond={"bg-orange text-white"}
                        bordure={"px-2 py-2 rounded-full font-inter text-sm justify-center gap-1 text-sm w-1/2"}
                        children={"Télécharger une image"}
                      />
                      <div className="w-full py-12 flex flex-col justify-center items-center border border-dashed rounded-md font-inter gap-3 border-grys/80">
                        <FiUploadCloud className="size-12 text-graytertiary" />
                        <span className="text-graytertiary">Glisser et déposer une image</span>
                      </div>
                    </div>
                  )
              }
              type={"file"}
              id={"image"}
              onChange={handleChange}
              placeholder={""}
              paddingB={"md:w-[95%] py-3 flex items-center hidden"}
              labelStyle={"text-md ms-1 mb-3 w-full"}
            />
            {errors["image"] && (
              <div className="text-red-500">{errors["image"]}</div>
            )}
          </div>
        </div>
      </div>
      <InputWysiwyg
        text={text}
        setText={setText}
        placeholder={"Exprimer votre besoin"}
        height={"150px"}
        width={"w-[98%]"}
        required={true}
      />
      <div className="flex justify-end w-[98%]">
        <MonBouton
          type={"submit"}
          couleurFond={"bg-orange text-white"}
          bordure={"px-2 py-2 rounded-lg justify-center gap-2 text-sm w-1/6"}
          iconRight={""}
          icone={""}
        >
          {isLoadingProjectdetail ? (
            <>
              Envoi <PulseLoader size={"5px"} color="#fff" />
            </>
          ) : (
            "Envoyer"
          )}
        </MonBouton>
      </div>
    </form>
  );
}

export default AjoutProjetClientSide;
