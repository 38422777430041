import React from 'react'
import { HubForgeHeader } from '../../components/composPageAccueil/HubForgeHeader'
import { FooterPageAcueil } from '../../components/composPageAccueil/FooterPageAcueil'
import Breadcrumbs from '../../components/Utils/Breadcrumbs'
// import TemplatePageComponent from '../../components/templatesPage/TemplatePageComponent'
import TemplateComponent from '../../components/composPageAccueil/TemplateComponent'

const TemplatesPage = () => {
  return (
    <div
      className={`min-h-[screen] h-full text-gray-600 bg-wt
        `}
    >
      <div className="w-full h-full">
        <div className="">
          <HubForgeHeader />
        </div>
        {/* <div className="flex justify-center pt-20 pb-6">
          <div className="w-[88%]">
            <Breadcrumbs home={true} />
          </div>
        </div> */}
        <div className="flex justify-center pt-20">
          <TemplateComponent display={"hidden"} />
        </div>
        <div>
          <FooterPageAcueil />
        </div>
      </div>
    </div>
  );
}

export default TemplatesPage
