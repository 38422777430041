import React from "react";
import { FooterPageAcueil } from "./FooterPageAcueil";
import DetailProjectContent from "./DetailProjectContent";
import { HubForgeHeader } from "./HubForgeHeader";

function DetailsProject() {
  return (
    <div
      className={`min-h-[screen] h-full bg-fond bg-cover bg-no-repeat bg-center text-gray-600`}
    >
      <div className="w-full">
        <div>
          <HubForgeHeader />
        </div>
      </div>
      <div className="">
        <DetailProjectContent />
      </div>
      <div>
        <FooterPageAcueil />
      </div>
    </div>
  );
}

export default DetailsProject;
