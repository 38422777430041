
import React, { useState } from "react";
import { useSelectData } from "../../hook/useSelectData";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Editor } from "primereact/editor";
import { useAppContext } from "../../context/AppContext";
import MonInput from "../Utils/design-system/inputs/MonInput";

export default function WysiwygForm({
  formKey,
  onDelete,
  handleInputChange,
  handleEditorChange,
  handleTypeCodeChange,
}) {
  const { apiUrlCat } = useAppContext();
  const { typesCodes } = useSelectData();
  const [text, setText] = useState("");
  const handleDeleteClick = () => {
    onDelete(formKey);
  };

  return (
    <>
      <div className="flex flex-col gap-3 mt-3 py-2 px-6 rounded-tl-xl rounded-tr-xl border border-gray-200">
        <div className="flex items-center gap-3">
          <div className="grid flex-1 gap-y-1 justify-end">
            <p className={`overflow-hidden break-words text-sm  text-bl`}>
              <RiDeleteBin6Line
                className="text-2xl text-red cursor-pointer"
                onClick={handleDeleteClick}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 pb-3 border border-gray-200 rounded-bl-xl rounded-br-xl  flex flex-col">
        {/* Input type de code*/}
        <div className="w-full  mb-3">
          <MonInput
            label="Type de code*"
            type="select"
            id={`type_code_${formKey}`}
            name="type_code"
            onChange={(e) => handleTypeCodeChange(formKey, e)}
            paddingB="h-11 md:w-[100%] bg-white mt-2 mb-2 pb-0 border-grys/40"
            labelStyle="text-md ms-1"
            placeholder={"Nouveau Type de Code"}
            options={[
              {
                label: "Liste des types de codes",
              },
              ...typesCodes.map((code) => ({
                value: code.type_de_code,
                label: code.type_de_code,
              })),
            ]}
            endPoint={`${apiUrlCat}/addTypesCode`}
            jsonKey="type_de_code"
          />
        </div>
        <div className="card">
          <Editor
            value={text}
            onTextChange={(e) => {
              setText(e.htmlValue);
              handleEditorChange(e.htmlValue);
            }}
            style={{ height: "100px", backgroundColor:"white"}}
          />
        </div>
        <div className="w-full">
          {/* Input lien de  téléchargement template*/}
          <MonInput
            label="Téléchargement*"
            type="url"
            id="telechargement"
            position={"flex-col"}
            name="telechargement"
            onChange={(e) => handleInputChange(e)}
            placeholder="Lien de téléchargement du type de code (Zip)"
            paddingB="h-11 rounded-lg bg-white border-grys/40"
            labelStyle="text-lg ms-1 text-gray-800 w-full mb-1"
          />
        </div>
      </div>
    </>
  );
}
