import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { Carousel } from 'primereact/carousel';
import ConfirmAction from './ConfirmAction';

const CarouselModal = ({ onClose, images, modalCarousel, modalConfirm, validation, title, actionText, text, setText, action, actionConfirm, loader, actionAnnuler, postuled }) => {
    const [isVisible, setIsVisible] = useState(true);
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            setIsVisible(false);
            setTimeout(() => onClose(), 300);
        }
    };
    const imageTemplate = (image) => {
        return (
            <img src={image.itemImageSrc} alt={image.alt} className='w-[100%] h-[500px] overflow-y-hidden' />
        );
    };

    return (
        <div 
            className={`fixed bg-noir/50 h-full mt-0 justify-center ${modalConfirm && "items-center bg-opacity-80"} ${modalCarousel && "bg-opacity-70 duration-700"} border-none inset-0 flex lg:px-4 bg-opacity-50 z-[5000] transition-opacity duration-700 ${isVisible ? 'opacity-100' : 'opacity-0'}`} 
            onClick={handleOverlayClick}
        >
            <div className={`rounded-lg content ${modalConfirm && "bg-white !w-[35%] h-max"} w-[70%] h-[95%] max-lg:overflow-y-auto overflow-hidden transition-opacity duration-700`}>
                <div className={`flex justify-end px-3 ${modalCarousel && "pt-0"} pt-3`}>
                    <IoClose 
                        className='text-xl cursor-pointer text-white'
                        onClick={() => {
                            setIsVisible(false);
                            setTimeout(() => onClose(), 300);
                        }}
                    />
                </div>
                {modalCarousel && <Carousel 
                    value={images && images} 
                    numVisible={1} 
                    numScroll={1} 
                    className="custom-carousel text-white"
                    circular
                    color='white'
                    itemTemplate={imageTemplate}
                />}
                {modalConfirm && <ConfirmAction actionText={actionText} actionAnnuler={actionAnnuler} title={title} postuled={postuled} validation={validation} text={text} setText={setText} actionConfirm={actionConfirm} loader={loader} />}
            </div>
        </div>
    );
};

export default CarouselModal;