import React from "react";
import { BiLogoGithub } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";
import MonBouton from "./design-system/buttons/MonBouton";
import { PropagateLoader } from "react-spinners";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";

export const Entete = ({ formError }) => {
  return (
    <>
      {formError && (
        <div className="w-full mb-5 bg-rose-100 border-2 border-rg h-25 py-2 px-3 justify-center flex rounded-xl">
          <small className="text-rg flex items-center italic text-center font-bold text-[13px] max-sm:text-sm text-wrap">
            <FiAlertTriangle className="me-2 text-xl" />
            <p>{formError}</p>
          </small>
        </div>
      )}
      {/* <a className={`flex items-center justify-center`} href="/">
        <BiLoaderCircle className={`text-[43px] text-bl me-3`} />
        <p className="text-3xl text-bl font-[600]">Bakeli Work</p>
      </a>
      <div className="text-mr text-center mt-6 text-opacity-90 text-lg">
        {ques}-vous en tant que Worker
      </div> */}
    </>
  );
};

export const Bouton = ({ emailError, isLoading, action, maskBtn, text }) => {
  return (
    <>
      {isLoading ? (
        <div className=" mt-6 mb-4 flex w-full align-items-center justify-center">
          <PropagateLoader color="#072967" size={"18px"} />
        </div>
      ) : (
        <MonBouton
          textColor={`mt-4 hover:scale-[97%] transition-all duration-500 w-full rounded-xl py-3 text-xl ${
            emailError !== "" || isLoading ? "hidden" : ""
          }`}
          couleurFond="bg-bl text-blc"
          action={action}
          id={"connecter"}
          disabled={maskBtn}
        >
          {text}
        </MonBouton>
      )}
    </>
  );
};

export const Comptes = ({ gitHubAction, googleAction, client_id }) => {
  return (
    <div className="w-full mt-4">
      <div className="flex items-center mb-4 w-full gap-2">
        <div className="h-[2px] w-[47%] bg-vr"></div>
        <p className="text-mr">Ou</p>
        <div className="h-[2px] w-[47%] bg-vr"></div>
      </div>
      <div className="flex gap-2 flex-wrap justify-center ">
        <button
          className=" px-2 flex items-center  justify-center hover:bg-gray-50  py-[5px] bg-white rounded-full  m-auto"
          onClick={gitHubAction}
        >
          <BiLogoGithub className="text-3xl text-bl me-2 " />
          <p className="text-sm font-500">Continuer avec GitHub</p>
        </button>
        <GoogleOAuthProvider clientId={client_id}>
          <GoogleLogin
            onSuccess={googleAction}
            text="continue_with"
            shape="circle"
            useOneTap={false}
          />
        </GoogleOAuthProvider>
      </div>
    </div>
  );
};
