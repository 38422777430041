import React, { useEffect, useRef, useState } from 'react'
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import MonBouton from '../../Utils/design-system/buttons/MonBouton';
import { CiTrash } from 'react-icons/ci';
import { Dropdown } from "primereact/dropdown";

const NotificationOptions = ({ read, handleDelete, notifId }) => {
  const dropdownRef = useRef(null);

  const options = [
    {
      option: (
        <li
          className="!px-0 w-full"
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(notifId);
            if (dropdownRef.current) {
              dropdownRef.current.hide();
            }
          }}
        >
          <MonBouton
            type={"button"}
            children={"Supprimer"}
            bordure={`w-full rounded-none text-red items-center cursor-pointer text-[11px] font-inter font-medium text-center justify-center`}
            icone={<CiTrash className="text-red" size={16} />}
          />
        </li>
      ),
    },
  ];

  const placeholder = (
    <span
      id="dropdownDefaultButton"
      data-dropdown-toggle="dropdown"
      className=" py-1 inline-flex justify-center cursor-pointer"
      role="button"
    >
      <HiOutlineDotsHorizontal size={16} className="text-noir_300" />
    </span>
  );

  return (
    <div
      className="relative flex flex-col items-end gap-2"
      onClick={(e) => e.stopPropagation()}
    >
      <Dropdown
        ref={dropdownRef}
        options={options}
        optionLabel="option"
        showClear
        placeholder={placeholder}
        showIcon={false}
        clearIcon
        hideOnClickOutside={false}
        className="w-7 outline-none shadow-none ms-auto p-0 bg-transparent"
      />
      {!read && (
        <span className="p-1 bg-blue-500 rounded-full my-auto me-1"></span>
      )}
    </div>
  );
};

export default NotificationOptions;
