import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectToken } from "../../redux/features/userSliceWithTokenValidation";
import { apiTel} from "../../Routes/api/global_vars";

export const API_DOWNLOAD = createApi({
    reducerPath: "downdloadApi",
    baseQuery: fetchBaseQuery({
      baseUrl: apiTel,
      prepareHeaders: (headers, { getState }) => {
        const token = selectToken(getState());
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        // headers.set("Content-Type", "application/json");
        return headers;
      },
    }),
    endpoints: (builder) => ({
        download: builder.mutation({
            query: (data) => ({
              method: "POST",
              body: data,
              headers: { 
                'Content-Type': 'application/json',
              }
            }),
        }),
    })
  });
  
  export const {
    useDownloadMutation
  } = API_DOWNLOAD;
  
  export default API_DOWNLOAD