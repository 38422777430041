import React from "react";

const TestimonialItem = ({ name, text }) => {
  return (
    <div className="testimonial-item">
      <p className="font-[500] text-dsn mb-6 text-base leading-7 max-xl:text-[16px] max-w-lg max-lg:max-w-full ">
        {text}
      </p>
      <div className="flex mb-6 items-center">
        <p className="font-semibold italic text-dsn">-{name}</p>
      </div>
    </div>
  );
};

export default TestimonialItem;
