import React, { Component } from 'react'; 
import Frame1 from '../../assets/Frame1.png';
import Frame2 from '../../assets/Frame2.png';
import Frame3 from '../../assets/Frame3.png';
import Frame4 from '../../assets/Frame4.png';

const projectData = [
  { src: Frame1, alt: 'JEEMA CODER', title: 'JEEMA CODER', price: '2.000.00 FCFA', description: 'Créer une application CRM de gestion d’hôtel.' },
  { src: Frame2, alt: 'Safmo forms', title: 'Safmo forms', price: '0.00 FCFA', description: 'Créer une application CRM de gestion d’hôtel.' },
  { src: Frame3, alt: 'Tayeur Gestion', title: 'Tayeur Gestion', price: '0.00 FCFA', description: 'Créer une application CRM de gestion d’hôtel.' },
  { src: Frame4, alt: 'Safmo Solution', title: 'Safmo Solution', price: '0.00 FCFA', description: 'Créer une application CRM de gestion d’hôtel.' }
];

export class ProjectCards extends Component {
  render() {
    return (
      <div className='p-3 'style={{position:'relative',bottom:'120px'}}>
        <div className="flex justify-between w-[90%] gap-8 lg:mx-16">
          {projectData.map((project, index) => (
            <div key={index} className="flex flex-col items-center">
              {/* Réduire la taille de l'image */}
              <img src={project.src} alt={project.alt} className="w-90 h-auto mb-4" /> 
              <h2 className="font-inter text-[20px] font-semibold leading-[31.46px] text-left text-[#191A15] mb-2">
                {project.title} <span className="font-inter text-[12px] font-medium leading-[31.46px] text-right text-[#FF9800] ml-5">{project.price}</span>
              </h2>
              <p className="font-mandali text-[12px] mt-[-15px] font-normal leading-[31.46px] text-left text-[#A6A6A6]">
                {project.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ProjectCards;