import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { BiLoaderCircle } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import illustration1 from "../assets/Group 32.png";
import illustration2 from "../assets/Working-pana 1.png";
import { Entete } from "../components/Utils/Reutilisable";
import MonBouton from "../components/Utils/design-system/buttons/MonBouton";
import MonInput from "../components/Utils/design-system/inputs/MonInput";
import { useAppContext } from "../context/AppContext";
import { AuthentificationEndpoints } from "../Routes/api/Endpoints";

function ResetPassword() {
  const { token, email } = useParams();
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { apiUrlCat } = useAppContext();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== password_confirmation) {
      setErrorMessage("Les mots de passe ne correspondent pas.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${apiUrlCat}/${AuthentificationEndpoints.updatePassword}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token,
            email,
            password,
            password_confirmation,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage(data.message);
        toast.success(data.message);
        navigate("/connexion", { replace: true });
        setPassword("");
        setPassword_confirmation("");
      } else {
        if (data.message) {
          if (typeof data.message === "object") {
            setErrorMessage(
              JSON.stringify(data.message).replace(/[\[\]{}()"]/g, "")
            );
          } else {
            setErrorMessage(data.message);
          }
        } else {
          setErrorMessage("Une erreur s'est produite");
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
    }

    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-gray-100 transition-all duration-500 flex items-center justify-center">
      <div className="flex justify-center max-sm:hidden max-md:hidden">
        <img
          src={illustration1}
          className="h-[80%] w-[80%] max-sm:hidden max-md:hidden"
          alt="img"
        />
      </div>

      <div className=" max-lg:bg-white fixed inset-0"></div>
      <div className=" w-4/12 max-md:w-[90%]  max-sm:w-[90%] max-lg:w-[80%] max-xl:w-[70%] py-6  border-2 max-lg:mx-auto z-10 bg-white rounded-xl shadow-lg">
        <div className=" relative p-4 sm:p-7 ">
          {errorMessage && (
            <div className="">
              <Entete formError={errorMessage} />
            </div>
          )}
          <div className="text-center ">
            <a
              className={`flex items-center mb-5 -ms-3 justify-center`}
              href="/"
            >
              <BiLoaderCircle className={`text-[43px] text-orange me-3`} />
              <p className="text-3xl text-vr font-[600]">Bakeli Work</p>
            </a>
            <h1 className="block text-xl font-bold text-orange">
              Mot de passe oublié ?
            </h1>
            <p className="mt-2 text-md text-mr">
              Réinitialisation du mot de passe
            </p>
          </div>

          <div className="mt-3">
            <form onSubmit={handleSubmit}>
              <div className="grid gap-y-2">
                <div>
                  <MonInput
                    type="password"
                    id="password"
                    value={password}
                    placeholder="Nouveau mot de passe"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrorMessage("");
                    }}
                    padding={"py-3"}
                    paddingB="w-full bg-gray font-bold ps-4 rounded-lg outline-none border-none py-3"
                  />
                </div>
                <div>
                  <MonInput
                    type="password"
                    id="password_confirmation"
                    paddingB="w-full bg-gray font-bold ps-4 rounded-lg outline-none border-none py-3"
                    value={password_confirmation}
                    placeholder="Confirmer le mot de passe"
                    onChange={(e) => {
                      setErrorMessage("");
                      setPassword_confirmation(e.target.value);
                    }}
                    padding={"py-3"}
                  />
                </div>
                <MonBouton
                  textColor={` mt-4 hover:scale-[97%] transition-all duration-500 w-full rounded-full py-2 text-lg ${
                    loading ? "hidden" : ""
                  }`}
                  couleurFond="px-2 md:w-14rem border outline-none bg-orange text-white text-md rounded-lg text-center py-1"
                  action={handleSubmit}
                  id={"submit"}
                  disabled={loading}
                  alignment={"justify-center"}
                >
                  Réinitialiser
                </MonBouton>
                <div
                  className={`${
                    loading ? "" : "hidden"
                  } mt-6 pb-3 w-full flex justify-center`}
                >
                  <PropagateLoader color="#009688" />
                </div>
              </div>
            </form>
            <div className="flex justify-end">
              <MonBouton
                textColor={` ${
                  errorMessage ===
                  "Demande de modification du Mot de Passe expirée !"
                    ? "flex"
                    : "hidden"
                } mt-2 hover:scale-[97%]  transition-all duration-500 w-[110px] rounded-full py-2 text-sm ${
                  loading ? "hidden" : ""
                }`}
                couleurFond="bg-bl text-blc"
                action={() => navigate("/connexion", { replace: true })}
                id={"submit"}
                disabled={loading}
                alignment={"justify-center"}
              >
                Retour
              </MonBouton>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center max-sm:hidden max-md:hidden">
        <img src={illustration2} className="h-[80%] w-[80%]" alt="img" />
      </div>
    </div>
  );
}

export default ResetPassword;
