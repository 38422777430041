import React from 'react';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoTimeOutline } from 'react-icons/io5';
import { formatDateExcepHours } from '../../Utils/FormatDate';

const ClientInfos = ({clientName, date}) => {
  return (
    <div className="flex flex-col gap-6 w-11/12">
      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-inter font-bold text-noir">
          À propos du client
        </h1>
        <div className="text-sm flex gap-3 items-center ps-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 36 36"><path fill="#00853f" d="M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5z"></path><path fill="#fdef42" d="M12 5h12v26H12z"></path><path fill="#e31b23" d="M32 5h-8v26h8a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4"></path><path fill="#00853f" d="M18.869 16.674L18 14l-.869 2.674H14.32l2.274 1.652L15.726 21L18 19.348L20.274 21l-.868-2.674l2.274-1.652z"></path></svg>
          <span>{clientName}</span>
        </div>
        {/* <div className="flex gap-3 items-center text-sm">
          <span className="text-noirel">
            <MdOutlinePersonOutline size={26} />
          </span>
          <span className="flex">
            <IoIosStar className="text-orange" size={26} />
            <IoIosStar className="text-orange" size={26} />
            <IoIosStar className="text-orange" size={26} />
            <IoIosStar className="text-orange" size={26} />
            <FaRegStarHalfStroke className="text-orange" size={26} />
          </span>
          <span>4.6</span>
          <span className="flex gap-1 items-center">
            <RiMessage2Fill className="stroke-current" stroke="1" />
            <p>21</p>
          </span>
        </div> */}
        <div className="flex gap-3 items-center font-inter text-noirel text-sm">
          <span>
            <IoIosCheckmarkCircleOutline
              size={23}
              className="stroke-current
            stroke-1"
            />
          </span>
          <span>Mode de paiement vérifié</span>
        </div>
        <div className="flex gap-3 items-center font-inter text-noirel text-sm">
          <span>
            <IoTimeOutline size={23} />
          </span>
          <span>{`Membre depuis le ${formatDateExcepHours(date)}`}</span>
        </div>
      </div>
      {/* <div className="flex flex-col gap-3">
        <h1 className="text-xl font-inter font-bold text-noir">
          Vérification du client
        </h1>
        <div className="flex gap-10 ps-4 text-vrt">
          <FaMoneyBills size={24} strokeOpacity={0.1} />
          <MdOutlineMail size={24} />
          <MdOutlinePersonOutline size={24} />
          <MdOutlineLocalPhone size={24} />
          <CiMoneyCheck1 size={30} />
        </div>
      </div> */}
    </div>
  );
}

export default ClientInfos
