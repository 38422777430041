import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";
import RenderWysiwyg from "render-wysiwyg";

export const CardesTemplate = ({
  HandlePreview,
  imageTemplate,
  fonCardTemplate,
  titreCrdTemplate,
  DesctiptionTemplate,
  prixTemplate,
  telechargeLink,
  userName,
  userProfile,
}) => {
  const [ishover, setIsHover] = useState(false);

  const hoverMethode = () => {
    setIsHover(true);
  };
  const hoveredMethode = () => {
    setIsHover(false);
  };
  return (
    <div
      className="relative pb-5 bg-white shadow rounded-xl max-lg:mb-4"
      onMouseEnter={hoverMethode}
      onMouseLeave={hoveredMethode}
    >
      <div
        className={`CardConta rounded-t-xl max-sm:p-7
        mx-auto flex justify-center ${fonCardTemplate} items-center border-b border-grayborder`}
        style={{ backgroundColor: `${fonCardTemplate}` }}
      >
        <img
          src={imageTemplate}
          alt="template1"
          className={`rounded-t-lg ImgTemp w-full object-fil xl:h-[250px] lg:h-[230px] md:h-[270px]
              sm:h-[310px] xs:h-[310px] z-0`}
        />
      </div>
      <div className="flex justify-between w-full px-5 pt-6 pb-4">
        <div className="max-sm:pe-4 text-bluerel">
          <p
            className={` font-semibold text-base font-inter max-sm:text-sm pb-2 text-gray-600"`}
          >
            {titreCrdTemplate}
          </p>
          <div className="max-sm:text-sm text-sm text-graymat">
            {<RenderWysiwyg content={DesctiptionTemplate} />}
          </div>
        </div>
        <div className="flex flex-col gap-2 items-end">
          <p className={`text-md text-end font-[300] text-vrt font-inter`}>
            {prixTemplate}
          </p>
          <div className="flex items-center gap-5">
            <Link to={HandlePreview} target="_blank">
              <FaArrowUpRightFromSquare className="text-[15px] text-blck hover:text-orange" />
            </Link>

            <Link to={telechargeLink} target="_blank">
              <MdOutlineFileDownload className="text-[23px] text-blck hover:text-orange" />
            </Link>
          </div>
        </div>

      </div>
        <div
          className={`flex absolute bottom-0 left-0 bg-grey/20 items-center gap-2 px-3 pb-2 text-white`}
        >
          <img
            src={userProfile}
            alt="Profile "
            className="rounded-full size-6"
          />
          <p className="text-xs text-orange border-b border-vr pb-1">{userName}</p>
        </div>
    </div>
  );
};
