import React, { useState } from 'react';
import { formatDateComplete } from '../../../Utils/FormatDate';
import { IoIosArrowDown } from "react-icons/io";
import { useAppContext } from '../../../../context/AppContext';
import { useModal } from '../../../../context/ModalContext';
import RenderWysiwyg from 'render-wysiwyg';
import { ImPlus } from "react-icons/im";
import MonBouton from '../../../Utils/design-system/buttons/MonBouton';
import { FaCheckCircle } from "react-icons/fa";
import { ProjectsEndpoints } from '../../../../Routes/api/Endpoints';
import toast from 'react-hot-toast';
import { PulseLoader } from 'react-spinners';
import { TiDelete } from "react-icons/ti";
import { BsPatchCheckFill, BsThreeDots } from 'react-icons/bs';
import CommentLivraison from '../commentairesComponent/CommentLivraison'
import InputWysiwyg from '../../../Utils/InputWysiwyg';
import { selectData } from '../../../../redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';
import { useDeleteDataMutation, usePostDataMutation, usePutTaskMutation } from '../../../../services/auth-services/AuthService';
import { Tooltip } from 'react-tooltip';
import CommentOptions from '../commentairesComponent/CommentOptions';
import ImageContainerDisplay from './ImageContainerDisplay';

const LivraisonsDetails = ({ livraisons, profileLetters, name, isLoading, refecthData}) => {
  const { apiUrlImg } = useAppContext();
  const [text, setText] = useState("");
  const [openInput, setOpenInput] = useState(false);
  const [putTask, { isLoading: isLoadingInvalidatedDelivery }] = usePutTaskMutation();
  const [deleteDelivary, { isLoading: isLoadingDeleteDelivary }] = useDeleteDataMutation();
  const [postData, { isLoading: isLoadingRejetDelivery }] = usePostDataMutation();
  const [editingDeliveryId, setEditingDeliveryId] = useState(null);
  const [editedText, setEditedText] = useState("");
  const { openModalLivraison } = useModal();
  const [captures, setCaptures] = useState([]);
  const [selectedLivraisonId, setSelectedLivraisonId] = useState(null);
  const user = useSelector(selectData)
  const handleOpenModal = (images) => {
    openModalLivraison(images);    
  };

  const handleAddImage = (newImages) => {
    setCaptures((prevCaptures) => [...prevCaptures, ...newImages]);
  };

  const handleEditClick = (e, deliveryId, currentText) => {
    e.stopPropagation();
    setEditingDeliveryId(deliveryId);
    setEditedText(currentText);
  };  

  const handleInvalidatedDelivary = (delivery) => {
    const formData = new FormData();
    if (editingDeliveryId) {
      formData.append("description", editedText);
      captures.forEach((capture, index) => {
        formData.append(`captures[${index}]`, capture);
      });
    }
    formData.append('motif', text)
    postData({
      endpoint: editingDeliveryId
        ? ProjectsEndpoints.updateDelivery
        : ProjectsEndpoints.rejeterLivraison,
      elementID: delivery?.id,
      formData,
    })
      .unwrap()
      .then((data) => {
        toast.success(data.message);
        refecthData();
        setText("");
        setEditingDeliveryId(null);
        setSelectedLivraisonId(null);
      })
      .catch((error) => {
        console.log(error);
        
      });
  }

  const handleThreeDotsClick = (e) => {
    e.stopPropagation();
  };

  const handleValidatedDelivary =  (delivery) => {
    
    const updatedDelivery = {
      id: delivery?.id,
    };
    console.log(updatedDelivery);
    
    putTask({ endpoint: ProjectsEndpoints.validerLivraison, task: updatedDelivery })
      .unwrap()
      .then((data) => {
        toast.success(data.message);
        refecthData()
      })
      .catch((error) => {
        console.log(error);
        
        
        // toast.error(error?.data.message);
      });
  }

  const hadleDeleteDelivary = (deliveryId) => { 
    deleteDelivary({ endpoint: ProjectsEndpoints.deleteDelivary, dataId: deliveryId })
      .unwrap()
      .then(() => {
        refecthData();
      })
      .catch((error) => {
        console.error('Error deleting comment:', error);
      });
  }

  const toggleDetailDelivery = (id) => {
    setSelectedLivraisonId(selectedLivraisonId === id ? null : id);
  };

  if (isLoading) {
    return <div className='py-12 text-center'>Chargement en cours...</div>;
  }

  if (!livraisons || livraisons.length === 0) {
    return <div className='py-12 text-center'>Aucune livraison disponible.</div>;
  }
  
  return (
    <div className='w-full py-6 flex flex-col gap-3'>
      {livraisons.map((livraison) => {
        const firstLetters = profileLetters(livraison.bakeliste?.name || '');
        const images = (livraison.images || []).map(image => ({
          itemImageSrc: `${apiUrlImg}/${image.captures}`,
          alt: 'Image',
        }));
        
        const isDetailVisible = selectedLivraisonId === livraison.id;
        return (
          <>
            <div key={livraison?.id} className="bg-white rounded-lg pb-2">
              <div
                key={livraison.id}
                className={`${isDetailVisible ? "pb-2 px-3 rounded-md" : ""}`}
              >
                <div
                  className={`flex justify-between text-noir_300 py-2 px-2 rounded duration-700 transition-all cursor-pointer group`}
                  onClick={() => toggleDetailDelivery(livraison.id)}
                >
                  <div className="flex gap-2 items-center">
                    {livraison?.bakeliste?.profile ? (
                      <span>
                        <img
                          src={`${apiUrlImg}/${livraison?.bakeliste?.profile}`}
                          className="w-8 h-8 rounded-full object-cover"
                        />
                      </span>
                    ) : (
                      <span className="h-[40px] w-[40px] profile cursor-pointer text-center items-center text-sm flex justify-center rounded-2xl border bg-bl text-blanc">
                        {firstLetters}
                      </span>
                    )}
                    <div className="text-xs leading-5">
                      <div className="flex items-center cursor-pointer gap-2 hover:underline hover:decoration-[0.5px]">
                        <span className="font-inter text-[13px] font-semibold text-orange">
                          {name}
                        </span>
                        <span className="text-xs font-inter">a livré</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-[10px] font-inter font-semibold">
                          {formatDateComplete(livraison.created_at)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2 text-xs items-start w-1/4 justify-end">
                    {livraison.validation === "VALIDE" && (
                      <span
                        className="inline-flex items-center gap-1 bg-vr text-white text-[10px] py-1 px-2 rounded-badge cursor-text tooltip"
                        data-tip="Livrable validé"
                      >
                        <BsPatchCheckFill size={10} className="text-white" />{" "}
                        Validé
                      </span>
                    )}
                    {livraison.validation === "REJETE" && (
                      <span
                        className="inline-flex items-center gap-1 bg-red text-white text-[10px] py-1 px-2 rounded-badge cursor-text tooltip"
                        data-tip="Livrable rejeté"
                      >
                        <TiDelete size={10} className="text-white" /> Rejetée
                      </span>
                    )}
                    {isDetailVisible &&
                      user?.id === livraison?.bakeliste_id && (
                        <BsThreeDots
                          size={20}
                          className={`text-blck options-${livraison?.id}`}
                          onClick={handleThreeDotsClick}
                        />
                      )}
                    <Tooltip
                      anchorSelect={`.options-${livraison?.id}`}
                      content={
                        <CommentOptions
                          commentId={livraison?.id}
                          handleEdit={(e) =>
                            handleEditClick(
                              e,
                              livraison.id,
                              livraison.description
                            )
                          }
                          commentText={livraison?.description}
                          handleDelete={hadleDeleteDelivary}
                          isLoadingDeleteComment={isLoadingDeleteDelivary}
                          user={user}
                          reply={true}
                          background={"bg-white text-blck"}
                          elem={livraison?.bakeliste_id}
                        />
                      }
                      place="right"
                      openOnClick
                      className="border text-blk"
                      style={{
                        backgroundColor: "white",
                        color: "#fff",
                        pointerEvents: "auto",
                        zIndex: 1000,
                        borderRadius: "8px",
                      }}
                    />
                    {!isDetailVisible && (
                      <IoIosArrowDown
                        className="opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                        size={16}
                      />
                    )}
                  </div>
                </div>
                {isDetailVisible && (
                  <div className="flex flex-col gap-4 font-inter text-[13px] font-light">
                    <div className="px-3">
                      <div className="">
                        {editingDeliveryId === livraison?.id ? (
                          <div>
                            <InputWysiwyg
                              text={editedText}
                              setText={setEditedText}
                              height="50px"
                            />
                          </div>
                        ) : (
                          <RenderWysiwyg
                            content={livraison.description || ""}
                          />
                        )}
                      </div>
                    </div>
                    {livraison?.images.length > 1 && <div
                      className={`flex border w-[95%] mx-3 border-gryborder/60 rounded-xl ${editingDeliveryId &&
                        "!w-full h-[7rem] !rounded-none !border-none gap-1"
                        } h-64 ${livraison.images.length <= 1 && "!border-none"
                        } `}
                    >
                      {editingDeliveryId ? (
                        <ImageContainerDisplay
                          images={livraison?.images}
                          widthFixed={"7rem"}
                          width={"7rem"}
                          height={"5rem"}
                          style={"gap-1"}
                          onAddImage={handleAddImage}
                          onRemoveImage={hadleDeleteDelivary}
                          apiUrlImg={apiUrlImg}
                        />
                      ) : (
                        <>
                          <div
                            className={`${livraison.images.length <= 1 ? "w-full" : "w-[45%]"} h-full cursor-pointer`}
                            onClick={() => handleOpenModal(images)}
                          >
                            <img
                              src={`${apiUrlImg}/${livraison.images?.[0]?.captures || ""
                                }`}
                              alt="img-0"
                              className={`rounded-l-md h-full object-cover w-full ${livraison.images.length === 1 && "rounded-md"
                                }`}
                            />
                          </div>
                          <div className="w-[55%] h-full flex flex-col">
                            {(livraison.images || [])
                              .slice(1, 2)
                              .map((image, index) => (
                                <div
                                  key={index}
                                  className={`h-1/2 cursor-pointer ${livraison.images.length < 3 ? "h-full" : ""
                                    }`}
                                  onClick={() => handleOpenModal(images)}
                                >
                                  <img
                                    src={`${apiUrlImg}/${image.captures || ""}`}
                                    alt={`img-${index + 1}`}
                                    className={`rounded-${index === 0 ? "tr-md" : "br-md"
                                      } h-full object-cover w-full`}
                                  />
                                </div>
                              ))}
                            {livraison.images.length > 3 && (
                              <div
                                className="h-1/2 relative flex justify-center cursor-pointer"
                                onClick={() => handleOpenModal(images)}
                              >
                                <img
                                  src={`${apiUrlImg}/${livraison.images?.[2]?.captures || ""
                                    }`}
                                  alt="img"
                                  className="rounded-br-md h-full object-cover w-full"
                                />
                                <div className="bg-gray-700 rounded-br-md flex w-full h-full opacity-70 absolute"></div>
                                <div className="absolute inset-0 flex justify-center gap-1 items-center">
                                  <span className="bg-yellow-100 rounded-full text-xl h-12 w-12 font-inter font-extrabold flex justify-center items-center text-orange">
                                    <ImPlus
                                      // size={"10px"}
                                      className="text-orange cursor-pointer size-3"
                                    />{" "}
                                    {livraison.images.length - 3}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>}
                    {user?.statut === "coach" &&
                    livraison.validation === "EN_ATTENTE" ? (
                      <div className="flex gap-2 justify-end font-inter font-semibold text-sm w-[97%]">
                        <MonBouton
                          children={"Rejeter"}
                          couleurFond={"bg-red text-white"}
                          bordure={` flex w-[30%] max-lg:w-2/4 justify-center border outline-none bg-gray-200 text-blk font-inter rounded-full py-1`}
                          action={() => setOpenInput(!openInput)}
                          type={"button"}
                        />
                        <MonBouton
                          children={"Valider"}
                          couleurFond={"bg-orange text-white"}
                          bordure={` flex w-[30%] max-lg:w-2/4 justify-center border outline-none bg-gray-200 text-blk font-inter rounded-full py-1`}
                          action={() => handleValidatedDelivary(livraison)}
                          type={"button"}
                          iconRight={
                            isLoadingInvalidatedDelivery && (
                              <PulseLoader size={5}  color='white' />
                            )
                          }
                        />
                      </div>
                    ) : (
                      <div
                        className={`flex gap-2 ${
                          editingDeliveryId === livraison?.id
                            ? "block"
                            : "hidden"
                        }`}
                      >
                        <MonBouton
                          children={"Enrégister"}
                          couleurFond={"bg-vr text-white"}
                          bordure={`px-2 flex max-w-max justify-start border outline-none bg-blue-400 text-blk font-inter text-xs rounded py-1`}
                          action={() => handleInvalidatedDelivary(livraison)}
                          type={"button"}
                          iconRight={
                            isLoadingRejetDelivery && (
                              <PulseLoader size={3} color="#fff" />
                            )
                          }
                        />
                        <MonBouton
                          children={"Annuler"}
                          couleurFond={"bg-grys/60"}
                          bordure={`px-2 flex max-w-max justify-start border outline-none bg-gray-200 text-blk font-inter text-xs rounded py-1 `}
                          action={() => setEditingDeliveryId(null)}
                          type={"button"}
                        />
                      </div>
                    )}
                    {openInput && (
                      <InputWysiwyg
                        text={text}
                        setText={setText}
                        placeholder="Motif de rejet"
                        height="50px"
                        motif={true}
                        required={true}
                        isLoading={isLoadingRejetDelivery}
                        submit={() => handleInvalidatedDelivary(livraison)}
                        width="w-full"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            {isDetailVisible && (
              <div className="w-full">
                <CommentLivraison
                  bakeliste={livraison.bakeliste.name}
                  livraisonId={livraison.id}
                  profileLetters={profileLetters}
                />
              </div>
            )}
          </>
        );
      })  
      }
    </div>
  );
};
export default LivraisonsDetails;