import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import WysiwygForm from "./WysiwygForm";
import { toast } from "react-hot-toast";
import { useSelectData } from "../../hook/useSelectData";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import {
  selectData,
  setAccessToken,
} from "../../redux/features/userSliceWithTokenValidation";
import { useSelector } from "react-redux";
import InputProfilData from "../Utils/InputsData";
import { BiError } from "react-icons/bi";

export default function AddForm() {
  const navigate = useNavigate();
  const { apiUrlCat } = useAppContext();
  
  const [uploadForms, setuploadForms] = useState([
    { id: 1, type_code: "", description: "", telechargement: "" },
  ]);
  const [formIdCounter, setFormIdCounter] = useState(1);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { typesTemplates, categories } = useSelectData();
  const user = useSelector(selectData)
  const token = useSelector(setAccessToken);
  const ccessToken = token?.payload.auth.accessToken;

  const [valid, setValid] = useState(false);
  const [downloadError, setDownloadError] = useState(""); 

  const [formData, setFormData] = useState({
    image: "",
    titre: "",
    type_template: "",
    description: "",
    prix: "",
    aperçu: "",
    nom_categorie: "",
    type_template_id: "",
    categorie_id: "",
    created_by: user.id,
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setSelectedImage(file);
    }
  };

  const handleAddWysiwygForm = () => {
    const newFormId = formIdCounter + 1;
    setFormIdCounter(newFormId);
    setuploadForms([
      ...uploadForms,
      { id: newFormId, type_code: "", description: "", telechargement: "" },
    ]);
  };

  const handleDeleteWysiwygForm = (formId) => {
    setuploadForms(uploadForms.filter((form) => form.id !== formId));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,


    }));

    if (id === "type_code") {
      setuploadForms((prevForms) =>
        prevForms.map((form) =>
          form.id === id ? { ...form, type_code: value } : form
        )
      );
    }

    if (id === "type_template") {
      const selectedType = typesTemplates.find(
        (t) => t.type_template === value
      );
      if (selectedType) {
        setFormData((prevData) => ({
          ...prevData,
          type_template_id: selectedType.id,
        }));
      }
    }

    if (id === "nom_categorie") {
      const selectedCategorie = categories.find(
        (c) => c.nom_categorie === value
      );
      if (selectedCategorie) {
        setFormData((prevData) => ({
          ...prevData,
          categorie_id: selectedCategorie.id,
        }));
      }
    }
  };

  const handleTypeCodeChange = (formId, e) => {
    const { value } = e.target;
    setuploadForms((prevForms) =>
      prevForms.map((form) =>
        form.id === formId ? { ...form, type_code: value } : form
      )
    );
  };

  const handleWysiwygFormChange = (formId, field, value) => {
    setuploadForms((prevForms) =>
      prevForms.map((form) => {
        if (form.id === formId) {
          if (field === "telechargement" && !value.endsWith(".zip")) {
            setDownloadError("Le lien de téléchargement doit se terminer par .zip");
          } else {
            setDownloadError(""); 
          }
          return { ...form, [field]: value };
        }
        return form;
      })
    );
  }; 

  const handleSubmit = async (e) => {
    e.preventDefault();

    const allTempData = new FormData();
    allTempData.append("image", selectedImage);
    Object.keys(formData).forEach((key) => {
      allTempData.append(key, formData[key]);
    });

    uploadForms.forEach((form, index) => {
      allTempData.append(`uploadForms[${index}][type_code]`, form.type_code);
      allTempData.append(
        `uploadForms[${index}][description]`,
        form.description
      );
      allTempData.append(
        `uploadForms[${index}][telechargement]`,
        form.telechargement
      );
    });

    setLoading(true);
    try {
      const response = await fetch(`${apiUrlCat}/templates`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${ccessToken}`,
        },
        body: allTempData,
      });

      if (response.ok) {
        setFormData({
          image: "",
          titre: "",
          type_template: "",
          description: "",
          prix: "",
          aperçu: "",
          nom_categorie: "",
          type_template_id: "",
          categorie_id: "",
          created_by: user.id,
        });
        setuploadForms([
          { id: 1, type_code: "", description: "", telechargement: "" },
        ]);
        setSelectedImage(null);
        setImagePreview(null);
        const data = await response.json();
        toast.success("Template créé avec succès");
      } else {
        const errorData = await response.json();
        const { errors } = errorData;

        if (errors) {
          Object.values(errors).forEach((errorMessages) => {
            errorMessages.forEach((errorMessage) => {
              toast.error(errorMessage);
            });
          });
        } else {
          toast.error("Erreur lors de la création du template");
        }
      }
    } catch (error) {
      toast.error(
        "Erreur lors de la création du template veillez reessayer !",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const checkFormCompletion = () => {
    const { titre, type_template, description, prix, aperçu, nom_categorie ,downloadError} =
      formData;
    const allFormsComplete = uploadForms.every(
      (form) => form.type_code && form.description && form.telechargement
    );
    const formComplete = titre && downloadError&&
      type_template &&
      description &&
      aperçu &&
      nom_categorie &&
      allFormsComplete;
    setValid(formComplete);
  };

  useEffect(() => {
    checkFormCompletion();
  }, [formData, uploadForms]);

  return (
    <form
      className={`flex flex-col gap-y-2 pb-4 mb-16`}
      onSubmit={handleSubmit}
    >
      <header className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
        <h1
          className={`text-2xl font-bold tracking-tight text-vr sm:text-3xl p-2 mx-4`}
        >
          Ajout de Templates
        </h1>
      </header>

      <section className="w-auto border border-gray-200 shadow-md rounded-xl mx-6">
        <header className="flex flex-col gap-3 px-6 py-3 rounded-tl-md rounded-tr-md border-b border-gray">
          <div className="flex items-center gap-3">
            <div className="grid flex-1 gap-y-1">
              <h3 className={`text-base font-semibold leading-6  "text-bl`}>
                Ajout Template
              </h3>
              <p className={`overflow-hidden break-words text-sm  text-bl`}>
                Ajouter un nouveau Template dans <b>Bakeli work</b>
              </p>
            </div>
          </div>
        </header>

        <div className="p-2 border border-gray-200 rounded-br-lg rounded-bl-lg  border-gray">
          <div className="flex flex-col">
            <div className="part1 flex lg:flex-row flex-col justify-between">
              <InputProfilData
                formData={formData}
                handleInputChange={handleInputChange}
                typesTemplates={typesTemplates}
                categories={categories}
              />
              <div className="image basis-1/3 py-3">
                <div className="flex flex-col items-start border rounded-xl mx-3 border-gray-200 px-3 py-4 h-full">
                  <p className="text-gray-500 font-bold text-md ms-1 mb-3">
                    Image du Template*
                  </p>
                  <div className="w-full">
                    <label
                      htmlFor="file"
                      className="flex justify-center items-center bg-white border-gray-200 border rounded-xl cursor-pointer h-[250px]"
                    >
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="Prévisualisation"
                          className="w-full max-h-full h-auto rounded-xl"
                        />
                      ) : (
                        "Choisir une image"
                      )}
                    </label>
                    <input
                      type="file"
                      name="file"
                      id="file"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="part2 flex flex-col">
              <div className="flex flex-col gap-3 mx-3 mt-3 py-3 px-6 rounded-tl-xl rounded-tr-xl border border-gray-200">
                <div className="flex items-center gap-3">
                  <div className="grid flex-1 gap-y-1">
                    <p
                      className={`overflow-hidden break-words text-sm text-bl`}
                    >
                      Ajouter un nouveau type de téléchargement dans
                      <b>Bakeli work</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-3 mx-3 mb-2 border rounded-bl-xl rounded-br-xl border-gray-200">
                <header className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
                  <div>
                    <p
                      className={`text-sm mx-2 font-bold tracking-tighttext-bl p-2`}
                    >
                      Types de Téléchargements
                    </p>
                  </div>
                </header>
                <div className="wysiwyg flex flex-wrap justify-between">
                  {uploadForms.map((form) => (
                    <div
                      key={form.id}
                      className="w-full md:w-1/2 flex item-center justify-center"
                    >
                      <div className="w-[96%]">
                        <WysiwygForm
                          key={form.id}
                          formKey={form.id}
                          onDelete={() => handleDeleteWysiwygForm(form.id)}
                          handleInputChange={(e) =>
                            handleWysiwygFormChange(
                              form.id,
                              e.target.name,
                              e.target.value
                            )
                          }
                          handleTypeCodeChange={handleTypeCodeChange}
                          handleEditorChange={(value) =>
                            handleWysiwygFormChange(
                              form.id,
                              "description",
                              value
                            )
                          }
                        />
                        {downloadError && (
                          <div className=" w-full flex justify-center gap-2 items-start">
                            <BiError className="text-red text-xl" />
                            <p className="text-red text-center text-md">
                              {downloadError}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-center my-4 mx-3">
                  <button
                    className="grid-flow-col items-center justify-center rounded-lg px-2 py-2 shadow-md text-gray-950 hover:bg-gray-200 border border-gray-200"
                    type="button"
                    onClick={handleAddWysiwygForm}
                  >
                    <span className="text-gray-950">
                      Ajouter un types de Téléchargements
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <footer className="gap-3 flex items-center justify-end mx-6">
              <button
                className={`grid-flow-col items-center justify-center rounded-lg px-5 py-3 shadow-md text-gray-950 hover:scale-[98%] aimation-all duration-500 hover:bg-gray-200 border border-gray-200`}
                type="button"
              >
                <span
                  className=""
                  onClick={() => navigate("/workerPage/mesTemplates")}
                >
                  Annuler
                </span>
              </button>
              {valid && (
                <button
                  className="grid-flow-col w-full items-center justify-center rounded-lg px-5 py-3 bg-vr shadow-md text-gray-950 hover:scale-[98%] aimation-all duration-500 border border-gray-200"
                  type="submit"
                  disabled={loading ? true : false}
                >
                  <span className="text-white">
                    {loading ? (
                      <BeatLoader color="#ffffff" size={8} />
                    ) : (
                      "Créer Template"
                    )}
                  </span>
                  <span className="" style={{ display: "none" }}></span>
                </button>
              )}
            </footer>
          </div>
        </div>
      </section>
    </form>
  );
}
