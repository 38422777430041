import React, { useRef, useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useIsAuthenticate from "../../hook/useIsAuthentificated";
import {
  setAccessToken,
  setRefreshToken,
  setUserData,
} from "../../redux/features/userSliceWithTokenValidation";
import {
  CLIENT_DASHBOARDS,
  CLIENT_DASHBOARDS_DASHBOARD,
} from "../../Routes/navigation/navigationPaths";
import { useLoginUserMutation } from "../../services/auth-services/AuthService";
import MonBouton from "../Utils/design-system/buttons/MonBouton";
import MonInput from "../Utils/design-system/inputs/MonInput";
import Loader from "../Utils/Loader";
import { Entete } from "../Utils/Reutilisable";
import ModalPassword from "../Utils/ModalPassword";

function LoginForm() {
  const formRef = useRef(null);
  const { slug } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState("");
  const isAuthenticated = useIsAuthenticate();
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginUser, { isLoading: loading }] = useLoginUserMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!email) newErrors.email = "L'email est requis.";
    if (!password) newErrors.password = "Le mot de passe est requis.";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formData = new FormData(formRef.current);
    const data = {
      email: formData.get("email"),
      password: formData.get("password"),
    };

    try {
      const userData = await loginUser(data).unwrap();

      if (userData) {
        dispatch(setUserData(userData.user));
        dispatch(setAccessToken(userData.access_token));
        dispatch(setRefreshToken(userData.refresh_token));
        sessionStorage.setItem("isAuthentificated", isAuthenticated);
        navigate(
          slug
            ? `/${slug}`
            : `/${CLIENT_DASHBOARDS}${CLIENT_DASHBOARDS_DASHBOARD}`
        );
      }
    } catch (error) {
      // console.log(error);
      setFormError(error?.data?.message || "Serveur indisponible, Veuillez réesayer plutard !");
    }
  };

  return isAuthenticated ? (
    <Navigate
      to={`/${CLIENT_DASHBOARDS}${CLIENT_DASHBOARDS_DASHBOARD}`}
      replace
    />
  ) : (
    <form
      ref={formRef}
      className="px-6 max-xl:px-0 py-6"
      onSubmit={handleSubmit}
    >
      <Entete formError={formError} />
      <div className="w-full mb-2 md:mb-0">
        <MonInput
          label={"Email"}
          type={"email"}
          id={"email"}
          name={"email"}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setFormError("");
          }}
          position={"flex-col"}
          placeholder={"Email"}
          paddingB={
            "w-full bg-gray font-bold ps-4 rounded-xl outline-none border-none py-2"
          }
          labelStyle={"w-full text-[16px] pb-3"}
        />
        {errors.email && <div className="text-red-500">{errors.email}</div>}
        <MonInput
          label={"Mot de passe"}
          type={showPassword ? "text" : "password"}
          id={"password"}
          name={"password"}
          value={password}
          position={"flex-col"}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={"Mot de passe"}
          paddingB={
            "w-full bg-gray font-bold ps-4 rounded-lg outline-none border-none py-2"
          }
          labelStyle={"w-full text-[16px] pb-3"}
          suffix={
            <div className="flex justify-center mt-10 items-center">
              {showPassword ? (
                <FaRegEyeSlash
                  onClick={() => setShowPassword(false)}
                  className="cursor-pointer text-dsn -ms-[60px]"
                  size={"18px"}
                />
              ) : (
                <FaRegEye
                  onClick={() => setShowPassword(true)}
                  className="cursor-pointer text-dsn -ms-[60px]"
                  size={"18px"}
                />
              )}
            </div>
          }
        />

        {errors.password && (
          <div className="text-red-500">{errors.password}</div>
        )}
      </div>
      <div className="flex w-full my-2 pt-4">
        <MonBouton
          type={"submit"}
          children={loading ? <Loader size={5} /> : "Se connecter"}
          couleurFond={"bg-orange text-white"}
          bordure={`px-2 rounded-full ${loading ? "py-1": "py-2"} justify-center text-base w-full`}
          iconRight={""}
            icone={""}
            disabled={loading ? true : false}
        />
      </div>

      {/*========= Decommentez cette partie pour avoir l'option Mot de passe oublié=========*/}
      <div className="w-full pt-3 flex justify-center items-center mt-1">
        <div
          onClick={() => setOpenModal(true)}
          className="text-gr font-sans text-md underline-none cursor-pointer"
        >
          Mot de passe oublié ?
        </div>
        {openModal && <ModalPassword onClose={() => setOpenModal(false)} />}
      </div>
    </form>
  );
}

export default LoginForm;
