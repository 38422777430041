import React from 'react'
import { FooterPageAcueil } from '../../components/composPageAccueil/FooterPageAcueil'
import { Outlet } from 'react-router-dom'
import { HubForgeHeader } from '../../components/composPageAccueil/HubForgeHeader'

const Acceuil = () => {
  return (
    <div
        className={`min-h-[screen] h-full bg-fond bg-cover bg-no-repeat
        `}
    >
        <div className="w-full">
            <div className=''>
                <HubForgeHeader />
            </div>
            <Outlet />
            <div>
                <FooterPageAcueil />
            </div>
        </div>
    </div>
  )
}

export default Acceuil
