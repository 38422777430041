import React, { useState } from "react";
import { useAppContext } from "../../context/AppContext";
import Banner from "./Banner";
import Partenaires from "./Partenaires";
import TemplateComponent from "./TemplateComponent";
import ProjectSection from "./ProjectSection";
import Gamification from "./Gamification";
export const PageAccueil = () => {
  const { apiUrlImg } = useAppContext();
  const [selectCat, setSelectCat] = useState(null);
  const [selectTemp, setSelectTemp] = useState([]);
  const [activeCat, setActiveCat] = useState(null);

  const ClicCategory = (categoryId, t) => {
    setSelectCat(categoryId);
    setSelectTemp(t);
  };

  const handleSelectCategory = (categoryId, templates) => {
    setActiveCat(categoryId);
    setSelectTemp(templates);
  };
  

  return (
    <>
      <div className="flex justify-end items-center w-full pt-28">
        <Banner />
      </div>
      <div className="flex justify-center items-center">
        <Partenaires />
      </div>
      {/*<SearchModal
          selectedCategoryId={activeCat}
          onSelectCategory={handleSelectCategory}
        />*/}
      <ProjectSection title={"w-2/5"} />
      {/* Passez activeCat comme prop */}
      <TemplateComponent />
      <Gamification/>
    </>
  );
};

export default PageAccueil;
