import React, { useState, useEffect, useRef } from 'react';
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-hot-toast';
import ModalContent from './ModalContent';
import { ProjectsEndpoints } from '../../Routes/api/Endpoints';
import { useModal } from '../../context/ModalContext';
import CarouselModal from './CarouselModal';
import { formatDateISO } from './FormatDate';
import { selectData } from '../../redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';
import { useFetchProjectsQuery, useGetDataQuery, usePostDataMutation, usePutTaskMutation } from '../../services/auth-services/AuthService';
import ModalElement from './ModalElement';
import { useAppContext } from '../../context/AppContext';
import { FaImage } from 'react-icons/fa6';
import MonBouton from './design-system/buttons/MonBouton';
import { PulseLoader } from 'react-spinners';
import { getFirstLetters } from './Variables/constantes';

function Modal({ task, onClose, notifications }) {
  const [isVisible, setIsVisible] = useState(false);
  const { data: tasks, isLoading: isLoadingTask, refetch: fetchMembers } = useGetDataQuery({
    endpoint: ProjectsEndpoints.getPostulant,
    projectId: task?.id
  }, {refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    skip: false });
  //  console.log(tasks);
  const { data: taskID, refetch: refetchTask } = useGetDataQuery({
    endpoint: ProjectsEndpoints.getTaskById,
    projectId: task?.id
  }, {refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    skip: false });
  const [postData, { isLoading: isLoadingPostuleTask } ] = usePostDataMutation();
  const { data: microtasks, refetch: refetchMicroTache } = useGetDataQuery({
    endpoint: ProjectsEndpoints.getMicroTask,
    projectId: task?.id
  }, {refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    skip: false });
  const [putTask, { isLoading: isLoadingValidationTask }] = usePutTaskMutation();
  const [rejetTache, { isLoading: isLoadingRejetTache }] = usePutTaskMutation();
  const [updatedDate, { isLoading: isLoadingUpdateDates }] = usePutTaskMutation();
  const [postImage, { isLoading: isLoadingUpdateImg }] = usePostDataMutation();
  const { data: comments, refetch: fetchComments } = useGetDataQuery({
    endpoint: ProjectsEndpoints.getComments,
    projectId: task?.id
  }, {refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    skip: false });
  const statut = `${taskID?.tache?.etat === "EN_COURS" ? "En cours" : taskID?.tache?.etat === "TERMINEE" ? "Terminée" : taskID?.tache?.etat === "EN_REVUE" ? "En revue" : taskID?.tache?.etat === "ACCEPTEE" ? "Acceptée": "À faire"}`;
  const [selectedBakeliste, setSelectedBakeliste] = useState(null);
  const [validated, setValidated] = useState(false);
  const [date, setDate] = useState(null);
  const [dateFinished, setDateFinished] = useState(null);
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);
  const handleEditClick = () => {
    fileInputRef.current.click();
  };
  const handleCancel = () => {
    setImage(null);
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleSubmitImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    postImage({
      endpoint: ProjectsEndpoints.updateTask,
      elementID: task?.id,
      formData,
    })
      .unwrap()
      .then(() => {
        refetchTask();
        setImage(null);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  
  const [postuled, setPostuled] = useState(false);
  const { closeModalValidation, isOpenModalConfirmValidation, openModalPostule, closeModalPostule, isOpenModalConfirmPostule,
    isModalElementOpen,
    closeModalElement,
    modalTask
  } = useModal();
  const [openModalDate, setOpenModalDate] = useState(false);
  const [text, setText] = useState("");
  const user = useSelector(selectData)
  const { apiUrlImg } = useAppContext();
  const [updateTask, {isLoading: loadingChangeStatus}] = usePostDataMutation();
  const { refetch } = useFetchProjectsQuery(ProjectsEndpoints.getProjects, {refetchOnMountOrArgChange: true,
    refetchOnFocus: true, // Rafraîchit quand l'onglet redevient actif
    skip: false });
  const status = [{statut: "A_FAIRE", text: "À faire"}, {statut: "EN_COURS", text: "En cours"}, {statut: "TERMINEE", text: "Terminée"}, {statut: "EN_REVUE", text: "En revue"}, {statut: "ACCEPTEE", text: "Acceptée"}];
  useEffect(() => {
    if (task) {
      setIsVisible(true);
    }
  }, [task]);

  const [postules, { isLoading } ] = usePutTaskMutation();

  if (!task) return null;

  const handleValidatedTask = (delivery) => {
    const validatedTask = {
      id: delivery?.id
    }
    putTask({ endpoint: ProjectsEndpoints.validerTache, task: validatedTask })
    .unwrap()
    .then((data) => {
      toast.success(data.message);
      closeModalValidation()
      refetch()
      refetchTask()
    })
    .catch((error) => {
      console.log(error);
      toast.error(error);
    });
  }

  const handleInvalidatedTask = () => {
    const formData = {
      id: task.id,
      motif : text
    }
    
    rejetTache({ endpoint: ProjectsEndpoints.rejeterTache, task: formData })
    .unwrap()
    .then((data) => {
      toast.success(data.message);
      refetch()
      refetchTask();
      setText("")
      closeModalValidation()
    })
    .catch((error) => {
      toast.error(error?.data.message);
    });
  }

  const handlePostuler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('email', user.email);
    formData.append('statut', user.statut);
    postData({ endpoint: ProjectsEndpoints.postCandidature, elementID: task?.id, formData })
    .unwrap()
    .then((data) => {
      toast.success(data.message);
      refetch();
      setPostuled(false)
      refetchTask();
      closeModalPostule()
    }).catch((error) => {
      toast.error(error.message);
    });
  };

  const handleAssign = async (optionId) => {
    const formData = {
      id: optionId,
      tacheId: task?.id
    };

    postules({ endpoint: ProjectsEndpoints.updateAssignation, task: formData })
    .unwrap()
    .then((data) => {
      toast.success(data.message);
      fetchMembers()
      refetch();
      refetchTask();
      closeModalPostule()
    })
    .catch((error) => {
      toast.error(error.message);
    });
  };

  const handleUpdateDate = () => {
    const formData = {
      id: task.id,
      ...(date && { started_at: formatDateISO(date) }),
      ...(dateFinished && { finished_at: formatDateISO(dateFinished) })
    };
    
    updatedDate({ endpoint: ProjectsEndpoints.updateTaskDate, task: formData })
      .unwrap()
      .then((data) => {
        toast.success(data.message);
        setDate(null)
        closeModalElement()
        setDateFinished(null);
        refetch()
        refetchTask()
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsVisible(false);
      setTimeout(() => onClose(), 300);
    }
  };  
  const handleAssignment = (memberId) => {
    setPostuled(false);
    setSelectedBakeliste(memberId);
    openModalPostule();
  };

  const handleCancelDate = () => {
    closeModalElement();
    setDateFinished(null);
    setDate(null);
  };

  const handleChangeStatus = (statut) => {
    const formData = new FormData();
    formData.append("etat", statut);

    updateTask({
      endpoint: ProjectsEndpoints.updateTask,
      elementID: task?.id,
      formData,
    })
      .unwrap()
      .then(() => {
        refetch();
        refetchTask();
      })
      .catch((error) => {
        console.log(error);
    });
  }
  
  return (
    <>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[4000] transition-opacity duration-300 ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleOverlayClick}
      >
        <div
          className={`bg-fond bg-no-repeat bg-cover rounded shadow-lg w-[80%] max-lg:h-[90%] h-full max-lg:overflow-y-auto transform content overflow-y-scroll scrollbar-none	text-black transition-all duration-300 ${
            isVisible
              ? "translate-y-0 opacity-100"
              : "-translate-y-10 opacity-0"
          }`}
        >
          <div className="flex justify-end relative">
            {user.statut === "coach" && task?.image && (
              <div
                className="bg-gray-50 bg-opacity-75 rounded-md items-center absolute cursor-pointer top-4 text-xs font-inter font-semibold left-4 inline-flex gap-2 py-2 px-3"
                onClick={handleEditClick}
              >
                <FaImage
                  // size={24}
                  className="size-3"
                />
                <span>Changer</span>
              </div>
            )}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageChange}
              className="hidden"
            />
            {task?.image && (
              <img
                src={
                  image
                    ? URL.createObjectURL(image)
                    : `${apiUrlImg}/${taskID?.tache?.image}`
                }
                alt="img"
                className="w-full h-64 object-cover"
              />
            )}
            <span className="cursor-pointer flex justify-center items-center p-2 absolute top-4 right-4 bg-white rounded-full">
              <IoClose
                className="size-4"
                onClick={() => {
                  setIsVisible(false);
                  setTimeout(() => onClose(), 300);
                }}
              />
            </span>
          </div>
          {image && (
            <div className="flex gap-3 pt-3 px-6">
              <MonBouton
                children={"Annuler"}
                couleurFond={"text-xs text-red"}
                bordure={"px-2"}
                action={handleCancel}
                type={"button"}
                alignment={""}
              />
              <MonBouton
                children={"Enrégistrer"}
                couleurFond={"text-xs text-bleu"}
                bordure={"px-2"}
                action={handleSubmitImage}
                type={"button"}
                alignment={"items-center"}
                iconRight={
                  isLoadingUpdateImg && <PulseLoader size={3} color="#2200e5" />
                }
              />
            </div>
          )}
          <ModalContent
            task={taskID?.tache}
            tasks={tasks}
            handleAssign={handleAssign}
            selectedBakeliste={selectedBakeliste}
            setSelectedBakeliste={setSelectedBakeliste}
            isLoading={isLoading}
            action={handlePostuler}
            isLoadingTask={isLoadingTask}
            statut={statut}
            isLoadingPostuleTask={isLoadingPostuleTask}
            user={user}
            profileLetters={getFirstLetters}
            assigned={task?.assigned_to_id}
            comments={comments}
            fetchData={fetchComments}
            microTaches={microtasks}
            refetchMicroTaches={refetchMicroTache}
            handleValidatedTask={handleValidatedTask}
            handleInvalidatedTask={handleInvalidatedTask}
            isLoadingValidationTask={isLoadingValidationTask}
            text={text}
            setText={setText}
            loaderConfirm={isLoadingRejetTache}
            setValidated={setValidated}
            handleCancel={handleCancel}
            isLoadingUpdateImg={isLoadingUpdateImg}
            setPostuled={setPostuled}
            openModalPostule={openModalPostule}
            date={date}
            setDate={setDate}
            handleUpdateDate={handleUpdateDate}
            isLoadingUpdateDates={isLoadingUpdateDates}
            dateFinished={dateFinished}
            setDateFinished={setDateFinished}
            notifs={notifications}
            setOpenModalDate={setOpenModalDate}
            image={image}
            setImage={setImage}
            handleEditClick={handleEditClick}
            handleImageChange={handleImageChange}
            fileInputRef={fileInputRef}
            handleSubmitImage={handleSubmitImage}
            status={status}
            loadingChangeStatus={loadingChangeStatus}
            handleChangeStatus={handleChangeStatus}
          />
        </div>
      </div>
      {isOpenModalConfirmValidation && (
        <CarouselModal
          onClose={closeModalValidation}
          modalConfirm={true}
          actionText={validated ? "valider" : "rejeter"}
          actionAnnuler={closeModalValidation}
          validation={validated ? true : false}
          title={validated ? "Valider" : "Rejeter"}
          loader={validated ? isLoadingValidationTask : isLoadingRejetTache}
          text={text}
          setText={setText}
          actionConfirm={
            validated ? () => handleValidatedTask(task) : handleInvalidatedTask
          }
        />
      )}
      {isOpenModalConfirmPostule && (
        <CarouselModal
          onClose={closeModalPostule}
          modalConfirm={true}
          actionText={
            postuled
              ? "postuler sur cette tache"
              : "assigner cette tache à ce Bakeliste"
          }
          validation={true}
          title={postuled ? "Postuler" : "Assigner"}
          loader={postuled ? isLoadingPostuleTask : isLoading}
          actionConfirm={
            postuled ? handlePostuler : () => handleAssign(selectedBakeliste)
          }
        />
      )}
      {isModalElementOpen && (
        <ModalElement
          titleModal={"Membre assigné"}
          task={modalTask}
          onClose={closeModalElement}
          membres={
            isLoadingTask
              ? [{ name: "Chargement..." }]
              : tasks
              ? tasks?.postulants
              : [{ name: "Aucun postulant" }]
          }
          isLoading={isLoading}
          handleAssign={handleAssignment}
          loadingMember={selectedBakeliste}
          profileLetters={getFirstLetters}
          memberAssigned={task?.assigned_to_id}
        />
      )}
    </>
  );
}

export default Modal;