import React from "react";
import { FiUploadCloud } from "react-icons/fi";
import { PulseLoader } from "react-spinners";
import { useAppContext } from "../../../context/AppContext";
import useAjoutProjectForm from "../../../hook/useAjoutProjectHookForm";
import MonBouton from "../../Utils/design-system/buttons/MonBouton";
import MonInput from "../../Utils/design-system/inputs/MonInput";
import InputWysiwyg from "../../Utils/InputWysiwyg";
import {
  formLabelImage,
  formProps,
  formPropsLinkAndObj,
} from "../../Utils/UtilsForm";

const initialFormState = {
  name: "",
  email: "",
  statut: "prospect",
  number: "",
  pays: "",
  titre: "",
  objectif: "",
  budget: "",
  document: null,
  image: null,
};

function AjoutProjectForm() {
  const {
    text,
    handleTextChange,
    errors,
    formRef,
    formState,
    handleChange,
    handleSubmit,
    isLoadingProjectAdded,
  } = useAjoutProjectForm(initialFormState, formProps, formPropsLinkAndObj);
  const { countryOptions } = useAppContext();
  // console.log(formState);

  return (
    <form
      ref={formRef}
      className="flex flex-wrap justify-between"
      onSubmit={handleSubmit}
    >
      {formProps.map((elem, i) => (
        <div className="w-[49%] mb-3 md:mb-0" key={i}>
          <MonInput
            label={elem.label}
            type={elem.type}
            id={elem.id}
            key={elem.id}
            value={formState[elem.id]}
            onChange={handleChange}
            options={elem.id === "pays" ? countryOptions : elem.options}
            placeholder={elem.placeholder}
            paddingB={elem.paddingB}
            labelStyle={elem.labelStyle}
            required={true}
            suffix={elem.suffix}
            padding={"py-3"}
            marginB={"w-full"}
            position={"!flex"}
          />
          {errors[elem.id] && <div className="text-red">{errors[elem.id]}</div>}
        </div>
      ))}
      <div className="flex flex-wrap justify-between w-full">
        <div className="md:w-[49%]">
          {formPropsLinkAndObj.map((elem, i) => (
            <div className="w-full mb-3 md:mb-0" key={i}>
              <MonInput
                label={elem.label}
                type={elem.type}
                id={elem.id}
                key={elem.id}
                onChange={handleChange}
                placeholder={elem.placeholder}
                paddingB={elem.paddingB}
                labelStyle={elem.labelStyle}
                required={true}
                padding={"py-3"}
                position={"!flex"}
              />
              {errors[elem.id] && (
                <div className="text-red">{errors[elem.id]}</div>
              )}
            </div>
          ))}
          <InputWysiwyg
            text={text}
            setText={handleTextChange}
            placeholder={"Exprimer votre besoin"}
            label={"Description"}
            height={"90px"}
            width={"w-full"}
            required={true}
          />
          {errors["text"] && (
            <div className="text-red w-full">{errors["text"]}</div>
          )}
        </div>
        <div className="md:w-[49%] h-full">
          <div className="w-full h-[90%] mb-3 md:mb-0">
            <MonInput
              label={
                formState?.image !== null
                  ? formLabelImage(
                    <img
                      src={URL.createObjectURL(formState.image)}
                      alt="preview"
                      className="!w-full h-full rounded-md"
                    />
                  )
                  : formLabelImage(
                    <div className="flex flex-col items-center gap-3 w-[95%]">
                      <MonBouton
                        type={"button"}
                        couleurFond={"bg-orange text-white"}
                        bordure={"px-2 py-2 rounded-full font-inter text-sm justify-center gap-1 text-sm w-1/2"}
                        iconRight={""}
                        icone={""}
                        children={"Télécharger une image"}
                      />
                      <div className="w-full py-12 flex flex-col justify-center items-center border border-dashed rounded-md font-inter gap-3 border-grys/80">
                        <FiUploadCloud className="size-12 text-graytertiary" />
                        <span className="text-graytertiary">Glisser et déposer une image</span>
                      </div>
                    </div>
                  )
              }
              type={"file"}
              id={"image"}
              onChange={handleChange}
              placeholder={""}
              paddingB={"md:w-[95%] py-3 flex items-center hidden"}
              labelStyle={"text-md ms-1 mb-3 w-full"}
            />
            {errors["image"] && (
              <div className="text-red-500">{errors["image"]}</div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center py-6 w-[98%]">
        <MonBouton
          type={"submit"}
          couleurFond={"bg-orange text-white"}
          bordure={"px-2 py-2 rounded-full justify-center gap-1 text-sm w-1/6"}
          iconRight={""}
          icone={""}
        >
          {isLoadingProjectAdded ? (
            <>
              Envoi en cours <PulseLoader size={"5px"} color="#fff" />
            </>
          ) : (
            "Envoyer"
          )}
        </MonBouton>
      </div>
    </form>
  );
}

export default AjoutProjectForm;
