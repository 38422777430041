import React, { useRef, useState } from 'react';
import { Calendar } from 'primereact/calendar';
// import { IoCheckmark, IoCloseOutline } from 'react-icons/io5';
import { PulseLoader } from 'react-spinners';
import MonBouton from '../../Utils/design-system/buttons/MonBouton';

const DateSelector = ({ date, setDate, handleUpdateDate, isLoadingUpdateDates, dateFinished, setDateFinished, handleCancelDate }) => {
  const [activeField, setActiveField] = useState('start');
  const endDateRef = useRef(null);
  const [isEndDateFocused, setIsEndDateFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  
  const handleDateChange = (e) => {
    if (activeField === "start") {
      setDate(e.value);
      setErrorMessage("");
      setTimeout(() => {
        setActiveField("end");
        endDateRef.current.focus();
      }, 0);
    } else if (activeField === "end") {
      setDateFinished(e.value);
      setErrorMessage(""); 
    }
  };
  
  
    const handleSave = () => {
      if (dateFinished && date && dateFinished < date) {
        setErrorMessage(
          "La date de fin ne peut pas être inférieure à la date de début."
        );
        return;
      }

      handleUpdateDate();
    };

  
    return (
      <div className="pb-3">
        <div className="w-full h-64 mb-3">
          <Calendar
            className="w-full h-full border-none rounded shadow-none outline-none scrollbar-thin !overflow-hidden content custom-calendar !text-xs p-2"
            value={activeField === "start" ? date : dateFinished}
            onChange={handleDateChange}
            onClick={() => setActiveField("start")}
            dateFormat="dd/mm/yy"
            inline
          />
        </div>
        <div className="flex-auto mb-2 px-4">
          <label
            htmlFor="buttondisplay"
            className="font-semibold text-xs text-blck block mb-2"
          >
            Date début
          </label>
          <Calendar
            className="border rounded shadow-none outline-none px-2 py-1 focus:outline-none focus:shadow-none w-full text-xs"
            value={date}
            onChange={handleDateChange}
            dateFormat="dd/mm/yy"
            onClick={() => setActiveField("start")}
          />
        </div>
        <div className="flex-auto mb-2 px-4">
          <label
            htmlFor="buttondisplay"
            className="font-semibold text-xs text-blck block mb-2"
          >
            Date fin
          </label>
          <Calendar
            className={`border rounded px-2 py-1 w-full text-xs cursor-text ${
              isEndDateFocused ? "border-sky-300" : ""
            }`}
            dateFormat="dd/mm/yy"
            ref={endDateRef}
            value={dateFinished}
            onChange={handleDateChange}
            onClick={() => setActiveField("end")}
            onFocus={() => setIsEndDateFocused(true)}
            onBlur={() => setIsEndDateFocused(false)}
          />
        </div>
        {errorMessage && (
          <p className="text-red text-xs mb-2">{errorMessage}</p>
        )}
        <div className="flex px-4 justify-between">
          <MonBouton
            children={"Annuler"}
            couleurFond={"text-xs text-white bg-grys/70 py-1 rounded w-[48%]"}
            bordure={"px-2"}
            action={handleCancelDate}
            type={"button"}
            alignment="justify-center"
          />
          <MonBouton
            children={
              isLoadingUpdateDates ? (
                <>
                  Enrégistrer <PulseLoader size={5} color="#fff" />
                </>
              ) : (
                "Enrégistrer"
              )
            }
            couleurFond={"text-xs bg-orange text-white"}
            bordure={"px-2"}
            action={handleSave}
            type={"button"}
            alignment={`items-center rounded py-1 w-[48%] justify-center ${
              isLoadingUpdateDates && "justify-between"
            }`}
          />
        </div>
      </div>
    );
};

export default DateSelector;