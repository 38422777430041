import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectToken } from "../../redux/features/userSliceWithTokenValidation";
import { apiUrlCat } from "../../Routes/api/global_vars";

const baseQueryWithContext = () => {
  return fetchBaseQuery({
    baseUrl: apiUrlCat,
    prepareHeaders: (headers, { getState }) => {
      const token = selectToken(getState());
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });
};

export const API_TEMPLATE = createApi({
  reducerPath: "template",
  baseQuery: baseQueryWithContext(),
  endpoints: (builder) => ({
    getTemplates: builder.query({
      query: (type) => `templates/${type || ""}`,
    }),
    getTypesTemplates: builder.query({
      query: () => "/types-templates",
      transformResponse: (response) => response.typesTemplates.reverse(),
    }),
    getCategories: builder.query({
      query: () => "/categories",
      transformResponse: (response) => response.categories.reverse(),
    }),
    getTypesCodes: builder.query({
      query: () => "/types-codes",
      transformResponse: (response) => response.typesCodes.reverse(),
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useGetTypesTemplatesQuery,
  useGetCategoriesQuery,
  useGetTypesCodesQuery,
} = API_TEMPLATE;

export default API_TEMPLATE;
