import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AuthentificationEndpoints } from "../../Routes/api/Endpoints";
import { logout, selectToken, setAccessToken, setRefreshToken } from "../../redux/features/userSliceWithTokenValidation";
import { apiUrlCat } from "../../Routes/api/global_vars";


const baseQuery = fetchBaseQuery({
  baseUrl: apiUrlCat,
  prepareHeaders: (headers, { getState }) => {
    const token = selectToken(getState());
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    const refreshToken = api.getState().auth.refreshToken;

    if (refreshToken) {
      const refreshResult = await baseQuery(
        {
          url: AuthentificationEndpoints.refreshToken,
          method: "POST",
          body: { refresh: refreshToken },
        },
        api,
        extraOptions
      );

      if (refreshResult?.data) {
        const newAccessToken = refreshResult.data.access;
        const newRefreshToken = refreshResult.data.refresh;

        api.dispatch(setAccessToken(newAccessToken));
        api.dispatch(setRefreshToken(newRefreshToken));

        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logout());
      }
    } else {
      api.dispatch(logout());
    }
  }

  return result;
};

export const API = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  refetchOnReconnect: true,
  keepUnusedDataFor: 60000,
  tagTypes: ["Projects", "Notifications"],
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: ({ email, password }) => ({
        url: AuthentificationEndpoints.postUserLogin,
        method: "POST",
        body: { email, password },
      }),
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: AuthentificationEndpoints.logout,
        method: "POST",
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(logout());
      },
    }),
    refreshToken: builder.mutation({
      query: () => ({
        url: AuthentificationEndpoints.refreshToken,
        method: "POST",
      }),
      onSuccess: (response, { dispatch }) => {
        const { access } = response.data;
        dispatch(setAccessToken(access));
      },
    }),
    addProject: builder.mutation({
      query: ({ endpoints, formData }) => ({
        url: endpoints,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Projects"],
    }),
    postData: builder.mutation({
      query: ({ endpoint, elementID, formData }) => ({
        url: `${endpoint}${elementID}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Projects"],
    }),
    deleteData: builder.mutation({
      query: ({ endpoint, dataId }) => ({
        url: `${endpoint}${dataId}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Projects", "Notifications"],
    }),
    putTask: builder.mutation({
      query: ({ endpoint, task }) => {
        return {
          url: `${endpoint}${task?.id}`,
          method: "PUT",
          body: task,
        };
      },
      invalidatesTags: ["Projects", "Notifications"],
    }),
    patchData: builder.mutation({
      query: ({ endpoint, task }) => {
        return {
          url: `${endpoint}${task?.id}`,
          method: "PATCH",
          body: task,
        };
      },
      invalidatesTags: ["Notifications"],
    }),
    fetchProjects: builder.query({
      query: (endpoints) => ({
        url: endpoints,
        method: "GET",
      }),
      transformResponse: (response) => response.projects,
      providesTags: ["Projects"],
    }),
    fetchNotifications: builder.query({
      query: (endpoints) => ({
        url: endpoints,
        method: "GET",
      }),
      transformResponse: (response) => response.notifications,
      // keepUnusedDataFor: 5,
      providesTags: ["Projects", "Notifications"],
    }),
    getData: builder.query({
      query: ({ endpoint, projectId }) => {
        if (projectId) {
          return `${endpoint}${projectId}`;
        }
        return endpoint;
      },
      providesTags: ["Projects"],
    }),
    fetchCategories: builder.query({
      query: (onSelectCategory) => {
        if (!onSelectCategory) {
          return { url: "", method: "GET" };
        }
        return {
          url: `/categories-templates`,
          method: "GET",
        };
      },
    }),
  }),
});
  
export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useRefreshTokenMutation,
  useAddProjectMutation,
  useFetchProjectsQuery,
  useGetDataQuery,
  usePostDataMutation,
  useDeleteDataMutation,
  usePutTaskMutation,
  useFetchCategoriesQuery,
  useFetchNotificationsQuery,
  usePatchDataMutation,
} = API;
  
  export default API