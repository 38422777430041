
import User1 from "../../../assets/images/user1.png";
import User2 from "../../../assets/images/user2.png";
import User3 from "../../../assets/images/user3.png";
import User4 from "../../../assets/images/user4.png";


export const testimonials = [
  {
    id: 1,
    name: "Youmané Diop",
    text: "Je suis très satisfait par cette plateforme, mes journées sont bien plus simples grâce aux projets proposés, et elle est devenue essentielle dans ma vie, surtout en gagnant de l'argent en un rien de temps 😃",
    avatar: User1,
  },
  {
    id: 2,
    name: "Mouhamet Badiane",
    text: "Je peux enfin travailler sur des projets intéressants tout en augmentant mes revenus. Cette plateforme a vraiment changé la manière dont j'aborde mes journées, et je suis plus productif que jamais ! 😍",
    avatar: User2,
  },
  {
    id: 3,
    name: "Mamadou Barry",
    text: "Grâce à cette plateforme, j'ai pu décrocher des tâches rapidement et améliorer mes compétences. Gagner de l'argent tout en apprenant, c'est un véritable bonus dans ma vie quotidienne ! 😍",
    avatar: User3,
  },
  {
    id: 4,
    name: "Serigne Mourtalla Sylla",
    text: "La flexibilité des tâches me permet de gérer mon emploi du temps à ma manière, tout en accumulant des revenus. Une solution qui rend la vie tellement plus simple et enrichissante ! 😍",
    avatar: User4,
  },
];
