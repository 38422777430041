import React from 'react'
import { BsEmojiDizzyFill } from 'react-icons/bs'

const HistoriqueProject = () => {
  return (
    <div className=''>
      <div className='flex flex-col justify-center items-center mt-56 gap-3'>
        <BsEmojiDizzyFill size={"40px"} />
        <h1 className="text-sm italic font-semibold font-sans text-center">Aucune historique de projet disponible pour le moment</h1>
      </div>
    </div>
  )
}

export default HistoriqueProject
