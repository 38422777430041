import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const navbarItems = [
  { text: "Accueil", path: "/" },
  { text: "Projets", path: "/projets" },
  { text: "Templates", path: "/templates" },
];

const useScrollTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default useScrollTop;
