import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalElementOpen, setModalElementOpen] = useState(false)
  const [isModalMembersOpen, setModalMembersOpen] = useState(false)
  const [modalTask, setModalTask] = useState(null);
  const [modalNotifications, setModalNotifications] = useState(null)
  const [modalCarousel, setModalCarousel] = useState(null);
  const [livraisonModal, setlivraisonModal] = useState(false)
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)
  const [isOpenModalConfirmPostule, setIsOpenModalConfirmPostule] = useState(false)
  const [isOpenModalConfirmValidation, setIsOpenModalConfirmValidation] = useState(false)
  const [refetchData, setRefetchData] = useState(() => () => {});

  const openModal = (task, refetchFunc, notifs = null) => {
    setModalTask(task);
    setRefetchData(() => refetchFunc);
    setModalOpen(true);
    setModalNotifications(notifs);
  };
  const closeModal = () => {
    setModalTask(null);
    setModalOpen(false);
  };
  const openModalElement = (refetchFunc) => {
    setRefetchData(() => refetchFunc)
    setModalElementOpen(true);
  }
  const closeModalElement = () => {
    setModalElementOpen(false);
  }
  const openModalProjectMember = (refetchFunc) => {
    setRefetchData(() => refetchFunc)
    setModalMembersOpen(true);
  }
  const closeModalProjectMember = () => {
    setModalMembersOpen(false);
  }

  const openModalLivraison = (img) => {
    setModalCarousel(img)
    setlivraisonModal(true)
  }

  const closeModalLivraison = () => {
    setlivraisonModal(false)
    setModalCarousel(null)
  }
  const openModalConfirm = () => {
    setIsOpenModalConfirm(true)
  }

  const closeModalConfirm = () => {
    setIsOpenModalConfirm(false)
  }

  const openModalPostule = () => {
    setIsOpenModalConfirmPostule(true)
  }
  const closeModalPostule = () => {
    setIsOpenModalConfirmPostule(false)
  }

  const openModalValidation = () => {
    setIsOpenModalConfirmValidation(true)
  }
  const closeModalValidation = () => {
    setIsOpenModalConfirmValidation(false)
  }


  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal, modalTask, openModalLivraison, closeModalLivraison, refetchData, setRefetchData, openModalElement, closeModalElement, isModalElementOpen, openModalProjectMember, closeModalProjectMember,
     isModalMembersOpen, livraisonModal, isOpenModalConfirm, closeModalConfirm, openModalConfirm, modalCarousel, openModalPostule, closeModalPostule, isOpenModalConfirmPostule, closeModalValidation, openModalValidation, isOpenModalConfirmValidation, modalNotifications }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);