import React from "react";
import { useAppContext } from "../../context/AppContext";
import MonBouton from "../Utils/design-system/buttons/MonBouton";
import SearchModal from "../composPageAccueil/SearchFuction/SearchModal";
import { useNavigate } from "react-router-dom";
import { CardesTemplate } from "../composPageAccueil/CardesTemplate";
import Logo from "../../assets/LogoHubForge.png";
import DefaltProfile from "../../assets/userLogo.jpg";
import { selectData } from "../../redux/features/userSliceWithTokenValidation";
import { useSelector } from "react-redux";
import { useGetTemplatesQuery } from "../../services/templates_services/TemplateService";
import { IoAddCircleOutline } from "react-icons/io5";

export const MesTemplates = () => {
  const { apiUrlImg } = useAppContext();
  const { data: templates } = useGetTemplatesQuery();
  const navigate = useNavigate();
  const user = useSelector(selectData);

  const getCreator = (template) => {
    if (template.user_role === "bakeliste") {
      return {
        creator: template.created_by === user.id ? "Moi" : template.user_name,
        profile:
          template.user_profile === null
            ? DefaltProfile
            : `${apiUrlImg}/${template.user_profile}`,
      };
    } else {
      return { creator: "Bakeli Work", profile: Logo };
    }
  };

  const mesTemplates = templates?.filter((t) => t.user_name === user.name);

  return (
    <div className={`w-full mb-10 `}>
      <SearchModal />

      <div className="flex mb-16 pt-4 top-0 bg-white_100 w-full justify-center ">
        <MonBouton
          textColor=" hover:scale-[98%] w-[50%] max-sm:w-[95%] flex justify-center transition-all duration-500 px-3 rounded-lg py-1 text-xl"
          couleurFond="border-orange border-2 bg-white text-mr"
          action={() => navigate("addForm")}
        >
          <div className="flex items-center gap-3">
            <IoAddCircleOutline className="text-mr text-2xl" />
            <p className="text-lg"> Ajoute ton template </p>
          </div>
        </MonBouton>
      </div>

      <div className={` max-lg:w-[97%] flex gap-7 justify-center flex-wrap`}>
        {mesTemplates?.map((temp) => {
          const { creator, profile } = getCreator(temp);
          return (
            <CardesTemplate
              key={temp.id}
              HandlePreview={temp.aperçu}
              imageTemplate={`${apiUrlImg}/${temp.image}`}
              handlePriceTo={temp.aperçu}
              titreCrdTemplate={temp.titre}
              DesctiptionTemplate={temp.description}
              prixTemplate={temp.prix === null ? "Free" : temp.prix}
              telechargeLink={`/telecharge/${temp.slug}`}
              userName={creator}
              userProfile={profile}
            />
          );
        })}
      </div>
    </div>
  );
};
