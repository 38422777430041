import React, { useState } from 'react'
import DateSelector from './DateSelector';
import { IoClose } from 'react-icons/io5';
import { formatDateComplete, formatDateShortMonth } from '../../Utils/FormatDate';

const DateModal = ({
  date,
  setDate,
  handleUpdateDate,
  isLoadingUpdateDates,
  setDateFinished,
    dateFinished,
  onClose,
    handleCancelDate,
    debutEcheance,
  finEcheance,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const handleOverlayClick = (e) => {
      if (e.target === e.currentTarget) {
        setIsVisible(false);
        setTimeout(() => onClose(), 300);
      }
    };
  return (
    <div
      className={`fixed inset-0 flex h-full left-9 top-16 bg-opacity-20 z-[4000] transition-opacity duration-300 `}
      onClick={handleOverlayClick}
    >
      <div
        className={`bg-white shadow px-0 w-[28%] !h-max scrollbar-none overflow-y-scroll max-lg:overflow-y-auto transform transition-all duration-300`}
      >
        <div className="flex justify-end items-center pt-1 px-2">
          <span className="cursor-pointer flex justify-center items-center p-2 bg-[#F2F2F2] rounded-full">
            <IoClose
              className="size-3"
              onClick={() => {
                setIsVisible(false);
                setTimeout(() => onClose(), 300);
              }}
            />
          </span>
        </div>
        <div className='px-6'>
            <div className=' border-b pb-1 border-gryborder text-xs'>
                <span className='font-semibold'>Echéance: </span>
                <span className='inline-flex gap-3'>
                    <span className='tooltip' data-tip={formatDateComplete(debutEcheance)}>{formatDateShortMonth(debutEcheance)}</span>
                        <span>-</span>
                    <span className='tooltip' data-tip={formatDateComplete(finEcheance)}>{formatDateShortMonth(finEcheance)}</span>
                </span>
            </div>       
        </div>
        <div>
          <DateSelector
            date={date}
            setDate={setDate}
            handleUpdateDate={handleUpdateDate}
            isLoadingUpdateDates={isLoadingUpdateDates}
            setDateFinished={setDateFinished}
            dateFinished={dateFinished}
            handleCancelDate={handleCancelDate}
          />
        </div>
      </div>
    </div>
  );
};

export default DateModal
