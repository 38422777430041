import React from 'react'
import { HubForgeHeader } from '../../components/composPageAccueil/HubForgeHeader';
import { FooterPageAcueil } from '../../components/composPageAccueil/FooterPageAcueil';
// import Breadcrumbs from '../../components/Utils/Breadcrumbs';
import AcceuilTaskSection from '../../components/composPageAccueil/ProjectSection';
import ProjectSection from '../../components/composPageAccueil/ProjectSection';


const ProjectsPage = () => {

    return (
      <div
        className={`min-h-screen h-full  text-gray-600 bg-wt
            `}
      >
        <div className="w-full h-full">
          <div className="">
            <HubForgeHeader />
          </div>
          {/* <div className="flex justify-center pt-20 pb-6">
            <div className="w-[88%]">
              <Breadcrumbs home={true} />
            </div>
          </div> */}
          <div className="flex justify-center pt-16">
            <ProjectSection
              display={"hidden"}
              position={"!flex-col !justify-center !items-center !w-full"}
              width={"!w-full"}
              title={"w-3/4 text-center"}
              description={"!w-[58%] text-center"}
              button={"hidden"}
              contener={"!w-full"}
              selectCat={true}
            />
          </div>
          <div>
            <FooterPageAcueil />
          </div>
        </div>
      </div>
    );
}

export default ProjectsPage
