import React from 'react'
// import { FaRegEye } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { useAppContext } from '../../../context/AppContext';

function CardprojectComponent({image, titre, objectif, budget, etat, id , path}) {
  // console.log(etat);
  
  const {apiUrlImg} = useAppContext();
  const textEtat = `${etat === "EN_COURS"? "En cours" : etat==="TERMINEE"? "Terminé": etat==="EST_DEMARRE"? "Débuté" : etat ==="A_FAIRE" ? "A faire" : ""}`
  
  return (
    <div className="px-2 max-md:w-full max-lg:mb-2 mb-1 bg-transparent py-2 rounded-xl hover:bg-sky-100/20">
      <Link to={path}>
        <figure className="flex justify-center group relative h-[302px] max-md:h-96 max-lg:h-[220px]">
          <img
            src={`${apiUrlImg}/${image}`}
            alt="img"
            className="w-full h-full cursor-pointer rounded-md object-cover"
          />
          {/* <div className='bg-gray-700 rounded-md w-full h-full opacity-0 group-hover:opacity-60 absolute transition-opacity duration-500'></div>
            <div className='absolute hidden group-hover:flex inset-0 items-center justify-center w-full h-full cursor-pointer'>
              <FaRegEye className='text-blc cursor-pointer text-2xl'/>
            </div> */}
        </figure>
      </Link>
      <div className="pt-4 grid gap-1">
        <div className="flex justify-between items-center">
          <div className="text-lg font-inter font-bold text-noirel">
            {titre}
          </div>
          <div className="text-xs font-inter text-orange">{budget} FCFA</div>
        </div>
        <p className="text-xs font-mandali text-grayrel">{objectif}</p>
        <div className="flex justify-between items-center">
          <div
            className={`text-end text-xs ${
              etat === "EN COURS"
                ? "text-orange"
                : etat === "TERMINEE"
                ? "text-green-500"
                : etat === "EST_DEMARRE"
                ? "text-grys"
                : "text-red"
            }`}
          >
            {textEtat}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardprojectComponent