import React from 'react';

const ReplyForm = ({ replyText, setReplyText, handleReplySubmit, handleCancelReply, setShowReplies }) => {
  return (
    <div className='mt-2 flex flex-col gap-3 w-full'>
      <textarea
        className='w-full pt-1 !pb-0 border-b items-end border-gryborder/80 text-xs outline-none bg-transparent resize-none'
        value={replyText}
        onChange={(e) => setReplyText(e.target.value)}
        placeholder='Ecrire votre réponse...'
      />
      <div className='flex gap-2 items-center text-xs font-inter'>
        <button className=' flex items-center cursor-pointer text-grys' onClick={handleCancelReply}>
            Annuler
        </button>
        <button className=' flex items-center cursor-pointer text-vr' onClick={handleReplySubmit}>
            Envoyer
        </button>
      </div>
    </div>
  );
};

export default ReplyForm;