import React from 'react';
import imgBanner from '../../assets/images/Group 1171275189.png';
import vectorBanner from '../../assets/images/Vector 32.png';
import imgEllipse from '../../assets/images/Ellipse 33.png'
import { useNavigate } from 'react-router-dom';
import MonBouton from '../Utils/design-system/buttons/MonBouton';
import { TbClick } from "react-icons/tb";
import CardBanner from './cardBanner';
import badge from '../../assets/images/fluent-emoji-flat_military-medal.svg'
import { FiBarChart2 } from "react-icons/fi";
import cardPayement from "../../assets/images/Frame 45.png"

function Banner() {
  const navigate = useNavigate();
  return (
    <div className="banner w-[94%] flex flex-wrap justify-between">
      <div className="w-[45%] max-lg:w-[40%] flex flex-col gap-8">
        <h1
          className={`text-[3.6rem] max-lg:text-3xl !font-inter max-sm:text-[1rem] text-noirel 
          max-sm:leading-[20px] leading-[80px] font-[700] max-xl:leading-[50px] max-xl:text-5xl max-sm:tracking-wider pb-4`}
        >
          <p>Transformez votre</p>
          <p>
            <span className="text-orange">vision</span> en{" "}
            <span className="text-vr">réalité</span>
          </p>
          <p>simplement</p>
        </h1>
        <div>
          <img src={vectorBanner} alt="vector" className="w-[85%]" />
        </div>
        <div className="flex flex-col gap-9 w-[85%]">
          <p className="font-mandali text-[18px] text-noir max-xl:text-sm max-lg:text-xs">
            Concrétiser vos idées et votre vision grâce à des modèles de sites
            vitrines et des dashboards.
          </p>
          <div className="flex justify-between flex-wrap items-center">
            <div className="w-1/2">
              <MonBouton
                children={"Soumettre un projet !"}
                couleurFond={"bg-orange"}
                bordure={
                  "border py-2 max-sm:py-1 max-sm:px-1 max-xl:text-xs max-lg:text-[8px] max-lg:p-1 rounded-full justify-center text-base font-inter text-white gap-2 font-sans font-medium max-sm:text-xs w-11/12"
                }
                action={() => navigate("soumettre-projet")}
                type={"button"}
              />
            </div>
            <div className="w-1/2">
              <MonBouton
                children={"Rejoindre un projet"}
                couleurFond={"bg-transparent"}
                bordure={
                  "border w-full px-3 py-2 max-sm:py-1 max-sm:px-1 max-xl:text-xs max-lg:text-[8px] max-lg:justify-start max-lg:gap-1 rounded-full justify-center text-base font-inter text-noirel gap-2 font-sans font-medium max-sm:text-xs border-none"
                }
                icone={
                  <TbClick
                    size={40}
                    className="text-noirel stroke-current stroke-1 max-xl:size-8 max-lg:size-4 animate-pulse"
                  />
                }
                action={() => navigate("soumettre-projet")}
                type={"button"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-[54%] max-lg:w-[58%] flex justify-center relative">
        <CardBanner
          title={"Gain total"}
          price={"75.000FCFA"}
          infos={"flex-col"}
          button={true}
          position={
            "absolute top-[5%] left-[-2%] w-[38%]  px-4 py-4 max-lg:py-2 max-lg:px-2 animate-custom-bounce"
          }
          border={"border-b border-whiteclear"}
        />
        <CardBanner
          title={"Développeur web"}
          price={"Mamadou Barry"}
          profile={imgEllipse}
          position={
            "absolute top-[25%]  right-[5%] w-[38%] px-4 py-2 max-lg:py-2 max-lg:px-2 animate-custom-bounce"
          }
          icon={
            <img
              src={badge}
              alt="img"
              className="w-5 h-5 max-lg:w-3 max-lg:h-3"
            />
          }
          infos={"flex-col-reverse"}
          place={"bg-vrt p-2 rounded-full max-lg:p-1"}
        />
        <img
          src={cardPayement}
          className="absolute top-[60%] right-[8%] w-[20%] animate-custom-bounce-top"
        />
        <CardBanner
          title={"Revenu total"}
          price={"100.000FCFA"}
          infos={"flex-col"}
          position={
            "absolute top-[75%] left-[10%] w-[22%] py-3 px-3 max-lg:p-2 animate-custom-bounce-top"
          }
          border={"!items-end"}
          icon={<FiBarChart2 className="text-vrt" size={18} />}
          place={"flex flex-col items-end w-[30%]"}
        />
        <img src={imgBanner} alt="img" className="w-[72%]" />
      </div>
    </div>
  );
}

export default Banner