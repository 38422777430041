import React from "react";


const PageNotFound = () => {
  return (
    <div className="text-center h-screen flex items-center">
      <div className="ext-red text-4xl w-full text-center">
        Page not found
        <p className="pt-6">404</p>
      </div>
    </div>
  );
};

export default PageNotFound;