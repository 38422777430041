import React from 'react';
import MonBouton from '../Utils/design-system/buttons/MonBouton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CardprojectComponent from '../client/dashboardComponents/CardprojectComponent';
import SkeletonLoader from '../Utils/Skeleton';
import { ProjectsEndpoints } from '../../Routes/api/Endpoints';
import { BsEmojiDizzyFill } from 'react-icons/bs';
import { PROJECTSACCUEIL } from '../../Routes/navigation/navigationPaths';
import { selectData } from '../../redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';
import { useFetchProjectsQuery } from '../../services/auth-services/AuthService';
import SelectCategory from './SelectCategory';
import ProjectCards from './ProjectCards';
function ProjectSection({display, position, title, description, width, button, contener, selectCat}) {
  const navigate = useNavigate();
  const { data: projets, isLoading } = useFetchProjectsQuery(ProjectsEndpoints.getProjects, {refetchOnMountOrArgChange: true,
    refetchOnFocus: true, // Rafraîchit quand l'onglet redevient actif
    skip: false });
  const validProjects = projets?.filter((proj) => proj.validation_projet === "VALIDE")
  const user = useSelector(selectData)
  const skeletonTab = Array.from({ length: 4 }, (_, i) => ({ id: `skeleton${i + 1}` }));
  const location = useLocation()
  const path = location.pathname
  console.log('test');
  const projectsToDisplay =
    path === "/projets" ? validProjects : validProjects?.slice(0, 4);
  

  return (
    <div
      className={`flex flex-col justify-center items-center pt-12  ${contener}`}
    >
      <div
        className="flex justify-between items-center flex-wrap w-[90%] ps-4"
        style={{ position: "relative", bottom: "50px" }}
      >
        <div className={`flex justify-between flex-wrap w-4/5 ${position}`}>
          <h1
            className={`text-[2.8rem] max-xl:text-4xl max-lg:text-xl font-semibold font-inter text-noirel max-sm:text-lg ${title}`}
          >
            Derniers projets sur le marché
          </h1>
          <div
            className={`w-3/5 flex justify-center py-2 max-xl:py-2 ${description}`}
          >
            <p
              className={`text-base leading-7 font-mandali max-xl:text-sm max-lg:text-xs text-grayrel w-11/12 ${width}`}
            >
              Nous proposons une variété de fonctionnalités intéressantes qui
              peuvent vous aider à développer vos compétences au travail et à 
              gagner de l’argent facilement.
            </p>
          </div>
        </div>
        {!user && (
          <div className={`flex justify-end w-1/5 ${display}`}>
            <MonBouton
              children={"Rejoindre un projet"}
              couleurFond={"bg-orange"}
              bordure={
                "border py-2 max-sm:py-1 max-sm:px-1 max-xl:text-sm max-lg:text-[8px] max-lg:p-1 rounded-full justify-center text-base font-inter text-white gap-2 font-sans font-medium max-sm:text-[8px] max-lg:w-3/4  w-full"
              }
              // iconRight={<IoIosArrowDroprightCircle size={"25px"} />}
              action={() => navigate("projets")}
              type={"button"}
            />
          </div>
        )}
      </div>
      {selectCat && (
        <div className={`flex justify-center w-full`}>
          <SelectCategory
            id="projets"
            widthContainer={"!w-[60%] !justify-center"}
          />
        </div>
      )}
      {validProjects?.length > 4 && (
        <div className={`w-[88%] flex justify-end ${button}`}>
          <Link
            to={`${PROJECTSACCUEIL}`}
            className="w-1/5 flex items-center justify-end gap-2 text-vr"
          >
            <h4 className="text-base font-semibold font-inter text-noir_200">
              Tout afficher
            </h4>
          </Link>
        </div>
      )}
      {isLoading ? (
        <div className={`flex max-md:gap-5 gap-7 w-[90%] flex-wrap pb-1`}>
          {skeletonTab.map((elem, i) => (
            <SkeletonLoader {...elem} key={i} cardProject={true} />
          ))}{" "}
        </div>
      ) : validProjects && validProjects.length > 0 ? (
        <div
          className={`grid grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-1 max-md:px-8 gap-4 ps-6 w-[90%] py-6`}
        >
          {projectsToDisplay?.map((elem, index) => (
            <CardprojectComponent
              key={index}
              image={elem?.image}
              budget={elem?.budget}
              titre={elem?.titre}
              etat={""}
              objectif={elem?.objectif}
              path={`/${elem?.slug}`}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center py-28 gap-3">
          {/**<BsEmojiDizzyFill size={"40px"} />
          <h1 className="text-sm font-sans font-semibold text-center">
            Aucun projet disponible pour le moment </h1> */}
          <ProjectCards />
        </div>
      )}
    </div>
  );
}

export default ProjectSection