import React, { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import { HiOutlineLogout } from 'react-icons/hi';
import imageProfile from '../../assets/face8.jpg.png';
import { GoMail } from "react-icons/go";
import { IoNotificationsOutline } from 'react-icons/io5';
import { selectData } from '../../redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';
import Notification from './Notification';
import { useLogoutUserMutation } from '../../services/auth-services/AuthService';
import { BiLoaderCircle } from 'react-icons/bi';
import { FaChevronDown } from "react-icons/fa6";
import { BiX } from "react-icons/bi";

function NavbarClient({ sidebarOpen, setSidebarOpen }) {
  const { apiUrlImg } = useAppContext();
  const user = useSelector(selectData);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNavbarUserVisible, setIsNavbarUserVisible] = useState(false);
  const { filteredProjects, setIsContentVisible, setIsIconExpanded } =
    useAppContext();
  const [searchTerm, setSearchTerm] = useState("");
 const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsContentVisible(true);
    setIsIconExpanded(true);
    if (value.trim() === "") {
      setFilteredSuggestions([]);
      return;
    }

    const suggestions = filteredProjects &&
      filteredProjects?.filter((project) =>
        project.titre.toLowerCase().includes(value.toLowerCase())
      ) || [];
    setFilteredSuggestions(suggestions);
  };

  const handleSelectProject = (slug) => {
    setSearchTerm("");
    setFilteredSuggestions([]);
    navigate(`/client-dashboard/projects/${slug}`);
  };
  const closeSearch = () => {
    setSearchTerm("");
    setFilteredSuggestions([]);
  }

  const [logout] = useLogoutUserMutation();
  // const dispatch = useDispatch()
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleNavbarUser = () => {
    setIsNavbarUserVisible(!isNavbarUserVisible);
  };

  const navigate = useNavigate();
  const LogOut = () => {
    logout();
    navigate("/");
  };

  

  return (
    <header className="sticky top-0 z-20 flex w-full text-gray-600">
      <div
        className={`flex justify-between items-center z-10 bg-transparent py-1 w-full`}
      >
        <div className="flex items-center gap-8 justify-between w-full px-6 max-lg:px-5 max-md:pe-3">
          <Link className={`flex items-center space-x-2`} to="/">
            <BiLoaderCircle className={`text-[26px] text-orange`} />
            <span
              className={`text-[17px] max-lg:text-sm max-lg:font-normal font-[500] text-vrt font-sans self-center max-lg:self whitespace-nowrap`}
            >
              Bakeli Work
            </span>
          </Link>
          <div className="w-[32%] max-md:w-[45%] max-lg:w-[28%] flex justify-end">
            <label
              className={`input input-bordered max-lg:text-xs flex items-center gap-2 !bg-grisrel text-black h-7 rounded-full focus:!outline-none relative !outline-none !shadow-none text-sm font-poppins !border-none !w-[94%] !max-md:w-full`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-search"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="m21 21-4.3-4.3" />
              </svg>
              <input
                type="text"
                className="grow !bg-transparent"
                placeholder="Rechercher"
                value={searchTerm}
                onChange={handleSearch}
              />
              {searchTerm && (
                <BiX className="cursor-pointer" onClick={() => closeSearch()} />
              )}
              {filteredSuggestions.length > 0 && (
                <ul className="absolute w-full top-6 left-0 bg-white text-noirel border border-gray-300 rounded-md mt-2 max-h-40 overflow-y-auto shadow-md z-10">
                  {filteredSuggestions.map((project) => (
                    <li
                      key={project.slug}
                      className="px-3 flex justify-between items-center py-2 cursor-pointer text-noirel hover:bg-gray-200"
                      onClick={() => handleSelectProject(project.slug)}
                    >
                      <div className='flex gap-3'>
                        <span className="bg-gray-200 p-1 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            className="lucide lucide-search"
                          >
                            <circle cx="11" cy="11" r="8" />
                            <path d="m21 21-4.3-4.3" />
                          </svg>
                        </span>
                        {project.titre}
                      </div>
                      <img src={`${apiUrlImg}/${project?.image}`} className="w-12 object-cover rounded h-8"
                        alt="img"/>
                    </li>
                  ))}
                </ul>
              )}
            </label>
          </div>
          <div className="flex gap-8">
            <div className="hidden md:flex items-center gap-3">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 32 32"
              >
                <path
                  fill="currentColor"
                  d="M17.74 30L16 29l4-7h6a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h9v2H6a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4h-4.84Z"
                ></path>
                <path fill="currentColor" d="M8 10h16v2H8zm0 6h10v2H8z"></path>
              </svg> */}
              <Notification />
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                className="cursor-pointer"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M12 6.826A.913.913 0 1 0 12 5a.913.913 0 0 0 0 1.826m6.088 0a.913.913 0 1 0 0-1.826a.913.913 0 0 0 0 1.826m-12.176 0a.913.913 0 1 0 0-1.826a.913.913 0 0 0 0 1.826M12 12.913a.913.913 0 1 0 0-1.826a.913.913 0 0 0 0 1.826m6.088 0a.913.913 0 1 0 0-1.826a.913.913 0 0 0 0 1.826m-12.176 0a.913.913 0 1 0 0-1.826a.913.913 0 0 0 0 1.826M12 19a.913.913 0 1 0 0-1.826A.913.913 0 0 0 12 19m6.088 0a.913.913 0 1 0 0-1.826a.913.913 0 0 0 0 1.826M5.912 19a.913.913 0 1 0 0-1.826a.913.913 0 0 0 0 1.826"
                ></path>
              </svg> */}
            </div>
            <div className="flex flex-col items-center justify-center md:order-2 space-x-3 max-md:space-x-0 rtl:space-x-reverse relative max-md:flex-row max-md:gap-2">
              <button
                type="button"
                className="flex text-sm rounded-badge bg-white space-x-2 p-1 md:me-0 items-center"
                id="user-menu-button"
                aria-expanded={isMenuOpen}
                onClick={toggleMenu}
                data-dropdown-placement="bottom"
              >
                {!user?.profile ? (
                  <img
                    src={imageProfile}
                    alt="userProfile"
                    className="w-8 h-8 rounded-full"
                  />
                ) : (
                  <img
                    src={`${apiUrlImg}/${user?.profile}`}
                    alt="userProfile"
                    className="w-8 h-8 rounded-full object-cover"
                  />
                )}
                <FaChevronDown className="ml-1" size={11} />
              </button>
              <div
                className={`!z-[10000] ${
                  isMenuOpen ? "block" : "hidden"
                } my-4 text-base list-none bg-white rounded-lg shadow-md absolute top-full right-0`}
                id="user-dropdown"
              >
                <div className="px-3 py-3 border-b">
                  <span className="block text-sm text-gray-900">
                    {user?.name}
                  </span>
                  <span className="block text-xs text-gray-500 truncate dark:text-gray-400">
                    {user?.email}
                  </span>
                </div>
                <ul className="py-2" aria-labelledby="user-menu-button">
                  <li
                    className="flex items-center gap-2 cursor-pointer p-3 hover:bg-gray-200"
                    onClick={LogOut}
                  >
                    <HiOutlineLogout size="18px" />
                    <span className="text-xs">Déconnexion</span>
                  </li>
                </ul>
              </div>
              <button
                data-collapse-toggle="navbar-user"
                type="button"
                className="inline-flex items-center p-1 w-7 h-7 justify-center text-sm text-gray-500 rounded-sm md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-200 dark:focus:ring-gray-200"
                aria-controls="navbar-user"
                aria-expanded="false"
                onClick={toggleNavbarUser}
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              </button>
              <div
                className={`z-[4000] ${
                  isNavbarUserVisible ? "block" : "hidden"
                } md:hidden absolute top-full right-0 bg-white shadow-lg rounded-lg mt-2`}
              >
                <ul className="py-2">
                  <li className="flex items-center gap-2 cursor-pointer p-3 hover:bg-gray-200">
                    <GoMail size="18px" />
                    <span className="text-xs">Messages</span>
                  </li>
                  <li className="flex items-center gap-2 cursor-pointer p-3 hover:bg-gray-200">
                    <IoNotificationsOutline size="18px" />
                    <span className="text-xs">Notifications</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default NavbarClient;