import React from 'react'
import MonBouton from '../Utils/design-system/buttons/MonBouton';

const CardBanner = ({title, price, icon, profile, button, position, border, place, infos}) => {
  return (
    <div className={`bg-white rounded-lg shadow-sm absolute ${position}`}>
      <div className={`!flex justify-between items-center ${border}`}>
        {profile && <div className='w-14 h-14 max-xl:w-11 max-xl:h-11 max-lg:w-8 max-lg:h-8'>
            <img src={profile} alt="profile" className="w-full h-full rounded-full" />
        </div>}
        <div className={`flex gap-1 ${infos}`}>
          <span className="text-grysclear font-inter text-[11px] max-xl:text-[8px]">{title}</span>
          <span className="font-inter text-[14px] text-noir font-medium max-xl:text-[10px]">{price}</span>
        </div>

        {icon && (
          <div className={`${place}`}>
            <span className=''>{icon}</span>
          </div>
        )}
        {button && (
          <span className="w-16 ">
            <MonBouton
              type="button"
              couleurFond="bg-vrt !w-full py-1 rounded-full text-xs max-lg:text-[10px] font-inter justify-center text-white"
            >
              Retirer
            </MonBouton>
          </span>
        )}
      </div>
    </div>
  );
}

export default CardBanner;
