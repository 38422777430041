import React from "react";
import CardDataStats from "../Utils/charts/CardDataStats";
import { cardsData, dataCards } from "../Utils/CardsDashboardData";

const DashboardPageActor = () => {
  return (
    <div className="">
      <h1 className="text-2xl pt-3 text-bl font-bold">Titre de la page</h1>
      <div className="flex gap-6 max-sm:flex-col my-6">
        {dataCards.map((card, index) => (
          <CardDataStats
            key={index}
            {...card}
          />
        ))}
      </div>
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-4">
        {cardsData.map((card, index) => (
          <CardDataStats key={index} {...card} />
        ))}
      </div>
    </div>
  );
};

export default DashboardPageActor;
