import React from 'react'
import AjoutProjetClient from '../../components/client/dashboardComponents/AjoutProjetClient'

function AddingProjectPage() {
  return (
    <div className='pt-12'>
      <AjoutProjetClient />
    </div>
  )
}

export default AddingProjectPage