import React from "react";

const MonBouton = ({
  id,
  couleurFond,
  bordure,
  icone,
  action,
  textColor,
  children,
  type,
  iconRight,
  disabled,
  alignment,
  tabIndex,
  // role
}) => {
  return (
    <button
      className={`items-center ${alignment} ${couleurFond} ${bordure} ${textColor} flex`}
      type={type}
      onClick={action}
      id={id}
      disabled={disabled}
      tabIndex={tabIndex}
      // role={role}
    >
      {icone && <span className="me-2 text-[20px]">{icone}</span>}
      {children}
      {iconRight && <span className=" text-[20px]">{iconRight}</span>}
    </button>
  );
};

export default MonBouton;
