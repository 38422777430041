import React from "react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"
import useIsAuthenticate from "../hook/useIsAuthentificated";
import { privateRoutes } from "./navigation/navigationRoutes/private";
import PageNotFound from "../pages/NotFound/PageNotFound";
import { LOGIN_PATH, NOTFOUND_PATH } from "./navigation/navigationPaths";
import { publicRoutes } from "./navigation/navigationRoutes/public";

/*
|--------------------------------------------------------------------------
| Web Routes Rendering
|--------------------------------------------------------------------------
|
| Ici on rend les routes private ou public en fonction de isAuthenticated
| La maniere dont react-router fonctionne, on ne doit pas rendre les routes
| public si on es authentifier et vice-versa, afin d'eviter certaines erreurs
| de redirections
|
*/
function Roots() {
  const isAuthenticated = useIsAuthenticate();

  const router = isAuthenticated
    ? createBrowserRouter([
        ...privateRoutes,
        ...publicRoutes,
        {
          path: NOTFOUND_PATH,
          element: <PageNotFound />,
        },
      ])
    : createBrowserRouter([
        ...publicRoutes,
        {
          path: "*", 
          element: <Navigate to={LOGIN_PATH} />,
        },
        {
          path: NOTFOUND_PATH,
          element: <PageNotFound />,
        },
      ]);

  return (
    <RouterProvider
      router={router}
      fallbackElement={<p>Loading...</p>}
    />
  );
}

export default Roots;