import React from 'react'
import ProjectMember from './ProjectMember';

const ProjectHeader = ({
  profileLetters,
  members,
  projectId,
  projectParticipants,
  refreshData,
  options,
    loader,
    user,
    page,
  projectName
}) => {
    return (
      <div className="py-6 flex justify-between text-noir_300">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-3">
            <span className="border-e-2 border-grys/50 pe-2 text-blur">
              {page}
            </span>
            <span className="flex gap-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 1024 1024"
              >
                <path
                  fill="currentColor"
                  d="M448 68.48v64.832A384.128 384.128 0 0 0 512 896a384.13 384.13 0 0 0 378.688-320h64.768A448.128 448.128 0 0 1 64 512A448.13 448.13 0 0 1 448 68.48"
                ></path>
                <path
                  fill="currentColor"
                  d="M576 97.28V448h350.72A384.064 384.064 0 0 0 576 97.28M512 64V33.152A448 448 0 0 1 990.848 512H512z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
                  <path
                    fill="currentColor"
                    d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
                  ></path>
                </g>
              </svg>
            </span>
            <span className='font-inter font-normal'>{projectName}</span>
          </div>
          <div className="inline-flex gap-1 text-[13px] text-blueclear font-mandali">
            <span>Bonjour,</span>
            <span>
              {user?.statut === "coach" && "coach"} {user?.name}
            </span>
          </div>
        </div>
        <ProjectMember
          profileLetters={profileLetters}
          members={members}
          projectId={projectId}
          projectParticipants={projectParticipants}
          refreshData={refreshData}
          options={options}
          loader={loader}
        />
      </div>
    );
};

export default ProjectHeader
