import React from "react";
import { BiLoaderCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import Logo from "../../assets/Logo_Bakeli.png"
import { footerItems } from "../Utils/UtilsTask";
import Newsletter from "./Newsletter";

export const FooterPageAcueil = () => {
  const date = new Date();
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
    };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  return (
    <div className=" mt-20 py-8 text-sm tracking-wide bg-fondfooter bg-no-repeat bg-cover relative">
      <div className="flex items-center justify-center md:max-w-full pt-24">
        <Newsletter />
        <div className="flex flex-wrap items-start justify-between text-sm sm:justify-between md:justify-between w-[88%] max-lg:mt-10">
          <div className="flex flex-col gap-3 max-sm:w-full w-[18%]">
            <a className={`flex items-center gap-2`} href="/">
              <BiLoaderCircle className={`text-[26px] text-orange`} />
              <span
                className={`text-[17px] max-xl:text-[12px] max-lg:text-[14px] font-[500] text-vrt font-inter self-center whitespace-nowrap`}
              >
                Bakeli Work
              </span>
            </a>
            <div className="flex flex-col gap-2 text-sm font-inter ">
              <p className="inline-flex font-mandali text-sm max-xl:text-xs max-lg:text-[9px]">
                Hello@coderthemes.com
              </p>
              <p className="inline-flex font-mandali text-sm max-xl:text-xs max-lg:text-[9px]">
                +01 ( 1234 567 890 )
              </p>
            </div>
          </div>
          <div className={`flex justify-between w-[80%]`}>
            {footerItems.map((item, i) => (
              <div className={`flex flex-col gap-3 text-noirel`} key={i}>
                <h3
                  className={`text-sm font-semibold inline-flex gap-1 items-center font-inter max-lg:text-xs`}
                >
                  {item.title?.text}
                </h3>
                <div className="flex flex-col font-mandali text-xs max-xl:text-[11px] max-lg:text-[8px] gap-3 pt-2">
                  <Link
                    to={item.linkOne?.path ? item.linkOne?.path : ""}
                    className="inline-flex gap-2 items-center"
                  >
                    {item.linkOne?.text}
                  </Link>
                  <Link
                    to={item.linkTwo?.path ? item.linkTwo?.path : ""}
                    className="inline-flex gap-2 items-center"
                  >
                    {item.linkTwo?.text}
                  </Link>
                  <Link
                    to={item.linkThree?.path ? item.linkThree?.path : ""}
                    className="inline-flex gap-2 items-center"
                  >
                    {item.linkThree?.text}
                  </Link>
                  <Link
                    to={item.linkFour ? item.linkFour?.path : ""}
                    className="inline-flex gap-2 items-center"
                  >
                    {item.linkFour?.text}
                  </Link>
                  <Link
                    to={item.linkFive ? item.linkFive?.path : ""}
                    className="inline-flex gap-2 items-center"
                  >
                    {item.linkFive?.text}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
