import React from 'react'
import SidebarItem from './SidebarItem';
import { fonctionnalites, menuSidebar, pages } from '../Utils/UtilsSidebar';
// import { BiLoaderCircle } from 'react-icons/bi';
// import { Link } from 'react-router-dom';
import { selectData } from '../../redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';

function SidebarClient({ sidebarOpen, setSidebarOpen }) {
  const data = useSelector(selectData)
  
  return (
    <div
      className={`flex flex-col sidebar justify-start absolute left-0 top-0 h-screen w-56 2xl:w-72 gap-3 duration-300 ease-linear lg:static lg:translate-x-0 z-[2000]
        ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } overflow-y-scroll scrollbar-none`}
    >
      <div className="flex items-center justify-between px-2 gap-4">
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          className="block lg:hidden"
        >
          <svg
            className="fill-current"
            width="16"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      <div className="">
        <ul className="gap-1 flex flex-col justify-center items-center max-md:gap-1">
          {menuSidebar
            .filter((item) => item.userStatut.includes(data?.statut))
            .map((item, index) => (
              <li
                className="text-right transition duration-500 max-lg:text-left pb-2 text-vr w-11/12"
                key={index}
              >
                <SidebarItem {...item} key={index} />
              </li>
            ))}
          {/* <li className="uppercase text-indigo font-inter text-[9px] w-full px-6 font-semibold ">fonctionnalités</li> */}
          {fonctionnalites
            .filter((item) => item.userStatut.includes(data?.statut))
            .map((item, index) => (
              <li
                className={`text-right transition duration-500 max-lg:text-left text-vr w-11/12`}
                key={index}
              >
                <SidebarItem {...item} key={index} />
              </li>
            ))}
          {/* <li className="uppercase text-indigo font-inter text-[9px] w-full px-6 font-semibold">pages</li> */}
          {pages
            .filter((item) => item.userStatut.includes(data?.statut))
            .map((item, index) => (
              <li
                className="text-right transition duration-500 max-lg:text-left text-vr w-11/12"
                key={index}
              >
                <SidebarItem {...item} key={index} />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default SidebarClient;