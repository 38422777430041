import React from "react";
import { NavBarPageAccueil } from "./NavBarPageAccueil";

export const HubForgeHeader = () => {
  return (
      <div
        className={`flex justify-center items-center h-14 max-lg:h-12 ps-4 w-full fixed z-[1000] max-sm:px-4 max-sm:border-0 bg-blanc shadow border-b`}
      >
        <NavBarPageAccueil />
      </div>
  );
};
