import ClientPage from "../../../layouts/ClientLayout/ClientPage";
import DashboardClientPage from "../../../pages/Client/DashboardClientPage";
import { ADD_FORM, AJOUT_PROJET_CLIENT, CLIENT_DASHBOARDS, CLIENT_DASHBOARDS_DASHBOARD, DASHBOARD, DETAIL_PROJECT_DASHBOARD, DOCUMENT_PROJECT_DASHBOARD, DONNEES_FINANCIERES, LANDING_PAGE, LIVRABLE, LIVRAISONSDETAIL, MES_TEMPLATES, PARAMETER, PAYMENT, PROJECT_DETAIL_ID, PROJECTID, PROJECTS, TACHES, TELECHARGER_TEMPLATES, WORKER_PAGE } from "../navigationPaths";
import ProjectsDetail from "../../../components/client/dashboardComponents/ProjectsDetail";
import ProjectClientPage from "../../../pages/Client/ProjectClientPage";
import ParameterClientPage from "../../../pages/Client/ParameterClientPage";
// import TelechargePage from "../../../components/Templates/TelechargePage";
import { MesTemplates } from "../../../components/Worker/MesTemplates";
import AddForm from "../../../components/Worker/AddForm";
import AddProject from "../../../components/composPageAccueil/AddProject";
import ProjectSpace from "../../../components/client/projectsComponents/ProjectSpace";
import { ProjectsRedirect } from "../../../components/client/projectsComponents/ProjectsRedirect";
import VoirLivrable from "../../../pages/Client/VoirLivrable";
import HistoriqueProject from "../../../pages/Client/HistoriqueProject";
import HistoriquePayment from "../../../pages/Client/HistoriquePayment";
// import LivraisonsPage from "../../../pages/Client/LivraisonsPage";

export const privateRoutes = [
    {
        path: CLIENT_DASHBOARDS,
        element: <ClientPage />,
        children: [
            {
                path: "",
                element: <DashboardClientPage />
            },
            {
                path: CLIENT_DASHBOARDS_DASHBOARD,
                element: <DashboardClientPage />
            },
            {
                path: AJOUT_PROJET_CLIENT,
                element: <AddProject />
            },
            {
                path: PROJECT_DETAIL_ID,
                element: <ProjectsDetail />,
                children: [
                    {
                        path: DETAIL_PROJECT_DASHBOARD,
                        element: "Details"
                    },
                    {
                        path: DOCUMENT_PROJECT_DASHBOARD,
                        element: "Documents"
                    },
                    {
                        path: DONNEES_FINANCIERES,
                        element: "Données financières"
                    }
                ]
            },
            {
                path: PROJECTS,
                element: <ProjectClientPage />,
                children: [
                    {
                        path: "",
                        element: <ProjectsRedirect />
                    },
                    {
                        path: `:slug`,
                        element: <ProjectSpace />,
                    },
                ]
            },
            {
                path: PARAMETER,
                element: <ParameterClientPage />
            },
            {
                path: LIVRABLE,
                element: <VoirLivrable />
            },
            {
                path: TACHES,
                element: <HistoriqueProject />
            },
            {
                path: PAYMENT,
                element: <HistoriquePayment />
            },
            {
                path: MES_TEMPLATES,
                element: <MesTemplates />
            },
            {
                path: ADD_FORM,
                element: <AddForm />
            }
        ]
    },
    
]