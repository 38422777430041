import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { BiLoaderCircle } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";
import { HiXMark } from "react-icons/hi2";
import { PropagateLoader } from "react-spinners";
import { useAppContext } from "../../context/AppContext";
import MonBouton from "./design-system/buttons/MonBouton";
import MonInput from "./design-system/inputs/MonInput";
import { Entete } from "./Reutilisable";
import { AuthentificationEndpoints } from "../../Routes/api/Endpoints";

export default function ModalPassword({ onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const { apiUrlCat } = useAppContext();

  const handleClick = async (e) => {
    e.preventDefault();
    if (email.trim() === "") {
      setEmailError("L'email est requis");
      setErrorMessage("L'email est requis pour effectuer cette demande !");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`${apiUrlCat}/${AuthentificationEndpoints.addResetLinkEmail}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Une erreur s'est produite");
      }

      setIsLoading(false);
      onClose();
      toast.success("Email de réinitialisation envoyé avec succès !");
      setEmail("");
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="bg-black/40 transition-all duration-500 max-lg:bg-white fixed inset-0"></div>
      <div className=" w-4/12 max-md:w-[80%]  max-sm:w-[90%] max-lg:w-[60%]  py-11 me-[54px] border-2 max-lg:mx-auto z-10 bg-white rounded-xl shadow-lg">
        <div className=" relative p-4 sm:p-7 ">
          <button
            className="absolute -top-3 right-2 text-gray-500 hover:text-rg text-3xl dark:text-gray-400"
            onClick={onClose}
          >
            <HiXMark />
          </button>

          <div className="text-center ">
            {errorMessage && (
              <div className="">
                <Entete formError={errorMessage} />
              </div>
            )}
            <a
              className={`flex items-center mb-5 -ms-3 justify-center`}
              href="/"
            >
              <BiLoaderCircle className={`text-[43px] text-orange me-3`} />
              <p className="text-3xl text-vr font-[600]">Bakeli Work</p>
            </a>
            <h1 className="block text-xl font-bold text-orange">
              Mot de passe oublié ?
            </h1>
            <p className="mt-2 text-md text-mr">
              Demander à réunitialiser votre mot de passe
            </p>
          </div>

          <div className="mt-4">
            <form>
              <div className="grid gap-y-2">
                <div>
                  <div className="relative">
                    <MonInput
                      type="email"
                      id="email"
                      value={email}
                      labelStyle="font-bold rounded-lg text-md ms-1 mb-3"
                      placeholder="Saisissez votre email..."
                      paddingB="w-full bg-gray font-bold ps-4 rounded-lg outline-none border-none py-3"
                      onChange={(e) => {
                        setErrorMessage("");
                        setEmail(e.target.value);
                        const Err = !/^.+@.+\..+$/.test(e.target.value) ? (
                          <>
                            <FiAlertTriangle className="me-2" /> Adresse email
                            invalide.
                          </>
                        ) : (
                          ""
                        );
                        setEmailError(Err);
                      }}
                      MessageError={emailError}
                    />
                  </div>
                </div>
                <MonBouton
                  textColor={` mt-4 hover:scale-[97%] transition-all duration-500 w-full rounded-full py-2 text-lg ${
                    isLoading ? "hidden" : ""
                  }
                  ${emailError !== "" ? "hidden" : "block"}`}
                  couleurFond="px-2 md:w-14rem border outline-none bg-orange text-white text-md rounded-lg text-center py-1"
                  action={handleClick}
                  id={"connecter"}
                  alignment={"justify-center"}
                >
                  Demander la réinitialisation
                </MonBouton>
                <div
                  className={`${
                    isLoading ? "" : "hidden"
                  } mt-6 py-4 w-full flex items-center justify-center`}
                >
                  <PropagateLoader color="#009688" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
