import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { FaPlus } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { selectData } from "../../../redux/features/userSliceWithTokenValidation";
import { ProjectsEndpoints } from "../../../Routes/api/Endpoints";
import {
  usePostDataMutation,
  usePutTaskMutation,
} from "../../../services/auth-services/AuthService";
import MonBouton from "../../Utils/design-system/buttons/MonBouton";
import MonInput from "../../Utils/design-system/inputs/MonInput";

const CriteriaList = ({ task, microTaches, refetchMicroTaches, notifMicrotache }) => {
  const [openInput, setOpenInput] = useState(false);
  const [titre, setTitre] = useState("");
  const [highlightedTaskId, setHighlightedTaskId] = useState(null);
  const [putTask, { isLoading: isLoadingUpdateTask }] = usePutTaskMutation();
  const [postData, { isLoading: isLoadingPostuleTask }] = usePostDataMutation();
  const taskList = microTaches?.microTaches;
  const taskFinished = taskList?.filter(
    (microtask) => microtask?.terminer === 1
  );
  const pourcentage = Math.min(
    Math.floor((taskFinished?.length * 100) / taskList?.length),
    100
  );
  const user = useSelector(selectData);

  useEffect(() => {
    if (notifMicrotache?.id) {
      setHighlightedTaskId(notifMicrotache.id);
      const timer = setTimeout(() => {
        setHighlightedTaskId(null); 
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notifMicrotache]);
  
  const handleAddMicroTask = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("titre", titre);
    postData({
      endpoint: ProjectsEndpoints.postMicroTask,
      elementID: task?.id,
      formData,
    })
      .unwrap()
      .then((data) => {
        toast.success(data.message);
        setTitre("");
        refetchMicroTaches();
      })
      .catch((error) => {
        console.log(error?.data.message);
        // toast.error(error?.data.message);
      });
  };

  const handleUpdateTask = (microTask) => {
    const updatedTask = {
      id: microTask.id,
      terminer: microTask.terminer === 1 ? 0 : 1,
    };
    // if (user.statut !== "coach") {
    //   return;
    // }

    putTask({ endpoint: ProjectsEndpoints.updateMicroTask, task: updatedTask })
      .unwrap()
      .then(() => {
        refetchMicroTaches();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="pt-5">
      {/* <div className="projectprogress flex flex-col gap-2 py-3">
        <div className="w-full">
          <div className="w-full bg-gray-200 rounded-full h-[8px]">
            <div
              className="bg-green-500 h-full rounded-full"
              style={{ width: `${pourcentage ? pourcentage : 0}%` }}
            ></div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex gap-1 items-center text-blk">
            <CgPlayListCheck size={"30px"} />
            <span className="text-xs font-sans">
              {taskFinished?.length}/{taskList?.length}
            </span>
          </div>
          <div className=" text-blk">
            <span className="text-xs font-sans">
              {formatDuration(task?.started_at)}
            </span>
          </div>
        </div>
      </div> */}
      <div className="">
        <div className="flex gap-2 items-center">
          {/* <IoList size={"25px"} /> */}
          <h3 className="text-noir_300 text-xl font-bold">
            Liste critères de validation
          </h3>
          <span className="text-xl font-normal text-grys">
            ({taskList?.length})
          </span>
        </div>
        <div className="px-1">
          <ul className="list-none">
            {taskList?.map((task, i) => (
              <li
                key={i}
                className="flex gap-5 items-center font-mandali text-blueclear pb-4 border-t border-b"
              >
                <MonInput
                  label={""}
                  type={"checkbox"}
                  id={"check"}
                  name={"check"}
                  onChange={() => user.statut === "coach" && handleUpdateTask(task)}
                  checked={task?.terminer === 1}
                  paddingB={
                    `!text-white !bg-white checkbox checkbox-primary !size-4 !rounded-sm [--chkfg:white] ${user.statut !== "coach" && "cursor-text"}`
                  }
                  labelStyle={""}
                  required={false}
                  
                />
                <span
                  className={`mt-3 ${
                    task?.terminer === 1 && "line-through decoration-grys"
                  } text-sm transition duration-300 ${
                    highlightedTaskId === task.id ? "bg-sky-100 px-2" : ""
                  }`}
                >
                  {task?.titre}
                </span>
              </li>
            ))}
          </ul>
          <div className="py-6">
            {user.statut === "coach" && (
              <MonBouton
                type={"button"}
                children={"Ajouter une nouvelle critère de validation"}
                couleurFond={"text-orange"}
                bordure={
                  "max-w-max rounded items-center text-xs font-inter font-bold cursor-pointer h-6 px-2 justify-center focus:border-none"
                }
                icone={<FaPlus size={12} className="font-inter font-bold" />}
                action={() => setOpenInput(!openInput)}
              />
            )}
            {openInput && (
              <div className="flex w-full py-4 justify-between items-center">
                <MonInput
                  label={""}
                  type={"text"}
                  id={"title"}
                  name={"title"}
                  onChange={(e) => setTitre(e.target.value)}
                  paddingB={"bg-transparent shadow-none py-1 rounded"}
                  marginB={"!w-[82%] !mt-1 text-sm"}
                  labelStyle={""}
                  required={false}
                  placeholder="Ajouter un critère de validation..."
                  value={titre}
                />
                <MonBouton
                  type={"submit"}
                  couleurFond={"bg-orange text-white"}
                  bordure={
                    "rounded-md justify-center items-center py-2 text-xs w-[16%]"
                  }
                  action={handleAddMicroTask}
                  disabled={isLoadingPostuleTask && true}
                >
                  {isLoadingPostuleTask ? (
                    <>
                      Ajout <PulseLoader size={"5px"} color="#fff" />
                    </>
                  ) : (
                    "Ajouter"
                  )}
                </MonBouton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CriteriaList;
