import React from 'react';
import { TbPointFilled } from "react-icons/tb";

const CardDetailProject = ({title, className, size, ID, id, competences, apropos, presentation, price, duration, publicationDate, head, cardPadding}) => {
  return (
    <div
      className={`card bg-transparent rounded-2xl shadow border my-4 p-8 ${cardPadding}`}
    >
      <div className="flex flex-col gap-3">
        <div className={head}>
          <div className={`flex flex-col gap-3`}>
            <div className={className}>
              <h2 className={size}>{title}</h2>
            </div>
            {presentation && (
              <div className="flex gap-2 items-center font-inter">
                <span className="text-sm bg-orange px-2 py-1 font-semibold text-white rounded-badge">
                  Ouvert
                </span>
                <span className="inline-flex text-sm items-center justify-center">
                  Publié il y'a {publicationDate}
                </span>
                <TbPointFilled size={8} />
                <span className="text-sm">
                  Se termine dans {duration} jours
                </span>
              </div>
            )}
          </div>
          {presentation && (
            <div className="flex flex-col gap-5 items-end">
              <h1 className="font-inter text-3xl font-bold text-orange">
                {price} FCFA
              </h1>
              <p className="text-sm font-inter">Payé à la validation</p>
            </div>
          )}
        </div>
        {ID && <p className="text-[13px] font-inter">ID du projet : #{id}</p>}
        <div className="flex gap-3">
          {competences &&
            competences?.map((competence, i) => (
              <span className="border rounded text-xs py-1 px-3" key={i}>
                {competence?.name}
              </span>
            ))}
        </div>
        {apropos && (
          <div className="flex gap-4 items-center font-inter">
            {apropos?.map((propo, i) => (
              <div className="flex gap-1 text-[10px] items-center" key={i}>
                <span>{propo?.icon}</span>
                <span>{propo?.text}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default CardDetailProject
