import React from 'react'
import MonBouton from '../../Utils/design-system/buttons/MonBouton'
import { TabsMenu } from '../../Utils/UtilsSidebar'
import { NavLink, useNavigate } from 'react-router-dom'
import { CiCirclePlus } from 'react-icons/ci'

function HeaderDashboard() {
  const navigate = useNavigate();
  return (
    <div className='flex justify-between items-center'>
        <div className='tab-filter w-2/4'>
            <div className='flex justify-between items-center'>
                {TabsMenu.map((item, index) => (
                    <NavLink key={index} className={`${item.bordure} flex justify-center px-4 text-sm`}>{item.text}</NavLink>
                ))}
            </div>
        </div>
        <div className='px-2'>
          <MonBouton children={"Ajouter un projet"} couleurFond={"bg-white"} bordure={"border px-2 py-2 justify-center rounded-full text-xs"} icone={<CiCirclePlus />} action={() => navigate("ajout-projet")} type={"button"}/>
        </div>
    </div>
  )
}

export default HeaderDashboard