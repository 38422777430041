import React, { useState } from "react";
import { BiLoaderCircle } from "react-icons/bi";
import { HiMenuAlt3, HiOutlineLogout } from "react-icons/hi";
import { NavLink, useNavigate } from "react-router-dom";
import MonBouton from "../Utils/design-system/buttons/MonBouton";
import useIsAuthenticate from "../../hook/useIsAuthentificated";
import { navbarItems } from "../Utils/UtilsAcceuilPage";
import imageProfile from '../../assets/face8.jpg.png'
import { CLIENT_DASHBOARDS, CLIENT_DASHBOARDS_DASHBOARD, LOGIN_PATH } from "../../Routes/navigation/navigationPaths";
import { PiShareBold } from "react-icons/pi";
import { selectData } from "../../redux/features/userSliceWithTokenValidation";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLogoutUserMutation } from "../../services/auth-services/AuthService";

export const NavBarPageAccueil = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNavbarUserVisible, setIsNavbarUserVisible] = useState(false);
  const dispatch = useDispatch()
  const isAuthenticated = useIsAuthenticate();
  const navigate = useNavigate();
  const userData = useSelector(selectData);
  const [logout] = useLogoutUserMutation();
  const [filter, setFilter] = useState("")

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleNavbarUser = () => {
    setIsNavbarUserVisible(!isNavbarUserVisible);
  };

  const LogOut = () => {
    logout();
    navigate(`${LOGIN_PATH}`);
  };

  return (
    <nav className="max-md:w-full w-[90%] h-[98%] relative">
      <div className="mx-auto flex items-center justify-between max-lg:justify-center max-lg:gap-3 max-sm:pt-1 max-w-screen-xl headerAcceuil font-sans h-full">
        <div className={`flex items-center space-x-2 cursor-pointer `} onClick={()=>navigate("/")}>
          <BiLoaderCircle className={`text-[26px] text-orange`} />
          <span
            className={`text-[17px] max-lg:text-sm max-lg:font-normal font-[500] text-vrt font-sans self-center max-lg:self whitespace-nowrap`}
          >
            Bakeli Work
          </span>
        </div>
        <div className="w-[32%] max-md:w-[45%] max-lg:w-[28%] flex justify-end">
          <label
            className={`input input-bordered max-lg:text-xs flex items-center gap-2 !bg-grisrel text-black h-7 rounded-full focus:!outline-none !outline-none !shadow-none text-sm font-poppins !border-none !w-[94%] !max-md:w-full`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-search"
            >
              <circle cx="11" cy="11" r="8" />
              <path d="m21 21-4.3-4.3" />
            </svg>
            <input
              type="text"
              className="grow !bg-transparent"
              placeholder="Rechercher"
            />
          </label>
        </div>
        <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse relative max-md:px-2">
          {!isAuthenticated ? (
            <div className="flex justify-between items-center gap-4 max-lg:gap-1 max-md:hidden">
              <MonBouton
                type={"button"}
                children={"Soumettre un projet"}
                couleurFond={
                  "text-grayrel text-sm max-xl:text-xs max-lg:text-[10px] text-blc"
                }
                action={() => navigate("soumettre-projet")}
              />
              <MonBouton
                action={() => {
                  navigate("/connexion");
                }}
                type={"button"}
                children={"Se connecter"}
                couleurFond={
                  isAuthenticated
                    ? "bg-orange text-white opacity-60"
                    : "bg-orange text-white text-sm max-xl:text-xs max-lg:text-[8px] text-blc"
                }
                bordure={
                  "px-7 max-lg:px-2 py-2 max-lg:py-1 rounded-full justify-center max-w-max"
                }
              />
            </div>
          ) : (
            <div className="flex gap-3">
              <button
                type="button"
                className="flex text-sm rounded-full md:me-0 focus:ring-4 focus:ring-grys dark:focus:ring-gr"
                id="user-menu-button"
                aria-expanded={isMenuOpen}
                onClick={toggleMenu}
                data-dropdown-placement="bottom"
              >
                <img
                  className="w-9 h-9 rounded-full"
                  src={imageProfile}
                  alt="user photo"
                />
              </button>
              {/* <span className='flex items-center gap-2 cursor-pointer p-3 hover:bg-gray-200' onClick={() => navigate(`/${CLIENT_DASHBOARDS}${CLIENT_DASHBOARDS_DASHBOARD}`)}>
                <span className="text-xs">Accéder à mon dashboard</span>
                <PiShareBold size={"18px"} />
              </span> */}
            </div>
          )}
          <div
            className={`z-[80] ${
              isMenuOpen ? "block" : "hidden"
            } my-4 text-base list-none bg-white rounded-lg shadow-md absolute top-full right-0`}
            id="user-dropdown"
          >
            <div className="px-3 py-3 border-b">
              <span className="block text-sm text-gray-900">
                {userData?.name}
              </span>
              <span className="block text-xs text-gray-500 truncate dark:text-gray-400">
                {userData?.email}
              </span>
            </div>
            <ul className="py-2" aria-labelledby="user-menu-button">
              <li
                className="flex items-center gap-2 cursor-pointer p-3 hover:bg-gray-200"
                onClick={() =>
                  navigate(
                    `/${CLIENT_DASHBOARDS}${CLIENT_DASHBOARDS_DASHBOARD}`
                  )
                }
              >
                <PiShareBold size={"18px"} />
                <span className="text-xs">Mon dashboard</span>
              </li>
              <li
                className="flex items-center gap-2 cursor-pointer p-3 hover:bg-gray-200"
                onClick={() => LogOut()}
              >
                <HiOutlineLogout size={"18px"} />
                <span className="text-xs">Déconnexion</span>
              </li>
            </ul>
          </div>
          <button
            data-collapse-toggle="navbar-user"
            type="button"
            className="inline-flex items-center p-1 w-8 h-8 justify-center text-sm text-gray-500 rounded-sm md:hidden hover:bg-gray-100 focus:outline-none dark:text-gray-400"
            aria-controls="navbar-user"
            aria-expanded="false"
            onClick={toggleNavbarUser}
          >
            <HiMenuAlt3 className="w-11 h-11" />
          </button>
        </div>

        <div
          className={`items-center ${
            isNavbarUserVisible ? "flex justify-end" : "hidden"
          } md:flex justify-between md:w-auto md:order-1 relative`}
          id="navbar-user"
        >
          <ul
            className={`flex flex-col font-medium ${
              isNavbarUserVisible
                ? "z-50 max-md:bg-white max-md:shadow-lg max-md:rounded-lg !max-md:w-full max-md:top-full max-md:right-0 max-md:ms-auto max-md:absolute max-md:gap-2"
                : "shadow-none w-full rounded-none"
            } p-2 md:p-0 mt-4 rounded-lg md:space-x-1 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 dark:text-stone-300 lg:flex-row lg:text-sm justify-center items-center`}
          >
            {navbarItems.map((item, index) => (
              <li
                key={index}
                className={`transition duration-500 ${
                  isNavbarUserVisible
                    ? "max-md:text-center"
                    : "lg:text-left text-right"
                }`}
              >
                <NavLink
                  to={item.path}
                  key={index}
                  className={({ isActive }) =>
                    `relative inline-block px-2 py-1 text-noirel font-medium max-2xl:text-[15.5px] max-xl:text-xs max-lg:text-[10px] font-mandali ${
                      isActive
                        ? "after:content-[''] after:absolute after:left-1/2 after:transform after:-translate-x-1/2 after:bottom-0 after:w-[40%] after:h-[1.5px] after:bg-orange"
                        : ""
                    }`
                  }
                >
                  {item.text}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};