/**
 * @readonly
 * ! PUBLIC ROUTES
 */

export const PUBLIC_PATH = "/";
export const LOGIN_PATH = "/connexion"
export const REGISTER_PATH = "/inscription"
export const FORGETPASSWORD = ""
export const RESETPASSWORD = "/reset-password/:token/:email"

/**
 * @readonly
 * ! PROTECTED ROUTES
 */

export const SOUMETTRE_PROJET = "/soumettre-projet"
export const DETAIL_PROJET_ACCEUIL = "/:slug"
export const DASHBOARDS = "dashbords"
export const CLIENT_DASHBOARDS = "client-dashboard/"
export const CLIENT_DASHBOARDS_DASHBOARD = "dashboard"
export const AJOUT_PROJET_CLIENT = "dashboard/ajout-projet"
export const PROJECTS = "projects"
export const PROJECTID = "slug"
export const PROJECT_DETAIL_ID= "dashboard/:slug"
export const PARAMETER = "parameter"
export const LIVRABLE = "livrable"
export const TACHES = "taches"
export const PAYMENT = "paiement"
export const DETAIL_PROJECT_DASHBOARD = "detail-projet"
export const MICROTASK = "micro-task"
export const ACTIVITIES = "activities"
export const DOCUMENT_PROJECT_DASHBOARD = "document-projet"
export const DONNEES_FINANCIERES = "donnees-financieres-projet"
export const WORKER_PAGE = "/workerPage"
export const MES_TEMPLATES = "mesTemplates"
export const DASHBOARD = "Dashboard"
export const ADD_FORM = "mesTemplates/addForm"
export const LANDING_PAGE = "landingPage"
export const PROJECTSACCUEIL = "/projets"
export const TEMPLATESPAGES = "/templates"
export const TELECHARGER_TEMPLATES = "/telecharge/:slug"
export const LIVRAISONSDETAIL = "livraisons"

/**
 * @readonly
 * ! NOT FOUND ROUTE
 */
export const NOTFOUND_PATH = "*";
