import React, { useState } from 'react';
import { FiAlertTriangle } from "react-icons/fi";
import { PulseLoader } from 'react-spinners';
import MonBouton from './design-system/buttons/MonBouton';
import InputWysiwyg from './InputWysiwyg';

const ConfirmAction = ({title, actionText, actionAnnuler, actionConfirm, validation, loader, text, setText, postuled}) => {
    return (
      <div className="w-full flex flex-col justify-center items-center gap-4">
        <div
          className={`${validation ? "bg-vr rounded-full p-3 flex justify-center items-center text-white" : "bg-red rounded-full p-3 flex justify-center items-center text-white"}`}
        >
          <FiAlertTriangle size={20} />
        </div>
        <div className="flex flex-col items-center w-[80%] gap-1">
          <span
            className={`font-semibold ${validation ? "text-vr" : "text-red"} text-center`}
          >
            {title}
          </span>
          <p className="text-md">{`Êtes-vous sûr de vouloir ${actionText}`}</p>
          {!validation && (
            <InputWysiwyg
              text={text}
              setText={setText}
              placeholder="Motif de rejet"
              height="50px"
              required={true}
              isLoading={loader}
              width="w-full"
            />
          )}
          <div className="flex justify-between w-full my-3 py-3">
            <MonBouton
              children={"Annuler"}
              couleurFond={
                "bg-gray-200 text-mr hover:scale-[98%] hover:border hover:border-bl transition-all duration-500"
              }
              bordure={`px-2 flex w-[48%] justify-center border outline-none bg-gray-200 text-blk font-sans text-xs rounded-full py-2`}
              action={actionAnnuler}
              type={"button"}
            />
            <MonBouton
              children="Confirmer"
              couleurFond={`${validation ? "bg-vr" : "bg-red"} hover:scale-[98%] hover:border hover:border-rg transition-all duration-700 hover:text-white text-white`}
              bordure={`px-2 w-[48%] flex justify-center gap-1 !items-center border outline-none bg-gray-200 text-blk font-sans text-xs rounded-full py-2 `}
              action={actionConfirm}
              type={"button"}
              iconRight={
                loader && <PulseLoader size={5} color="#fff" className="" />
              }
            />
          </div>
        </div>
      </div>
    );
}

export default ConfirmAction
