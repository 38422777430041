import React from 'react'
import AjoutProjectForm from './AjoutProjectForm'

function AjoutProjetClient() {
  return (
    <div className='flex justify-center'>
        <div className='w-[92%] p-8'>
            <div className='header py-6'>
                <h1 className='font-mandali text-base text-indigos border-b '>Vous avez un projet, une idée que vous voulez concrétiser ? faites le nous savoir...</h1>
            </div>
            <div className='py-3 px-6 bg-white border rounded-3xl'>
                <AjoutProjectForm />
            </div>
        </div>
    </div>
  )
}

export default AjoutProjetClient