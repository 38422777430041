import React from "react";
import MonInput from "./design-system/inputs/MonInput";

export const profileInputs = [
  {
    label: "Prénom & Nom",
    type: "text",
    id: "name",
    placeholder: (user) => user.name,
  },
  {
    label: "Téléphone",
    type: "text",
    id: "number",
    placeholder: (user) => user.number,
  },
  {
    label: "Mot de passe",
    type: "password",
    id: "password",
    placeholder: "Votre nouveau mot de passe",
  },
  {
    label: "Confirmation",
    type: "password",
    id: "password_confirmation",
    placeholder: "Confirmez le mot de passe",
  },
  {
    label: "Pays de résidence",
    type: "text",
    id: "pays",
    placeholder: "Pays de résidence",
  },
];

export const TemplateInputs = (typesTemplates, categories) => [
  {
    label: "Titre du template*",
    type: "text",
    id: "titre",
    placeholder: "Le titre de votre template",
    labelStyle:"text-lg ms-5 text-gray-800 w-full mb-1"
  },
  {
    label: "Description*",
    type: "text",
    id: "description",
    placeholder: "La description de votre template",
    labelStyle:"text-lg ms-5 text-gray-800 w-full mb-1"
  },
  {
    label: "Aperçu*",
    type: "url",
    id: "aperçu",
    placeholder: "Lien de l'aperçus de votre template",
    labelStyle:"text-lg ms-5 text-gray-800 w-full mb-1",
  },
  {
    label: "Prix du template",
    type: "number",
    id: "prix",
    placeholder: "Le prix de votre template",
    labelStyle:"text-lg ms-5 text-gray-800 w-full mb-1",
  },
  {
    label: "Catégorie Template*",
    type: "select",
    id: "nom_categorie",
    placeholder: "Séléctionner ou Ajouter une catégorie",
    labelStyle:"text-lg ms-1 text-gray-800 w-full mb-1",
    options: [
      { value: "", label: "Liste des catégories" },
      ...categories.map((cat) => ({
        value: cat.nom_categorie,
        label: cat.nom_categorie,
      })),
    ],
  },
  {
    label: "Type de template*",
    type: "select",
    id: "type_template",
    placeholder: "Séléctionner un type de Template",
    labelStyle:"text-lg ms-1 text-gray-800 w-full mb-1",
    options: [
      { value: "", label: "Les types de template" },
      ...typesTemplates.map((t) => ({
        value: t.type_template,
        label: t.type_template,
      })),
    ],
  },
];

const InputProfilData = ({
  formData,
  handleInputChange,
  typesTemplates,
  categories,
}) => {
  const templateInputs = TemplateInputs(typesTemplates, categories);

  return (
    <div className="part1 flex lg:flex-row flex-col basis-2/3 justify-between">
      <div className="flex flex-wrap justify-between border border-gray-200 rounded-xl m-3 px-4 py-2">
        {templateInputs.map((input) => (
          <div key={input.id} className="w-full md:w-1/2 md:mb-0">
            <MonInput
              label={input.label}
              type={input.type}
              id={input.id}
              position={"flex-col justify-start"}
              onChange={handleInputChange}
              placeholder={input.placeholder}
              labelStyle={input.labelStyle}
              paddingB="h-11 bg-white md:w-[96%] ms-0 rounded-lg border-grys/40"
              value={formData[input.id]}
              options={input.options}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default InputProfilData;
